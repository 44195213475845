import React, { useCallback, useEffect } from "react";
import Infromations from "./Infromations";
import Adresses from "./Adresses";
import Intermediary from "./Intermediary";
import { useDispatch, useSelector } from "react-redux";
import Contract from "./Contract";
import Note from "./Note";
import Accompaniment from "./Accompaniment";
import Comments from "./Comments";

function NewRevendeurContent({ activeTab, forms, setForms, customErrors }) {
  /* ********************************* consts ********************************* */
  const { user, userRole } = useSelector((state) => state.userReducer);
  const userIsBusinessBroker = userRole === "BusinessBroker";
  const {
    attributes: { firstName, lastName, business_affiliate },
  } = user;
  const userFullName = `${firstName} ${lastName}`;
  /* ********************************* Helpers ******************************** */
  const dispatch = useDispatch();

  // handle change form function
  const handleFormChange = useCallback(
    (name, formData) => {
      setForms({ ...forms, [name]: formData });
    },
    [forms, setForms]
  );
  // clear the form the unmount of the New Reseller component
  useEffect(() => {
    return () => dispatch({ type: "CLEAR_CURRENT_RESELLER" });
  }, [dispatch]);

  /**
   *  @description :
   *  if the role of the user is BusinessBroker which is the BusinessAffilite
   *  then we Need the businessAffiliate form attribute directly without opening the @Intermediary component
   */

  useEffect(() => {
    if (userIsBusinessBroker) {
      handleFormChange("affiliate", [
        { name: userFullName, code: business_affiliate?.data?.id },
      ]);
    }
  }, [userIsBusinessBroker, business_affiliate?.data?.id]);

  /* ************************* Rendering Conditionally ************************ */
  switch (activeTab) {
    case "Informations":
      return (
        <Infromations
          initialValues={forms.infos}
          handleFormChange={handleFormChange}
          customErrors={customErrors}
        />
      );
    case "Addresses":
      return (
        <Adresses
          initialValues={forms.addresses}
          handleFormChange={handleFormChange}
        />
      );
    case "Note":
      return (
        <Note initialValues={forms.note} handleFormChange={handleFormChange} />
      );
    case "Contract":
      return (
        <Contract
          initialValues={forms.contract}
          handleFormChange={handleFormChange}
        />
      );
    case "Accompaniment":
      return (
        <Accompaniment
          initialValues={forms.accompaniment}
          handleFormChange={handleFormChange}
        />
      );
    case "Comments":
      return (
        <Comments
          initialValues={forms.comments}
          handleFormChange={handleFormChange}
        />
      );
    case "Business affiliate":
      if (!userIsBusinessBroker)
        return (
          <Intermediary
            initialValues={forms.affiliate}
            handleFormChange={handleFormChange}
          />
        );
      break;
    default:
      return (
        <Infromations
          initialValues={forms.infos}
          handleFormChange={handleFormChange}
        />
      );
  }
}

export default NewRevendeurContent;
