import qs from "qs";
import NEW_API from "../../Api/NewApi";
import { uploadFile } from "../../Utils";
import { toast } from "react-toastify";
import moment from "moment";
import { getCatalogue } from "./catalogue";

export const fetchCommandes =
  (searchInput, sort = "number:desc", page = 1, activeTab) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    NEW_API.defaults.headers.common["Authorization"] = token;
    try {
      // return;
      const searchquery = searchInput ? `search=${searchInput}` : "";
      const sortquery = sort ? `sort=${sort}` : "";
      if (page === 1) {
        dispatch({ type: "COMMANDES_LOADING" });
      } else {
        dispatch({ type: "COMMANDES_REFETCH_LOADING" });
      }

      const { data } = await NEW_API.get(
        `/api/fetchCommandes?${searchquery}&${sortquery}&page=${page}&activeTab=${activeTab}`
      );

      if (page === 1) {
        dispatch({
          type: "COMMANDES_SUCCESS",
          payload: { commandes: data.data.commandes, count: data.data.count },
        });
      } else {
        dispatch({
          type: "COMMANDES_SUCCESS_REFETCH",
          payload: data.data.commandes,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "COMMANDES_ERROR" });
    }
  };

export const updateCommande = (commandeId, data) => async (dispatch) => {
  try {
    await NEW_API.put(`/api/commandes/${commandeId}`, {
      data: { ...data },
    });
    dispatch(refetchCommandeItem(commandeId));
  } catch (error) {
    dispatch({ type: "CART_ERROR" });
  }
};

export const fetchCommande = (commandeId) => async (dispatch) => {
  dispatch({ type: "COMMANDES_LOADING" });

  try {
    const fetchedCommande = await NEW_API.get(
      `/api/fetchCommandeItem?commandeId=${commandeId}`
    );
    dispatch({
      type: "COMMANDE_SUCCESS",
      payload: fetchedCommande?.data?.data,
    });
  } catch (error) {
    dispatch({ type: "COMMANDES_ERROR" });
  }
};

export const refetchCommandeItem = (commandeId) => async (dispatch) => {
  try {
    const newCommande = await NEW_API.get(
      `/api/fetchCommandeItem?commandeId=${commandeId}`
    );
    dispatch({
      type: "UPDATE_COMMANDE",
      payload: {
        commandeId: commandeId,
        data: newCommande.data.data.commande,
      },
    });
  } catch (error) {
    dispatch({ type: "CART_ERROR" });
  }
};

export const deleteCommande =
  (commandeId, { searchInput, activeTab, productsactiveTab }) =>
  async (dispatch) => {
    try {
      await NEW_API.post(`/api/handleDeleteCommande`, {
        data: { commandeId: commandeId },
      });
      dispatch({
        type: "DELETE_COMMANDE",
        payload: commandeId,
      });
      if (activeTab && productsactiveTab) {
        dispatch(getCatalogue(activeTab, productsactiveTab, searchInput));
      }
    } catch (error) {
      dispatch({ type: "CART_ERROR" });
    }
  };

// COMMENTS

export const getOrderComments = (order) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COMMENTS_ORDER_LOADING" });
    const query = qs.stringify({
      populate: [
        "attachments.file",
        "user.photo",
        "order",
        "user.profil.reseller",
        "user.business_affiliate",
        "user.marque",
      ],
      sort: ["createdAt:desc"],
      filters: {
        commande: { id: { $eq: order } },
      },
    });
    const {
      data: { data: commentData },
    } = await NEW_API.get(`/api/comments?${query}`);
    dispatch({ type: "FETCH_COMMENTS_ORDER_SUCCESS", payload: commentData });
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_ORDER_ERROR", payload: error });
  }
};

export const createOrderComment =
  (comment, order, user, successCallback, t) => async (dispatch) => {
    dispatch({ type: "CREATE_COMMENTS_ORDER_LOADING" });

    try {
      const {
        data: { data: commentData },
      } = await NEW_API.post(`/api/comments?populate=*`, {
        data: {
          text: comment?.text,
          commande: order,
          user,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await NEW_API.post(`/api/attachments?populate=*`, {
            data: {
              date: new Date(),
              file: fileId,
              comment: commentData?.id,
            },
          });
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      dispatch({
        type: "CREATE_COMMENTS_ORDER_SUCCESS",
        payload: {
          ...commentData,
          attributes: { ...commentData?.attributes },
        },
      });
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

export const updateCommandePayments = (commande) => async (dispatch) => {
  // dispatch({ type: "UPDATE_COMMANDE_LOADING" });
  dispatch({ type: "COMMANDES_LOADING" });

  try {
    if (commande?.payments) {
      for (const payment of commande?.payments) {
        if (payment?.newPayment) {
          await NEW_API.post(`api/payments`, {
            data: {
              amount: payment?.amount,
              date: moment(payment?.date).format("YYYY-MM-DD"),
              commande: commande?.id,
            },
          });
        } else {
          await NEW_API.put(`api/payments/${payment?.id}`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
            },
          });
        }
      }
    }

    dispatch(fetchCommande(commande?.id));
  } catch (error) {
    console.log(error);
    dispatch({ type: "CART_ERROR" });
  }
};

export const deletePayment = (payload) => async (dispatch) => {
  dispatch({ type: "ORDER_LOADING" });
  try {
    await NEW_API.delete(`api/payments/${payload.id}`);
    dispatch(fetchCommande(payload?.commandeID));
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
  }
};

// export const fetchCommandesAvoir =
//   (searchInput, sort = "number:desc", page = 1) =>
//   async (dispatch) => {
//     const token = localStorage.getItem("token");
//     NEW_API.defaults.headers.common["Authorization"] = token;
//     try {
//       const searchquery = searchInput ? `search=${searchInput}` : "";
//       const sortquery = sort ? `sort=${sort}` : "";
//       if (page === 1) {
//         dispatch({ type: "COMMANDES_LOADING" });
//       } else {
//         dispatch({ type: "COMMANDES_REFETCH_LOADING" });
//       }

//       const { data } = await NEW_API.get(
//         `/api/fetchCommandesAvoir?${searchquery}&${sortquery}&page=${page}`
//       );

//       if (page === 1) {
//         dispatch({
//           type: "COMMANDES_SUCCESS",
//           payload: {
//             commandes: data.data.commandesAvoir,
//             count: data.data.countAvoir,
//           },
//         });
//       } else {
//         dispatch({
//           type: "COMMANDES_SUCCESS_REFETCH",
//           payload: data.data.commandesAvoir,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       dispatch({ type: "COMMANDES_ERROR" });
//     }
//   };
