import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import _ from "lodash";

const tableHeaders = (t, headers) => headers.map((header) => t(header));

const AnnualDataTable = ({
  headers,
  columns,
  fetchData,
  data,
  totalPages,
  title,
  years,
  noResultsMessage,
  filters,
}) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const handleSearch = _.debounce((e) => {
    setSearch(e.target.value);
  }, 300);

  useEffect(() => {
    fetchData(1, selectedYear, search);
  }, [filters, search]);

  return (
    <div className="pt-5">
      <span className="text-blue bold f22 quickSand">{t(title)}</span>
      <div className="alignH my-2 justify-content-between">
        <div className="alignH">
          {years.map((year) => (
            <button
              key={year}
              className={`${
                year === selectedYear
                  ? "bg-gray-500 text-white"
                  : "bg-transparent text-blue"
              } py-1 f16 px-3 border-0 outline-none sBold`}
              onClick={() => {
                if (year !== selectedYear) {
                  setSelectedYear(year);
                  setPage(1);
                  fetchData(1, year, search);
                }
              }}
            >
              {year}
            </button>
          ))}
        </div>
        <div className="w-100 max-w-500px">
          <TextInput
            placeholder={t("Search")}
            width="100%"
            callback={handleSearch}
          />
        </div>
      </div>
      <div className="overflow-x-auto mb-4 mt-2">
        <table className="table table-transparent text-center">
          <thead className="table-dimGrey text-nowrap">
            <tr className="border-none">
              {tableHeaders(t, headers).map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="py-3 px-4 text-lightBlack sBold f14"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((row, rowIndex) => (
                <tr key={row.id || rowIndex} className="align-middle table-row">
                  {columns.map((column) => (
                    <td
                      key={column.name}
                      className="py-3 px-4 f14 text-lightBlack"
                    >
                      {column.render({ ...row, index: rowIndex })}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="py-3 px-4 text-center f14 text-lightBlack"
                >
                  {t(noResultsMessage)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalPages > page && (
          <div className="w-100 text-center">
            <button
              className="text-brightGrey underline bg-transparent border-0 sBold outline-none"
              onClick={() => {
                setPage((prev) => prev + 1);
                fetchData(page + 1, selectedYear, search);
              }}
            >
              {t("See more")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnualDataTable;
