export const headers = (t) => ({
  1: [
    t("Entry date"),
    t("Est. Date"),
    t("Category"),
    t("Model"),
    t("Range"),
    t("Coloring"),
    t("Serial number"),
    t("PO"),
    t("Depot"),
    t("Status"),
    "",
  ],
  2: [
    t("Entry date"),
    t("Est. Date"),
    t("Type"),
    t("Reference"),
    t("Model"),
    t("Coloring"),
    t("Quantity"),
    t("Depot"),
    t("PO"),
    t("Status"),
    "",
  ],
});
