import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { useTranslation } from "react-i18next";

const AnnualMargin = ({ filters, selectedYear }) => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);

  const rows = [
    {
      label: "General Margin Rate",
      value: data?.generalTxMarge ? data?.generalTxMarge.toFixed(1) : 0,
    },
    {
      label: "Spa Store Margin Rate",
      value: data?.totalMargins?.spa?.totalTxMarge
        ? data?.totalMargins?.spa?.totalTxMarge?.toFixed(1)
        : 0,
    },
    {
      label: "Swim Spa Store Margin Rate",
      value: data?.totalMargins?.swimSpa?.totalTxMarge
        ? data?.totalMargins?.swimSpa?.totalTxMarge?.toFixed(1)
        : 0,
    },
    {
      label: "Accessory Store Margin Rate",
      value: data?.totalMargins?.accessory?.totalTxMarge
        ? data?.totalMargins?.accessory?.totalTxMarge?.toFixed(1)
        : 0,
    },
    {
      label: "Spare Parts Store Margin Rate",
      value: data?.totalMargins?.sparePart?.totalTxMarge
        ? data?.totalMargins?.sparePart?.totalTxMarge?.toFixed(1)
        : 0,
    },
    {
      label: "Marketing Product Margin Rate",
      value: data?.totalMargins?.marketingProduct?.totalTxMarge
        ? data?.totalMargins?.markingProduct?.totalTxMarge?.toFixed(1)
        : 0,
    },
    {
      label: "Key Accounts Margin Rate",
      value: data?.totalMargins?.bigAccount?.totalTxMarge
        ? data?.totalMargins?.bigAccount?.totalTxMarge?.toFixed(1)
        : 0,
    },
  ];

  const fetchData = async () => {
    try {
      const params = {
        year: selectedYear,
      };

      Object.keys(filters)?.forEach((key) => {
        if (filters[key]) {
          params[key] = filters[key]?.code;
        }
      });

      const response = await API.get(`api/annual-margin`, {
        params,
      });

      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear, filters]);

  return (
    <div className="w-50">
      <div className="d-flex gap-4 w-100">
        <div className="w-100 flex-column d-flex gap-1">
          {rows.map(({ label, value }) => (
            <div className="d-flex justify-content-between gap-1">
              <span className="f16 py-2 px-4 text-lightBlack sBold f14 w70 bg-dimGrey">
                {t(`${label}`)}
              </span>
              <span className="f16 py-2 px-4 text-lightBlack sBold f14 w30 bg-dimGrey text-center">
                {value ?? 0}%
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnnualMargin;
