export const productForm = [
  {
    type: "text",
    label: "Name",
    placeholder: "Name",
    name: "name",
    required: true,
  },
  {
    type: "text",
    label: "Name",
    placeholder: "Name",
    name: "name_en",
    required: true,
  },
  {
    type: "select",
    name: "productType",
    label: "Product type",
    required: true,
  },
  {
    type: "select",
    name: "category",
    label: "Categories",
    // required: true,
    disabledByOptions: true,
  },
  {
    type: "select",
    name: "gamme",
    label: "Range",
    // required: true,
    disabledByOptions: true,
  },
  {
    type: "text",
    placeholder: "Reference",
    required: true,
    label: "Reference",
    name: "reference",
  },
  {
    type: "multiSelectCheckbox",
    name: "related_products",
    label: "Associated Product(s)",
    filter: true,
  },
  {
    type: "number",
    placeholder: "Purchase Price",
    required: true,
    name: "buyingPrice",
    label: "Purchase Price",
    surfixText: true,
  },
  {
    type: "number",
    placeholder: "Reseller Price Excl. Tax",
    required: true,
    name: "retailerPriceHT",
    label: "Reseller Price Excl. Tax",
    surfixText: true,
  },
  {
    type: "number",
    placeholder: "Public Price Incl. Tax",
    required: true,
    name: "publicPriceTTC",
    label: "Public Price Incl. Tax",
    surfixText: true,
  },
  {
    type: "select",
    name: "product_size",
    label: "Palettization Size",
    // required: true,
  },
  {
    type: "multiSelectCheckbox",
    name: "delivery_types",
    label: "Possible Delivery Type",
    multiple: true,
    // required: true,
  },
  {
    type: "number",
    placeholder: "0",
    name: "multipleContainer",
    label: "Multiple Container",
    // required: true,
  },
  {
    type: "select",
    placeholder: "0",
    name: "kangourou",
    label: "Kangaroo pocket spa",
  },
  {
    type: "text",
    placeholder: "0",
    name: "accessoryDimension",
    label: "Package dimensions mm",
  },
  {
    type: "number",
    placeholder: "0",
    name: "accessoryWeight",
    label: "Weight in kg",
  },
  {
    type: "textarea",
    placeholder: "description",
    label: "Description",
    name: "description_en",
  },
  {
    type: "textarea",
    placeholder: "description",
    label: "Description",
    name: "description",
  },
];

export const productServiceForm = [
  {
    type: "text",
    label: "Name",
    placeholder: "Name",
    name: "name",
    required: true,
  },
  {
    type: "select",
    name: "productType",
    label: "Product type",
    required: true,
  },

  {
    type: "text",
    placeholder: "Reference",
    required: true,
    label: "Reference",
    name: "reference",
  },
  {
    type: "multiSelectCheckbox",
    name: "related_products",
    label: "Associated Product(s)",
    filter: true,
  },
  {
    type: "number",
    placeholder: "Purchase Price",
    required: true,
    name: "buyingPrice",
    label: "Purchase Price",
    surfixText: true,
  },
  {
    type: "number",
    placeholder: "Reseller Price Excl. Tax",
    required: true,
    name: "retailerPriceHT",
    label: "Reseller Price Excl. Tax",
    surfixText: true,
  },
  {
    type: "number",
    placeholder: "Public Price Incl. Tax",
    required: true,
    name: "publicPriceTTC",
    label: "Public Price Incl. Tax",
    surfixText: true,
  },

  {
    type: "textarea",
    placeholder: "description",
    label: "Description",
    name: "description",
  },
];

export const caractProductForm = [
  {
    type: "number",
    placeholder: "Capacity",
    required: false,
    name: "capacity",
    label: "Capacity",
  },
  {
    type: "text",
    placeholder: "Product Dimensions",
    required: false,
    name: "productDimensions",
    label: "Product Dimensions",
  },
  {
    type: "text",
    placeholder: "Palettized Dimensions",
    required: false,
    name: "palletizedDimensions",
    label: "Palettized Dimensions",
  },
  {
    type: "number",
    placeholder: "Weight",
    required: false,
    name: "weight",
    label: "Weight",
  },
  {
    type: "text",
    placeholder: "Hydrotherapy jet",
    required: false,
    name: "hydrotherapyJet",
    label: "Hydrotherapy jet",
  },
  {
    type: "text",
    placeholder: "Water pump",
    required: false,
    name: "waterPump",
    label: "Water pump",
  },
  {
    type: "text",
    placeholder: "Filtration pump",
    required: false,
    name: "filtrationPump",
    label: "Filtration pump",
  },
  {
    type: "text",
    placeholder: "Filtration pump",
    required: false,
    name: "dashboardComputer",
    label: "Filtration pump",
  },
  {
    type: "text",
    placeholder: "Balboa Electric Box",
    required: false,
    name: "balboaElectricBox",
    label: "Balboa Electric Box",
  },
  {
    type: "text",
    placeholder: "Balboa heating",
    required: false,
    name: "balboaHeater",
    label: "Balboa heating",
  },
  {
    type: "text",
    placeholder: "Power Supply",
    required: false,
    name: "electricalPowerSource",
    label: "Power Supply",
  },
  {
    type: "text",
    placeholder: "Swim Pump",
    required: false,
    name: "swimJetPump",
    label: "Swim Pump",
  },
  {
    type: "number",
    placeholder: "Water Volume",
    required: false,
    name: "waterVolume",
    label: "Water Volume",
  },
];

export const equipementForm = [
  {
    type: "select",
    placeholder: "Acrylics ARISTECH",
    required: true,
    name: "acrylicsARISTECH",
    label: "Acrylics ARISTECH",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "BioLok System",
    required: true,
    name: "systemeBiolok",
    label: "BioLok System",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "text",
    placeholder: "Disinfection",
    required: true,
    name: "desinfection",
    label: "Disinfection",
  },
  {
    type: "select",
    placeholder: "Cartridge filtration",
    required: true,
    name: "filtrationCartouche",
    label: "Cartridge filtration",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Low consumption filtration",
    required: true,
    name: "filtrationBasseConsommation",
    label: "Low consumption filtration",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "text",
    placeholder: "Isolation",
    required: true,
    name: "isolation",
    label: "Isolation",
  },
  {
    type: "select",
    placeholder: "ABS Base",
    required: true,
    name: "baseABS",
    label: "ABS Base",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "text",
    placeholder: "Power Regulator",
    required: true,
    name: "powerController",
    label: "Power Regulator",
  },
  {
    type: "select",
    placeholder: "Cascade",
    required: true,
    name: "cascade",
    label: "Cascade",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Easy Drain",
    required: true,
    name: "easyDrain",
    label: "Easy Drain",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Winter cover",
    required: true,
    name: "winterCover",
    label: "Winter cover",
    options: [
      {
        name: "Yes",
        code: true,
      },
      {
        name: "No",
        code: false,
      },
    ],
  },
  // {
  //   type: "text",
  //   placeholder: "Niveaux de cale pieds",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Régulateur de puissance",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Repose verre",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Cascade",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Easy Drain",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Bâche hivernation",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
];

export const lightSensorForm = [
  {
    type: "number",
    placeholder: "Chromotherapy spot",
    required: true,
    name: "sportChromotherapie",
    label: "Chromotherapy spot",
  },
  {
    type: "number",
    placeholder: "Waterline LEDs",
    required: true,
    name: "LEDsLigneEau",
    label: "Waterline LEDs",
  },
  {
    type: "number",
    placeholder: "Backlit Jets",
    required: true,
    name: "jetsRetroEclaires",
    label: "Backlit Jets",
  },
  {
    type: "text",
    placeholder: "Backlit Waterfall",
    required: true,
    name: "backlitWaterfall",
    label: "Backlit Waterfall",
  },
  {
    type: "text",
    placeholder: "Backlit River",
    required: true,
    name: "backlitRiver",
    label: "Backlit River",
  },
  {
    type: "text",
    placeholder: "Backlit Headrest",
    required: true,
    name: "backlitNeckSupport",
    label: "Backlit Headrest",
  },
  {
    type: "switch",
    name: "backlitCorners",
    label: "Backlit Corners",
  },
  {
    type: "switch",
    name: "backlitPanels",
    label: "Backlit Panels",
  },
  {
    type: "switch",
    name: "backlitSkimmer",
    label: "Backlit Skimmer",
  },
  {
    type: "switch",
    name: "backlitToggleSwitch",
    label: "Backlit ON/OFF",
  },
  {
    type: "switch",
    name: "backlitRegulators",
    label: "Backlit Regulators",
  },
];

export const imageExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".webp",
  ".svg",
  ".tiff",
  ".tif",
  ".ico",
  ".heic",
  ".heif",
  ".raw",
];

export const videoExtensions = [
  ".mp4",
  ".mov",
  ".avi",
  ".mkv",
  ".wmv",
  ".flv",
  ".webm",
  ".m4v",
  ".3gp",
  ".3g2",
  ".mpg",
  ".mpeg",
];

export const documentExtensions = [
  ".pdf",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".txt",
  ".rtf",
  ".csv",
  ".odt",
  ".ods",
  ".odp",
];

export const joinExtensions = (extensions) => extensions.join(" ");
