const initialState = {
  resellers: [],
  loading: false,
  error: null,
  currentReseller: null,
  currentResellerLoading: false,
  currentResellerError: null,
  deleteLoading: false,
  deleteError: null,
  resetReseller: null,
  selectedResellerAdress: null,
  selectedResellerAdressLoad: false,
  selectedResellerAdressError: null,
  resellersCount: null,
  currentComments: [],
  currentCommentsLoading: false,
  commentsLoading: false,

  // stats
  stats: {},
  statsError: null,
  statsLoading: false,

  // events
  resellerEvents: [],
  resellerEventsLoading: false,
  resellerEventsError: null,
  resellerEventsPages: null,
};

const resellerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_RESELLERS_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_RESELLERS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        resellers: payload,
      };
    case "FETCH_RESELLERS_ERROR":
      return { ...state, loading: false, error: payload };

    case "FETCH_CURRENT_RESELLER_SUCCESS":
      return { ...state, currentReseller: payload };

    case "CREATE_UPDATE_RESELLER_LOADING":
    case "CREATE_RESELLER_ADDRESS_LOADING":
      return {
        ...state,
        currentResellerLoading: true,
        currentResellerError: null,
      };
    case "CREATE_UPDATE_RESELLER_SUCCESS": {
      let isFound = false;
      const updatedResellers = state.resellers?.map((item) => {
        if (+item?.id === +payload?.id) {
          isFound = true;
          return {
            ...payload,
            attributes: {
              ...payload.attributes,
              billingAddress: state.currentReseller?.attributes?.billingAddress,
              delivery_addresses:
                state.currentReseller?.attributes?.delivery_addresses,
              business_affiliate:
                state.currentReseller.attributes.business_affiliate,
            },
          };
        }
        return item;
      });
      return {
        ...state,
        resellers: isFound ? updatedResellers : [payload, ...state.resellers],
        currentResellerLoading: false,
        currentReseller: payload,
        currentResellerError: null,
      };
    }
    case "CREATE_UPDATE_RESELLER_ERROR":
    case "CREATE_RESELLER_ADDRESS_ERROR":
      return {
        ...state,
        currentResellerLoading: false,
        currentResellerError: payload,
      };

    case "REFETCH_RESELLER_ITEM": {
      const updatedResellers = state.resellers.map((reseller) =>
        reseller.id === payload.id ? payload : reseller,
      );
      return {
        ...state,
        resellers: updatedResellers,
        currentResellerLoading: false,
        currentReseller: {
          ...state?.currentReseller,
          attributes: {
            ...state?.currentReseller?.attributes,
            delivery_addresses: { data: payload?.deliveryAddressData },
            billingAddress: { data: payload?.billingAddressData },
          },
        },
        currentResellerError: null,
      };
    }

    case "DELETE_RESELLER_LOADING":
      return { ...state, deleteLoading: true, deleteError: null };
    case "DELETE_RESELLER_SUCCESS":
      return {
        ...state,
        deleteLoading: false,
        resellers: state.resellers.filter((item) => +item?.id !== +payload),
      };
    case "DELETE_RESELLER_ERROR":
      return { ...state, deleteLoading: false, deleteError: payload };

    case "CLEAR_CURRENT_RESELLER":
      return {
        ...state,
        currentReseller: null,
        currentResellerError: null,
        currentResellerLoading: false,
      };
    case "RESET_RESELLER":
      return {
        ...state,
        currentReseller: null,
        currentResellerError: null,
        currentResellerLoading: false,
      };
    case "FETCH_SELECTED_RESELLER_ADRESS":
      return {
        ...state,
        selectedResellerAdressLoad: true,
        selectedResellerAdressError: null,
      };
    case "FETCH_SELECTED_RESELLER_FAIL":
      return {
        ...state,
        selectedResellerAdressError: payload,
        selectedResellerAdressLoad: false,
      };
    case "SELECTED_RESELLER_ADRESS_SUCCESS":
      return {
        ...state,
        selectedResellerAdress: payload,
        selectedResellerAdressLoad: false,
      };
    case "RESET_RESELLERS": {
      return { ...state, resellers: [], loading: false, resellersCount: null };
    }
    case "RESELLERS_COUNT": {
      return { ...state, resellersCount: payload };
    }
    case "RESELLER_ARCHIVED":
    case "RESELLER_UNARCHIVED": {
      return {
        ...state,
        resellers: state?.resellers?.filter(
          (reseller) => +reseller?.id !== +payload,
        ),
      };
    }

    case "CREATE_COMMENTS_RESELLER_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_RESELLER_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_RESELLER_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_RESELLER_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };

    case "RESELLER_STATS_LOADING": {
      return { ...state, statsLoading: true, statsError: null };
    }

    case "RESELLER_STATS_SUCCESS": {
      return {
        ...state,
        statsLoading: false,
        statsError: null,
        stats: payload,
      };
    }

    case "RESELLER_STATS_ERROR": {
      return { ...state, statsLoading: false, statsError: payload };
    }

    // reseller events
    case "RESELLER_EVENTS_LOADING": {
      return {
        ...state,
        resellerEventsLoading: true,
        resellerEventsError: null,
      };
    }
    case "RESELLER_EVENTS_SUCCESS": {
      return {
        ...state,
        resellerEventsLoading: false,
        resellerEvents: [...state.resellerEvents, ...payload],
        resellerEventsError: null,
      };
    }
    case "INITIAL_RESELLER_EVENTS_SUCCESS": {
      return {
        ...state,
        resellerEventsLoading: false,
        resellerEvents: payload,
        resellerEventsError: null,
      };
    }
    case "RESELLER_EVENTS_PAGES": {
      return {
        ...state,
        resellerEventsPages: payload,
      };
    }
    case "RESELLER_EVENTS_ERROR": {
      return {
        ...state,
        resellerEventsLoading: false,
        resellerEventsError: payload,
      };
    }

    case "RESET_RESELLER_EVENTS": {
      return { ...state, resellerEvents: [] };
    }

    default:
      return state;
  }
};

export default resellerReducer;
