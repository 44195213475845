import React, { useEffect, useRef, useState } from "react";
import Button from "../../../Common/FormElements/Button/Button";
import AttachmentInput from "./AttachmentInput";
import { useTranslation } from "react-i18next";
import EditMessageMediaPreview from "./EditMessageMediaPreview";
import { editComment } from "../../../../REDUX/actions/comment";
import { useDispatch } from "react-redux";

function EditMessageInput({ message, close, actionType }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const textareaRef = useRef(null);
  const [updatedMessage, setUpdatedMessage] = useState({
    text: message?.attributes?.text,
    attachments: message?.attributes?.attachments?.data,
  });

  const handleFocus = () => {
    if (textareaRef?.current) {
      textareaRef.current.focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attachments") {
      setUpdatedMessage({
        ...updatedMessage,
        [name]:
          updatedMessage?.attachments?.length > 0
            ? [...updatedMessage?.attachments, ...value]
            : value,
      });
    } else {
      setUpdatedMessage({ ...updatedMessage, [name]: value });
    }
  };

  const handleSubmit = async (e, successCallback) => {
    e.preventDefault();
    if (
      updatedMessage?.text?.trim()?.length > 0 ||
      updatedMessage?.attachments?.length > 0
    ) {
      setLoading(true);
      dispatch(
        editComment(
          message?.id,
          updatedMessage,
          message?.attributes?.attachments?.data,
          actionType,
          successCallback,
          () => setLoading(false)
        )
      );
    }
  };

  useEffect(() => {
    handleFocus();
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;

    const adjustTextareaHeight = () => {
      if (textarea) {
        textarea.style.height = "auto"; // Reset the height to auto to calculate the new height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content's scrollHeight
      }
    };

    if (textarea) {
      textarea.addEventListener("input", adjustTextareaHeight);
      adjustTextareaHeight(); // Call it initially to set the correct height
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener("input", adjustTextareaHeight);
      }
    };
  }, []);

  return (
    <div className="formContainer position-sticky w100">
      <EditMessageMediaPreview
        attachments={updatedMessage?.attachments}
        setAttachments={(updatedAttachments) =>
          setUpdatedMessage({
            ...updatedMessage,
            attachments: updatedAttachments,
          })
        }
      />
      <div className="position-relative d-flex gap-2 flex-wrap mt-3">
        <div
          className="w100 b-2-softGrey br5 messageInput py-2 ps-4 pe-2 d-flex align-items-center justify-content-center"
          onClick={() => handleFocus()}
        >
          <textarea
            ref={textareaRef}
            name="text"
            rows={1}
            type="textarea"
            placeholder="Message"
            className="d-flex align-items-center w-100 border-0"
            onChange={loading ? undefined : handleChange}
            value={updatedMessage?.text ?? ""}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleSubmit(e, () => {
                  const textarea = textareaRef.current;
                  textarea.style.height = "auto";
                  close();
                });
              }
            }}
          />
        </div>
        {/* TODO LATER */}
        <div className="d-flex align-content-center justify-content-end mt-2 w-100">
          <AttachmentInput
            name="attachments"
            value={message?.attachments}
            handleChange={handleChange}
          />
          <Button className="sendBtn ms-2 mt-auto" onClick={close}>
            {t("Cancel")}
          </Button>
          <Button
            className="sendBtn ms-2 mt-auto"
            loading={loading}
            isSubmit={false}
            onClick={(e) => {
              handleSubmit(e, () => close());
            }}
            disabled={
              (!updatedMessage?.text?.trim()?.length > 0 &&
                !updatedMessage?.attachments?.length > 0) ||
              loading
            }
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditMessageInput;
