import React, { useEffect, useState } from "react";
// import { Carousel } from "react-bootstrap";
import { eventPictures } from "../../../../helpers/fakeData/Calendar/eventPictures";
// import CarouselArrow from "./CarouselArrow";
import { useDispatch, useSelector } from "react-redux";
import "./Evenement.css";
import { deleteEvent, getSingleEvent } from "../../../../REDUX/actions/events";
import LoaderWrapper from "../../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import Button from "../../../Common/FormElements/Button/Button";
import DeleteBtn from "../../../Common/FormElements/DeleteBtn";
import EventDetails from "./EventDetails";
import UpdateEvent from "./UpdateEvent";
import { openCenteredModal } from "../../../../REDUX/actions/centeredModal";
import DeleteGuide from "../../../Faq/DeleteGuide";
import { eventTypeFr } from "../../../../Utils/fileUtils";
import ConfirmModal from "../../../Common/Modal/ConfirmModal";
import { useTranslation } from "react-i18next";

function Evenement({ modalTitle, id, creator }) {
  const { t } = useTranslation("common");

  // consts
  const dispatch = useDispatch();
  const { event, eventLoading } = useSelector((state) => state.eventsReducer);
  const { user } = useSelector((state) => state.userReducer);

  const userRole = user?.attributes?.userRole;

  const canUpdate =
    userRole === 1 ||
    (userRole === 0 && +user?.id === +creator?.data?.id) ||
    (userRole === 2 &&
      +user?.attributes?.business_affiliate?.data?.id ===
        +creator?.data?.attributes?.profil?.data?.attributes?.reseller?.data
          ?.attributes?.business_affiliate?.data?.id);

  useEffect(() => {
    dispatch(getSingleEvent(id));
  }, [dispatch, id]);
  /* ******************************** callBacks ******************************* */
  const handleDelete = (action, message, id) => {
    dispatch(
      openCenteredModal(
        <ConfirmModal onConfirm={action} label={message} id={id} />
      )
    );
  };
  const [showUpdateEvent, setShowUpdateEvent] = useState(false);

  /* ******************************** Rendering ******************************* */
  return (
    <LoaderWrapper loading={eventLoading}>
      <div className="evenementModal ">
        <h1 className="quickSand rightModalTitle mb-5 ps-5">
          {t("Detail")} {t(`${modalTitle}`)}
        </h1>
        {!showUpdateEvent ? (
          <EventDetails event={event} />
        ) : (
          <UpdateEvent event={event} eventId={id} />
        )}

        {canUpdate && !showUpdateEvent && (
          <div className="d-flex mt-3 gap-2 ps-5">
            <Button onClick={() => setShowUpdateEvent(true)}>
              {t("Edit")}
            </Button>
            <DeleteBtn
              onClick={() =>
                handleDelete(
                  () =>
                    dispatch(
                      deleteEvent(id, event?.data?.attributes?.eventType, t)
                    ),
                  `${t(
                    `${eventTypeFr[event?.data?.attributes?.eventType] ?? ""}`
                  )}`,
                  id
                )
              }
            ></DeleteBtn>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
}

export default Evenement;
