import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { packingListTitles } from "../../../helpers/fakeData/Commandes";
import PackingListItem from "./PackingListItem";
import { v4 as uuidv4 } from "uuid";
import { getPackingSelectValue } from "./getPackingSelectValue";
import _ from "lodash";
import { isDefined } from "../../../Utils";
import { useTranslation } from "react-i18next";
import TextInput from "../../Common/FormElements/TextInput/TextInput";

const PackingListTabContent = ({
  handleFormsChange,
  initialValues,
  totalTransport,
  taux,
  setTransportExceeded,
}) => {
  const { t } = useTranslation("common");
  const { approv } = useSelector((state) => state.approvReducer);
  const [formData, setFormData] = useState(initialValues);
  const [searchInput, setsearchInput] = useState("");

  const dispatch = useDispatch();

  const handleAddItem = () => {
    setFormData([
      ...formData,
      {
        id: uuidv4(),
        newPacking: true,
      },
    ]);
  };

  const handleItemChange = (id, updatedItemData) => {
    setFormData((formData) =>
      formData.map((item) => (item.id === id ? updatedItemData : item))
    );
  };

  const handleDeleteItem = (id, modelId) => {
    setFormData((currentFormData) => {
      const updatedFormData = [...currentFormData];
      _.remove(updatedFormData, function (item) {
        if (isDefined(modelId) && !item?.isProduct) {
          return item?.model?.code?.toString() === modelId?.toString();
        }
        return item?.id?.toString() === id?.toString();
      });

      return updatedFormData;
    });
  };

  useEffect(() => {
    handleFormsChange("packingList", [...formData]);
    const packingListTransport = formData?.reduce((acc, item) => {
      if (item?.hide) return acc;
      return (item?.transport ?? 0) * (item?.quantity ?? 1) + acc;
    }, 0);
    setTransportExceeded(
      packingListTransport.toFixed(2) > parseFloat(totalTransport)
    );
  }, [formData, totalTransport, setTransportExceeded]);

  useEffect(() => {
    const values = getPackingSelectValue(approv);

    const updatedValuesWithQuantity = values?.map((item, index) => {
      if (item?.isProduct) return item;

      if (
        index >
        values?.findIndex(
          (packing) => packing?.model?.code === item?.model?.code
        )
      ) {
        return { ...item, hide: true, quantity: 1 };
      }

      const quantity = values?.reduce((acc, packing) => {
        if (packing?.model?.code === item?.model?.code) {
          return acc + 1;
        }
        return acc;
      }, 0);

      return {
        ...item,
        quantity,
      };
    });

    setFormData(updatedValuesWithQuantity);
  }, [approv]);

  const handleApprovPackingsSearch = (e) => {
    setsearchInput(e.target.value);
  };

  return (
    <div className="packingListForm modalContent px-5 pt-4">
      <div className="mb-3">
        <TextInput
          search
          placeholder={t(
            "Search for packing with the model name, serial number, or reference ..."
          )}
          className={"flex-2"}
          width={"60%"}
          callback={handleApprovPackingsSearch}

          // onSearchBtnClick={() => filterPackings(searchInput)}
        />
      </div>
      <div className="alignH bg-dimGrey ps-2 py-3 justify-content-between w100 gap-2">
        <div className="w3"></div>
        {packingListTitles?.map(({ id, label, width }) => (
          <p className={`${width}  mb-0 sBold text-brightGrey f12`} key={id}>
            {t(`${label}`)}
          </p>
        ))}
      </div>
      <div>
        {formData
          ?.filter((packing) => {
            return (
              (packing?.serialNumber || "")
                ?.toLowerCase()
                ?.includes(searchInput?.toLowerCase()) ||
              (packing?.reference || "")
                .toLowerCase()
                ?.includes(searchInput?.toLowerCase()) ||
              (packing?.name || packing?.model?.name || "")
                ?.toLowerCase()
                ?.includes(searchInput?.toLowerCase())
            );
          })
          ?.map((packingItemData) => {
            return (
              <PackingListItem
                key={packingItemData.id}
                packingItemData={packingItemData}
                totalTransport={totalTransport}
                taux={taux}
                length={formData?.length}
                setFormData={setFormData}
                handleItemChange={handleItemChange}
                handleDeleteItem={handleDeleteItem}
                formData={formData}
              />
            );
          })}
      </div>

      <button
        onClick={handleAddItem}
        className="addAttachment ms-3 mt-3 f12 text-softBlue border-0 bg-transparent underline"
      >
        + {t("Add an element")}
      </button>
    </div>
  );
};

export default PackingListTabContent;
