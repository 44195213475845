import React, { useEffect, useState } from "react";

import fileUploadIcon from "../../../../Images/Icons/sky-upload.png";
import imageUploadIcon from "../../../../Images/Icons/camera-upload.png";
import videoUploadIcon from "../../../../Images/Icons/video-upload.png";

import "./UploadButton.css";

const UploadButton = ({
  multiple = false,
  id,
  name,
  type,
  text,
  callback,
  className = "",
  defaultFilePreview,
}) => {
  const [filePreview, setFilePreview] = useState(null);

  let iconSource = fileUploadIcon;
  let iconWidth = 40;
  let acceptTypes = "/*";

  switch (type) {
    case "file":
      iconSource = fileUploadIcon;
      iconWidth = 40;
      acceptTypes = "/*";
      break;
    case "image":
      iconSource = imageUploadIcon;
      iconWidth = 32;
      acceptTypes = "image/*";
      break;
    case "video":
      iconSource = videoUploadIcon;
      iconWidth = 42;
      acceptTypes = "video/mp4,video/x-m4v,video/*";
      break;
    default:
      break;
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && type === "image") {
      const fileURL = URL.createObjectURL(file);
      setFilePreview(fileURL);
    }
    if (callback) {
      callback(event);
    }
    event.target.value = "";
  };

  useEffect(() => {
    if (defaultFilePreview instanceof File) {
      const fileURL = URL.createObjectURL(defaultFilePreview);
      setFilePreview(fileURL);
    } else {
      setFilePreview(defaultFilePreview);
    }
  }, [defaultFilePreview]);

  return (
    <div
      className={`bg-white colAlignC ${className} position-relative br5 overflow-hidden`}
    >
      <label
        htmlFor={name ?? type}
        className="pointer upload-btn-container colAlignC br5 d-flex flex-column justify-content-center align-items-center flex-grow-1 w-100"
      >
        <img
          src={iconSource}
          alt={type}
          width={iconWidth}
          height={28}
          className="upload-btn-icon"
        />
        {text !== "undefined" ? (
          <p className="f14 text-brightGrey mb-0 mt-3 w70 text-center">
            {text ?? ""}
          </p>
        ) : (
          ""
        )}

        {filePreview && type === "image" && (
          <div className="position-absolute top-0 left-0 w-100 h-100 bg-white">
            {type === "image" && (
              <img
                src={filePreview}
                alt="Preview"
                className="w-100 h-100 object-cover"
              />
            )}
          </div>
        )}
      </label>
      <input
        multiple={multiple}
        name={name}
        hidden
        id={id ?? name ?? type}
        accept={acceptTypes}
        type="file"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default UploadButton;
