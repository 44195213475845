import JSZip from "jszip";
import { saveAs } from "file-saver";
import { IMAGE_END_POINT } from "../Api/EndPoints";
import moment from "moment";

export const getFileName = ({ url, orderSlug, invoiceSlug }) =>
  `Facture${invoiceSlug}_Commande${orderSlug}`;
// url?.substring(url?.lastIndexOf("/") + 1, url?.length);

export const handleDownload = async (items) => {
  const zip = new JSZip();
  for (const item of items) {
    const fileUrl = IMAGE_END_POINT + item?.fileUrl;

    const fileName = `Facture${item?.invoiceSlug}_Commande${item?.orderSlug}.pdf`; // Create a proper file name string
    try {
      const response = await fetch(fileUrl);
      if (response.ok) {
        const fileData = await response.blob();
        zip.file(fileName, fileData); // Use the string as the file name
      }
    } catch (error) {
      console.log(error);
    }
  }

  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, `factures.zip`);
};

export const areFilesInList = (files, target = "attachement") => {
  if (target !== "attachment") {
    return files?.some((item) => item?.fileUrl);
  }
  return files?.some(
    (item) =>
      item?.fileName !== null &&
      item?.fileName !== undefined &&
      item?.fileUrl !== null &&
      item?.fileUrl !== undefined,
  );
};

export const eventTypeFr = {
  expo_fair: "Expo and Fair",
  open_house: "Open House",
  google_ads: "Google Ads",
  meta_ads: "Meta Ads",
  flyer_campaign: "Flyer Campaign",
  radio_campaign: "Radio Campaign",
  city_poster: "City Poster",
};

export const formatTimeToHHMM = (timeString) => {
  return moment(timeString, "HH:mm:ss:SSS").format("HH:mm");
};
export const formatDateTime = (dateTime) => {
  return moment(dateTime, "HH:mm:ss.SSS").format(
    "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (ZZ)",
  );
};
export function formatTimeStringToDate(timeString) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set the date to the beginning of the day
  const formattedDateTime = new Date(
    currentDate.getTime() + moment.duration(timeString).asMilliseconds(),
  );
  return formattedDateTime;
}

export const formatDate = (date, format = "DD/MM/YY") => {
  // console.log(date);
  return moment(date).format(format);
};

/* ******************************* user roles ******************************* */
//? userRoles is an object map associates numeric values with corresponding string roles :
export const userRoles = {
  0: "reseller", // => Numeric value 0 corresponds to the role "Revendeur"
  1: "admin", // => Numeric value 1 corresponds to the role "admin"
  2: "BusinessBroker", // Numeric value 2 corresponds to the role "apporteur d'affaire"
};

export const rolesColors = {
  reseller: "blue",
  admin: "darkBlue",
  BusinessBroker: "darkBlue",
};
