import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { soldProductColors } from "../../helpers/fakeData/Dashboard";
import { CatalogueSvg } from "../Svgs";
import adjustPrice from "../../Utils/AdjustPrice";
import DonutChart from "../Dashboard/SoldProducts/Chart";

const RevendeurRangeStats = () => {
  const { t } = useTranslation("common");
  const { stats } = useSelector((state) => state.resellerReducer);
  const [soldProductsStats, setSoldProductsStats] = useState([]);

  useEffect(() => {
    setSoldProductsStats(() => {
      if (!stats?.rangeStats) return [];
      return Object.keys(stats?.rangeStats)?.map((key, index) => ({
        id: key,
        color: soldProductColors[index] ?? "",
        label: key.slice(0, 1).toUpperCase() + key.slice(1).toLowerCase(),
        value: stats?.rangeStats[key] ?? 0,
      }));
    });
  }, [stats]);

  return (
    <div className="br5 bgWhite soldProductsContainer colAlignC justify-content-between align-items-start w-100">
      <div className="d-flex flex-wrap w-100 px-4 h-100">
        <div className="">
          <div className="p-4 alignH gap-2">
            <CatalogueSvg color="#14748e" size={20} />
            <span className="text-blue bold quickSand f22">
              {t("Sold products")}
            </span>
          </div>

          <div className="d-flex">
            <DonutChart
              data={soldProductsStats?.map((item) => item?.value ?? 1)}
              labels={soldProductsStats?.map((item) => item?.label ?? 0)}
            />
          </div>
        </div>
        <div className="colAlignC justify-content-between align-items-start gap-3 my-auto px-2 py-4 flex-grow-1">
          {soldProductsStats?.map(({ id, color, label, value }) => {
            let formattedValue = adjustPrice(value.toFixed(2).toString());

            return (
              <div
                className="alignH justify-content-between w-100 gap-3"
                key={id}
              >
                <div className="alignH gap-2">
                  <span
                    className={`br4 statColor`}
                    style={{ backgroundColor: color }}
                  ></span>
                  <span className="text-customBlack f16 align-self-start normal">
                    {label}
                  </span>
                </div>
                <span
                  className="f15 text-brightGrey"
                  style={{ textAlign: "end" }}
                >
                  {formattedValue.substring(
                    0,
                    formattedValue.split("")?.findIndex((el) => el === ".")
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RevendeurRangeStats;
