import React from "react";
import Separator from "../../../Components/Common/Separator/Separator";
import { useSelector, useDispatch } from "react-redux";
import getPanierSummaryData from "./getPanierSummaryData";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import adjustPrice from "../../../Utils/AdjustPrice";
import ProductItem from "../Panier/ProductItem";
import getProductsByType from "../Panier/getProductsByType";
import SwitchButton from "../../Common/FormElements/SwitchButton/SwitchButton";
import { toggleOrderWithoutPay } from "../../../REDUX/actions/panier";

function PanierSummary({ children, className = "" }) {
  const dispatch = useDispatch();
  const {
    panier,
    panierLoading,
    panierError,
    canOrderWithoutPay,
    panierSoftLoading,
  } = useSelector((state) => state.panierReducer);
  const { user } = useSelector((state) => state.userReducer);
  const mainProducts = getProductsByType(panier?.panierItems, 1);
  const accessoryProducts = getProductsByType(panier?.panierItems, 2);
  const marketingProducts = getProductsByType(panier?.panierItems, 3);
  const sparePartProducts = getProductsByType(panier?.panierItems, 4);
  const isAdmin = user?.attributes?.userRole === 1;

  const hasTax =
    (panier?.delivery?.address?.country?.data?.attributes?.code === "FR" ||
      panier?.delivery?.billingAddress?.toLowerCase()?.includes("france")) &&
    !panier?.delivery?.billingAddress?.toLowerCase()?.includes("d'outre-mer");

  const { taxCost, totalCost } = getPanierSummaryData(
    panier?.total,
    panier?.delivery?.price,
    20,
    hasTax
  );

  return (
    <ShadowLoader
      loading={panierLoading || panierSoftLoading}
      panierError={panierError}
    >
      <div className={`panierSummary pe-3 ${className}`}>
        <div className="d-flex justify-content-between align-items-center mb-5 flex-wrap">
          <h2 className="quickSand f22 bold text-blue">Récapitulatif</h2>
          {isAdmin ? (
            <div className="w-100 d-flex justify-content-between align-items-center">
              <p className="f14 text-ultraBlack mb-0">Commande à 0 (SAV)</p>
              <SwitchButton
                small
                isActive={canOrderWithoutPay}
                onClick={() => dispatch(toggleOrderWithoutPay())}
              />
            </div>
          ) : null}
        </div>
        <div className="panierContent mt-4 d-flex flex-column customScroll">
          {mainProducts?.length > 0 ? (
            <>
              <p className="f14 sBold text-softBlue mt-3">Produits</p>
              {mainProducts.map((product) => {
                return (
                  <ProductItem
                    key={product.id}
                    {...product}
                    panierID={panier?.id}
                  />
                );
              })}
            </>
          ) : null}
          {accessoryProducts?.length > 0 ? (
            <>
              <p className="f14 sBold text-softBlue mt-3">
                Produits complémentaires
              </p>
              {accessoryProducts.map((product) => {
                return (
                  <ProductItem
                    key={product.id}
                    {...product}
                    panierID={panier?.id}
                  />
                );
              })}
            </>
          ) : null}
          {marketingProducts?.length > 0 ? (
            <>
              <p className="f14 sBold text-softBlue mt-3">Produits marketing</p>
              {marketingProducts.map((product) => {
                return (
                  <ProductItem
                    key={product.id}
                    {...product}
                    panierID={panier?.id}
                  />
                );
              })}
            </>
          ) : null}
          {sparePartProducts?.length > 0 ? (
            <>
              <p className="f14 sBold text-softBlue mt-3">Pièces Détachées</p>
              {sparePartProducts.map((product) => {
                return (
                  <ProductItem
                    key={product.id}
                    {...product}
                    panierID={panier?.id}
                  />
                );
              })}
            </>
          ) : null}
        </div>
        <p className="f14 sBold text-ultraBlack">
          {panier?.productsCount ?? 0} articles
        </p>
        <Separator />

        <div className="d-flex justify-content-between mt-3">
          <p className="f116 sBold text-ultraBlack">Frais de livraison</p>
          <p className="f116 sBold text-ultraBlack me-2">
            {adjustPrice(
              (canOrderWithoutPay ? 0 : panier?.delivery?.price ?? 0)
                ?.toFixed(2)
                .toString()
            )}{" "}
            €
          </p>
        </div>
        <Separator />
        <div className="d-flex justify-content-between mt-3">
          <p className="f116 sBold text-ultraBlack">Total HT</p>
          <p className="f116 sBold text-ultraBlack me-2">
            {adjustPrice(
              (canOrderWithoutPay
                ? 0
                : panier?.total + panier?.delivery?.price ?? 0
              )
                ?.toFixed(2)
                .toString()
            )}{" "}
            €
          </p>
        </div>
        <Separator />

        <div className="d-flex justify-content-between mt-3">
          <p className="f116 sBold text-ultraBlack">TVA (20%)</p>
          <p className="f116 sBold text-ultraBlack me-2">
            {adjustPrice(
              (canOrderWithoutPay ? 0 : +taxCost ?? 0)?.toFixed(2)?.toString()
            )}{" "}
            €
          </p>
        </div>
        <Separator />
        <div className="d-flex justify-content-between mt-3">
          <p className="f116 sBold text-ultraBlack">TOTAL TTC </p>
          <p className="f116 sBold text-ultraBlack me-2">
            {adjustPrice(
              (canOrderWithoutPay ? 0 : (+totalCost ?? 0).toFixed(2)).toString()
            )}{" "}
            €
          </p>
        </div>
        <div className="childrenContainer me-2 d-flex flex-column">
          {children}
        </div>
      </div>
    </ShadowLoader>
  );
}

export default PanierSummary;
