import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_END_POINT } from "../../../Api/EndPoints";
import API from "../../../Api/Api";
const categoriesConfig = {
  SPA: {
    label: "Nbre Spa Stock",
    color: "bg-blue-600",
  },
  "SPA DE NAGE": {
    label: "Nbre Swim Spa Stock",
    color: "bg-blue-600",
  },
  SAUNA: {
    label: "Nbre Sauna Stock",
    color: "bg-bronze-100",
  },
};

const Statistics = () => {
  const { t } = useTranslation("common");

  const [generalStats, setGeneralStats] = useState([]);

  useEffect(() => {
    async function getStats() {
      try {
        const response = await API.get(`${API_END_POINT}api/stock-stats`);
        const data = response.data;

        const generalStatsData = [
          {
            label: "Product out of stock",
            value: data.productCount,
            bgColor: "bg-red-700",
          },
          {
            label: "Next Arrival",
            value: data.approvisionnementCount,
            unit: "container(s)",
            bgColor: "bg-violet-400",
          },
          ...Object.keys(data?.categoriesCount)?.map((key) => {
            const categoryConfig = categoriesConfig[key] || {};
            return {
              label: categoryConfig.label || key,
              value: data.categoriesCount[key],
              bgColor: categoryConfig.color || "bg-gray-400",
            };
          }),
        ];

        setGeneralStats(generalStatsData);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    }

    getStats();
  }, []);

  return (
    <div className="row g-3 align-items-stretch">
      {generalStats.map(({ label, value, unit, bgColor }) => (
        <div className="col-xxl-2 col-4 text-center">
          <div
            key={label}
            className={`p-3 br20 flex-1 flex-column h-100 alignC ${bgColor}`}
          >
            <span className="d-block text-white sBold f22 mb-2">
              {t(`${label}`)}
            </span>
            <span className="d-block text-white text-nowrap bold f30">
              {value}
              {unit && (
                <span className="text-white sBold f24 mb-2">
                  {" "}
                  {t(`${unit}`)}
                </span>
              )}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Statistics;
