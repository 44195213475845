import qs from "qs";
import { toast } from "react-toastify";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import store from "../store";
import {
  orderExtractor,
  ordersExtractor,
} from "../../Utils/DataExtractors/ordersExtractor";
import { adminOrdersQuery, ordersQuery } from "../apiQueries/orders";
import { ordersUrlFilters } from "../../Utils/urlFilters/ordersUrlFilters";
import { closeRightModal } from "./rightModal";
import moment from "moment";
import { deleteRecord } from "../../Utils/api";
import { isDefined, uploadFile } from "../../Utils";
import { savPdtsExtractor } from "../../Utils/DataExtractors/savExtractor";
import _ from "lodash";
import { toggleOrderWithoutPay } from "./panier";
import { createInvoice, deleteInvoice } from "./invoices";
import {
  getComission,
  getTotalComission,
  getTotalDeliveredCostBuyingCost,
  netMargin,
} from "../../Components/Commandes/ModifyOrder/orderUtils";

export const getOrders =
  ({ orderNumberSearch = "", refClientSearch = "", clear = false }) =>
  async (dispatch) => {
    if (clear) {
      dispatch({ type: "CLEAR_ORDERS" });
    }

    const { ordersCount, orders } = store.getState().ordersReducer;

    const currentPage = Math.ceil(orders?.length / 10);
    const page = currentPage + 1;
    if (
      (isDefined(ordersCount) && currentPage * 10 > ordersCount) ||
      ordersCount === 0
    ) {
      return;
    }
    // if (
    //   ordersCount === 0 ||
    //   (orders?.length >= ordersCount && ordersCount !== null)
    // ) {
    //   return;
    // }
    dispatch({ type: "ORDERS_LOADING" });
    try {
      const { user } = store.getState().userReducer;
      const filters = ordersUrlFilters(orderNumberSearch);

      const getResellerFilters = () => {
        if (user?.attributes?.profil?.data?.attributes?.reseller?.data?.id) {
          return `&filters[reseller][id][$eq]=${user?.attributes?.profil?.data?.attributes?.reseller?.data?.id}`;
        }
      };

      let userFilters = "";
      let bsFilters = "";
      if (user?.attributes?.userRole === 0) {
        userFilters = getResellerFilters();
      } else if (user?.attributes?.userRole === 2) {
        bsFilters = `filters[$or][0][business_affiliate][id][$eq]=${user?.attributes?.business_affiliate.data?.id}&filters[$or][1][reseller][business_affiliate][id][$eq]=${user?.attributes?.business_affiliate.data?.id}&`;
      }

      const { orders: currOrders } = store.getState().ordersReducer;
      const query =
        user?.attributes?.userRole === 1 ? adminOrdersQuery() : ordersQuery();

      let fetchUrl = `${API_END_POINT}api/orders?${filters}${bsFilters}${query}${userFilters}&sort[0]=slug:desc`;

      // THAT CAUSES A PROBLEM IN ORDERS LIST ( INCOMPLETE LIST )
      // if (currOrders[currOrders?.length - 1]?.createdAt) {
      //   fetchUrl =
      //     fetchUrl +
      //     `&filters[createdAt][$lte]=${
      //       new Date(currOrders[currOrders?.length - 1]?.createdAt).getTime() ??
      //       ""
      //     }`;
      // }

      const { data: allOrders } = await API.get(fetchUrl, {
        params: {
          pagination: {
            pageSize: 10,
            page,
          },
        },
      });

      const orders = ordersExtractor(allOrders?.data);
      if (clear) {
        dispatch({ type: "CLEAR_ORDERS" });
      }
      dispatch({
        type: "ORDERS_FETCHED",
        payload: {
          orders,
          clear,
          ordersCount: allOrders?.meta?.pagination?.total,
        },
      });
      dispatch(handleShowOrderCard(store.getState().ordersReducer.showOrder));
    } catch (error) {
      throw error;
    }
  };

export const createOrder = (callback, payload, t) => async (dispatch) => {
  dispatch({ type: "ORDERS_LOADING" });
  try {
    const currentPanier = store.getState().panierReducer.panier;

    const hasTax =
      (currentPanier?.delivery?.address?.country?.data?.attributes?.code ===
        "FR" ||
        currentPanier?.delivery?.billingAddress
          ?.toLowerCase()
          ?.includes("france")) &&
      !currentPanier?.delivery?.billingAddress
        ?.toLowerCase()
        ?.includes("d'outre-mer");

    const canOrderWithoutPay =
      store.getState().panierReducer.canOrderWithoutPay;
    const user = store.getState().userReducer.user;

    const deliveredCost = currentPanier?.panierItems
      // ?.filter(({ productType }) => productType === 1)
      .reduce(
        (acc, item) =>
          acc +
          (item?.packings?.reduce(
            (innerAcc, innerItem) =>
              innerAcc +
              (innerItem?.attributes?.model?.data?.attributes?.productType === 1
                ? innerItem?.attributes?.deliveredCost
                : innerItem?.attributes?.buyingCost),
            //  + innerItem?.attributes?.transport //client want the same deliveredCost in the approv list items

            0
          ) ?? 0),
        0
      );

    const deliveryPrice = currentPanier?.delivery?.price;
    const montantHT = currentPanier?.total + deliveryPrice;
    const montantTTC = (currentPanier?.total + deliveryPrice) * 1.2;
    const margeNet = montantHT - deliveredCost - deliveryPrice;
    const txMarge = (margeNet / (montantHT - deliveryPrice)) * 100;

    // const comissions = cartAffiliate?.attributes?.comissions?.data;
    // let commission = 0;
    // if (comissions?.length > 0) {
    //   currentPanier?.panierItems?.forEach((item) => {
    //     commission += item?.packings?.reduce((acc, packing) => {
    //       const rangeId =
    //         packing?.attributes?.model?.data?.attributes?.gamme?.data?.id;
    //       const currentComission = comissions?.find((comission) => {
    //         return +rangeId === +comission?.attributes?.range?.data?.id;
    //       });
    //
    //       if (isDefined(currentComission)) {
    //         return (
    //           acc +
    //           (+packing?.attributes?.model?.data?.attributes?.retailerPriceHT -
    //             +packing?.attributes?.deliveredCost) *
    //             (+currentComission?.attributes?.value / 100)
    //         );
    //       }
    //       return 0;
    //     }, 0);
    //   });
    // }

    const { data: order } = await API.post(
      `${API_END_POINT}api/orders?populate=*`,
      {
        data: {
          productsCount: currentPanier.productsCount,
          total: hasTax ? montantTTC : montantHT,
          refClient: `${user.attributes.lastName} ${user.attributes.firstName}`,
          realRefClient: currentPanier?.realRefClient,
          status: 0,
          panier_items: currentPanier.panierItems.map((item) =>
            Number(item.id)
          ),
          delivery: currentPanier?.delivery?.id,
          TVA: 20,
          panier: currentPanier?.id,
          montantHT,
          margeNet,
          txMarge,
          paymentStatus: !!canOrderWithoutPay,
          reseller: canOrderWithoutPay
            ? payload?.resellerId
            : currentPanier?.reseller,
          // commission,
          business_affiliate: canOrderWithoutPay
            ? payload?.affiliateId
            : currentPanier?.business_affiliate,
        },
      }
    );

    // return;

    // dispatch(
    //   updateProductsQuantities({ panierItems: currentPanier.panierItems })
    // );

    toast.success(t("Order successfully created!"));

    dispatch({ type: "ORDER_CREATED" });

    for (const item of currentPanier?.panierItems) {
      for (const packing of item?.packings) {
        await API.put(`${API_END_POINT}api/packings/${packing?.id}`, {
          data: {
            sold: true,
          },
        });
      }
    }
    await deleteRecord(currentPanier.id, "paniers");
    // here before toggle order without pay to false save in order tha is from sav( update api)
    // orderID: order?.data?.id
    // update sav ticket and full orders there
    if (canOrderWithoutPay) {
      await API.put(`api/orders/${order?.data?.id}`, {
        data: {
          ticket_sav: payload?.savID,
        },
      });
    }

    canOrderWithoutPay && (await dispatch(toggleOrderWithoutPay(false)));
    await API.put(`${API_END_POINT}api/orders/${order?.data?.id}?populate=*`, {
      data: { slug: String(order?.data?.id) },
    });
    callback();

    // if (canOrderWithoutPay) callback();
    // dispatch({ type: "CLEAR_PANIER" });
    // dispatch(currentUser());
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDERS_ERROR", payload: error });
    toast.error("une erreur est survenue!");
  }
};

export const deleteOrder = (payload) => async (dispatch) => {
  dispatch({ type: "ORDERS_LOADING" });
  try {
    // Before delete Order update the panier item packing to sold=false ( return to the stock )
    const panierItems = payload?.panierItems?.data;
    for (let i = 0; i < panierItems?.length; i++) {
      const packing = panierItems[i]?.attributes?.packings?.data[0];
      if (packing?.id) {
        await API.put(`${API_END_POINT}api/packings/${packing?.id}`, {
          data: {
            sold: false,
          },
        });
      }
    }
    await API.delete(`${API_END_POINT}api/orders/${payload?.id}`);
    if (payload?.comments?.length > 0) {
      for (const commentId of payload?.comments) {
        await deleteRecord(commentId, "comments");
      }
    }
    dispatch({ type: "ORDER_DELETED" });
    dispatch(ordersReload());
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDERS_ERROR", payload: error });
  }
};

export const updateOrderPaymentStatus =
  (payload, callback) => async (dispatch) => {
    dispatch({ type: "ORDERS_LOADING" });
    try {
      const { id, paymentStatus } = payload;

      const {
        data: { data },
      } = await API.put(`api/orders/${id}?populate=*`, {
        data: {
          paymentStatus: !paymentStatus,
        },
      });

      data?.attributes?.paymentStatus && !data?.attributes?.invoice?.data?.id
        ? await dispatch(
            createInvoice({
              id: data?.id,
              resellerID: data?.attributes?.reseller?.data?.id,
            })
          )
        : data?.attributes?.payments?.data?.length === 0 &&
          data?.attributes?.invoice?.data?.id &&
          dispatch(deleteInvoice({ id: data?.attributes?.invoice?.data?.id }));

      dispatch({ type: "ORDER_UPDATED" });
      await callback();
      dispatch(ordersReload());
    } catch (error) {
      console.log(error);
      dispatch({ type: "ORDERS_ERROR", payload: error });
    }
  };

export const updateDelivery = (payload) => async (dispatch) => {
  dispatch({ type: "ORDERS_LOADING" });
  try {
    await API.put(`${API_END_POINT}api/deliveries/${payload.id}`, {
      data: {
        date: payload.date,
      },
    });
    dispatch({ type: "ORDER_UPDATED" });
    dispatch(ordersReload());
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDERS_ERROR" });
  }
};

// export const updateProductsQuantities = (payload) => async (dispatch) => {
//   try {
//     payload.panierItems.forEach((panierItem) => {
//       dispatch(
//         updateProductQuantity(
//           {
//             availableQuantity:
//               panierItem.availableQuantity - panierItem.quantity,
//           },
//           panierItem.productID
//         )
//       );
//     });
//   } catch (error) {
//     console.log(error);
//     dispatch({ type: "ORDERS_ERROR", payload: error });
//   }
// };

export const getOneOrder = (payload) => async (dispatch) => {
  dispatch({ type: "ORDER_LOADING" });
  try {
    const { data: fetchedOrder } = await API.get(
      `api/orders/${payload.id}?${adminOrdersQuery()}`
    );
    const order = orderExtractor(fetchedOrder.data);
    dispatch({ type: "ORDER_FETCHED", payload: order });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
  }
};

export const updateOrder = (payload, paymentsTab, t) => async (dispatch) => {
  dispatch({ type: "ORDER_LOADING" });

  try {
    await API.put(`api/orders/${payload.id}?populate=*`, {
      data: {
        reseller: payload.updatedOrder.reseller?.code,
        commission: payload.updatedOrder.commission,
        margeNet: payload.updatedOrder.margeNet,
        txMarge: payload.updatedOrder.txMarge,
        montantHT: payload.updatedOrder.montantHT,
        TVA: payload.updatedOrder.TVA,
        total: payload.updatedOrder.total,
        business_affiliate: payload.updatedOrder?.businessAffiliate?.code,
        slug: payload.updatedOrder.slug,
      },
    });

    for (const item of payload?.updatedOrder?.panierItems?.data) {
      await API.put(`api/panier-items/${item?.id}`, {
        data: {
          ...item?.attributes,
          discount: item?.attributes?.discount,
          refClient: item?.attributes?.refClient,
        },
      });
    }

    const {
      data: { data },
    } = await API.get(`api/orders?filters[id][$eq]=${payload?.id}&populate=*`);
    const paymentsLength = data[0]?.attributes?.payments?.data?.length;

    if (payload.updatedOrder.payments) {
      for (const payment of payload.updatedOrder.payments) {
        if (payment.newPayment) {
          await API.post(`api/payments`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
              order: payload.id,
            },
          });
        } else {
          await API.put(`api/payments/${payment.id}`, {
            data: {
              amount: payment.amount,
              date: moment(payment.date).format("YYYY-MM-DD"),
            },
          });
        }
      }
    }

    if (
      payload?.updatedOrder?.invoiceSlug &&
      payload?.updatedOrder?.invoice?.id
    ) {
      await API.put(`api/invoices/${payload?.updatedOrder?.invoice?.id}`, {
        data: { slug: payload?.updatedOrder?.invoiceSlug },
      });
    }
    if (
      payload?.updatedOrder?.invoiceSlugDate &&
      payload?.updatedOrder?.invoice?.id
    ) {
      await API.put(`api/invoices/${payload?.updatedOrder?.invoice?.id}`, {
        data: {
          slugDate: moment(payload?.updatedOrder?.invoiceSlugDate)
            ?.utc(true)
            .toDate(),
        },
      });
    }

    // IF PAIMENTS  CREATE INVOICE
    if (paymentsTab) {
      payload?.updatedOrder?.paymentStatus === false &&
        payload.updatedOrder.payments?.length > 0 &&
        paymentsLength === 0 && // create invoice only at the first create on payment
        (await dispatch(
          createInvoice({
            id: payload?.id,
            resellerID: payload?.updatedOrder?.reseller?.data?.id,
          })
        ));
      payload?.updatedOrder?.paymentStatus === false &&
        payload?.updatedOrder?.payments?.length === 0 &&
        payload?.updatedOrder?.invoiceId &&
        (await dispatch(
          deleteInvoice({ id: payload?.updatedOrder?.invoiceId })
        ));
    }

    // dispatch(
    //   updateProductsQuantities({ panierItems: payload.updatedOrder.products })
    // );

    dispatch({ type: "ORDER_UPDATED" });
    dispatch({ type: "ORDER_RELOAD" });
    toast.success(t("Order successfully updated!"));
    dispatch({ type: "ORDERS_RELOAD" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
    toast.error("Une erreur est survenue!");
  }
};

export const updateOrderStatus = (payload, t) => async (dispatch) => {
  dispatch({ type: "ORDER_LOADING" });
  try {
    await API.put(`api/orders/${payload.id}`, {
      data: {
        status: payload.status,
      },
    });

    dispatch({ type: "ORDER_UPDATED" });
    toast.success(t("Order successfully updated!"));
    dispatch({ type: "ORDERS_RELOAD" });
  } catch (error) {
    dispatch({ type: "ORDER_ERROR", payload: error });
    toast.error("Une erreur est survenue!");
  }
};

export const updateOrderDelivery = (payload, t) => async (dispatch) => {
  const resellers = payload?.resellers;
  const orderID = payload.orderID;
  dispatch({ type: "ORDER_LOADING" });
  try {
    await API.put(`api/orders/${orderID}`, {
      data: {
        phoneNumber: payload.phoneNumber,
      },
    });
    let newDeliveryID = payload?.deliveryID;
    if (isDefined(payload.deliveryID)) {
      await API.put(`api/deliveries/${payload.deliveryID}`, {
        data: {
          mode: payload?.mode,
          deliveryMessage: payload?.deliveryMessage,
        },
      });
    } else {
      const { data } = await API.post(`api/deliveries`, {
        data: {
          mode: payload?.mode,
          deliveryMessage: payload?.deliveryMessage,
        },
      });
      newDeliveryID = data?.data?.id;
    }

    if (isDefined(payload.addressID)) {
      await API.put(`api/addresses/${payload.addressID}`, {
        data: {
          street: payload?.street,
          zip: payload?.zip,
          city: payload?.city.code,
          country: payload?.country.code,
        },
      });
    } else {
      await API.post(`api/addresses`, {
        data: {
          delivery: newDeliveryID,
          street: payload?.street,
          zip: payload?.zip,
          city: payload?.city.code,
          country: payload?.country.code,
        },
      });
    }

    await API.post(`api/orders/send-delivery-pdf`, {
      orderID: orderID,
      resellers,
    });

    dispatch({ type: "ORDER_UPDATED" });
    toast.success(t("Order successfully updated!"));
    dispatch({ type: "ORDERS_RELOAD" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
    toast.error("Une erreur est survenue!");
  }
};

export const deletePayment = (payload) => async (dispatch) => {
  dispatch({ type: "ORDER_LOADING" });
  try {
    await API.delete(`api/payments/${payload.id}`);
    dispatch({ type: "ORDER_UPDATED" });
    dispatch({ type: "ORDER_RELOAD" });
    dispatch({ type: "APPROV_RELOAD" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
  }
};
export const deletePanierItem = (payload) => async (dispatch) => {
  dispatch({ type: "ORDER_LOADING" });
  try {
    await API.delete(`api/panier-items/${payload.id}`);
    dispatch({ type: "ORDER_UPDATED" });
    dispatch({ type: "ORDER_RELOAD" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
  }
};

export const createOrderAdmin = (payload, t) => async (dispatch) => {
  dispatch({ type: "ORDERS_LOADING" });
  try {
    const buyer = payload.createdOrder.buyer;
    const { data: createdOrder } = await API.post(`api/orders`, {
      data: {
        buyer: buyer?.code ? buyer.name : buyer,
        commission: payload.createdOrder.commission,
        margeNet: payload.createdOrder.margeNet,
        txMarge: payload.createdOrder.txMarge,
        montantHT: payload.createdOrder.montantHT,
        TVA: payload.createdOrder.TVA,
        total: payload.createdOrder.total,
        business_affiliate: payload.createdOrder.businessAffiliate?.code,
        reseller: buyer?.code,
        productsCount: payload.createdOrder.products.reduce(
          (total, { quantity }) => (total += Number(quantity)),
          0
        ),
        status: 0,
      },
    });
    for (const product of payload.createdOrder.products) {
      await API.post(`api/panier-items`, {
        data: {
          product: product.productID,
          quantity: product.quantity,
          discount: product.discount,
          order: createdOrder.data.id,
        },
      });
    }
    if (payload.createdOrder.payments) {
      for (const payment of payload.createdOrder.payments) {
        await API.post(`api/payments`, {
          data: {
            amount: payment.amount,
            date: moment(payment.date).format("YYYY-MM-DD"),
            order: createdOrder.data.id,
          },
        });
      }
    }
    const { data: createdDelivery } = await API.post("api/deliveries", {
      data: { order: createdOrder.data.id },
    });
    await API.post("api/addresses", {
      data: { delivery: createdDelivery.data.id },
    });
    dispatch({ type: "INVOICES_LOADING" });
    await API.post(`${API_END_POINT}api/invoices`, {
      data: {
        order: createdOrder.data.id,
      },
    });
    dispatch({ type: "INVOICE_CREATED" });
    // dispatch(
    //   updateProductsQuantities({
    //     panierItems: payload.createdOrder.products,
    //   })
    // );
    dispatch({ type: "ORDER_CREATED" });
    toast.success(t("Order successfully created!"));
    dispatch(closeRightModal());
    dispatch({ type: "ORDERS_RELOAD" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ORDER_ERROR", payload: error });
    toast.error("Une erreur est survenue!");
  }
};

export const ordersReload = () => async (dispatch) => {
  dispatch({ type: "ORDERS_RELOAD" });
};

// COMMENTS

export const getOrderComments = (order) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COMMENTS_ORDER_LOADING" });
    const query = qs.stringify({
      populate: [
        "attachments.file",
        "user.photo",
        "order",
        "user.profil.reseller",
        "user.business_affiliate",
      ],
      sort: ["createdAt:desc"],
      filters: {
        order: { id: { $eq: order } },
      },
    });
    const {
      data: { data: commentData },
    } = await API.get(`${API_END_POINT}api/comments?${query}`);
    dispatch({ type: "FETCH_COMMENTS_ORDER_SUCCESS", payload: commentData });
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_ORDER_ERROR", payload: error });
  }
};

export const createOrderComment =
  (comment, order, user, successCallback, t) => async (dispatch) => {
    dispatch({ type: "CREATE_COMMENTS_ORDER_LOADING" });

    try {
      const {
        data: { data: commentData },
      } = await API.post(`${API_END_POINT}api/comments?populate=*`, {
        data: {
          text: comment?.text,
          order,
          user,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                date: new Date(),
                file: fileId,
                comment: commentData?.id,
              },
            }
          );
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      dispatch({
        type: "CREATE_COMMENTS_ORDER_SUCCESS",
        payload: {
          ...commentData,
          attributes: { ...commentData?.attributes },
        },
      });
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

export const deleteOrderComment = (comment) => {
  console.log(comment);
  try {
  } catch (error) {
    console.log(error);
  }
};

export const getProductsFromOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: "GET_PRODUCTS_ORDER_LOAD" });
  try {
    const { data: fetchedOrder } = await API.get(
      `api/orders/${orderId}?${adminOrdersQuery()}`
    );
    const productsFromOrder = savPdtsExtractor(
      fetchedOrder?.data?.attributes?.panier_items?.data
    );

    dispatch({ type: "GET_PRODUCTS_ORDER_SUCC", payload: productsFromOrder });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_PRODUCTS_ORDER_ERROR", payload: error });
  }
};

export const handleShowOrderCard = (id) => async (dispatch) => {
  dispatch({
    type: "SHOW_ORDER",
    payload: id,
  });
};
export const handleCloseOrderCard = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_ORDER",
  });
};

export const getResellerAndBusAffiliateFromSAV =
  (payload) => async (dispatch) => {
    dispatch({
      type: "GET_RESELLER_AFFILIATE_SUCC",
      payload,
    });
  };

export const updateOrderItems =
  (payload, deletedPackings) => async (dispatch) => {
    dispatch({ type: "ORDER_LOADING" });
    try {
      const { id, panierItems } = payload;

      const totalDiscounts = panierItems?.data?.reduce(
        (acc, { attributes: { discount } }) => acc + +discount,
        0
      );

      const totalAmountHT = payload?.panierItems?.data
        ?.map(({ attributes }) => {
          return {
            price: isDefined(attributes?.itemAmount)
              ? payload?.createdAt < "2024-03-12" && payload?.oldId
                ? attributes?.itemAmount / 1.2
                : attributes?.itemAmount
              : attributes.product?.data?.attributes?.retailerPriceHT,
            quantity: parseInt(attributes.quantity),
          };
        })
        .reduce((total, { price, quantity = 1 }) => {
          return (total += price);
        }, 0);

      const newMontantHT =
        totalAmountHT + payload?.delivery?.price - totalDiscounts;

      const currentBA = isDefined(payload?.businessAffiliate?.code)
        ? await getComission(payload?.businessAffiliate?.code)
        : undefined;

      const totalComission = getTotalComission(currentBA, payload) ?? 0;

      const newMargeNet = netMargin({
        commission: totalComission,
        montantHT: newMontantHT ?? 0,
        deliveryPrice: payload?.delivery?.price ?? 0,
        deliveredCost:
          panierItems?.data?.reduce((acc, item) => {
            const modelProductType =
              item?.attributes?.packings?.data[0]?.attributes?.model?.data
                ?.attributes?.productType;
            const packingDeliveredCost =
              item?.attributes?.packings?.data[0]?.attributes?.deliveredCost;
            const packingBuyingCost =
              item?.attributes?.packings?.data[0]?.attributes?.buyingCost;
            return (
              acc +
              (modelProductType === 1
                ? +packingDeliveredCost
                : +packingBuyingCost)
            );
          }, 0) ?? 0,
      });

      await API.put(`api/orders/${id}?populate=*`, {
        data: {
          commission: totalComission,
          montantHT: newMontantHT,
          total: newMontantHT * 1.2,
          TVA: newMontantHT * 1.2 - newMontantHT,
          margeNet: +newMargeNet,
          txMarge: !isNaN(
            (+newMargeNet / (+newMontantHT - +payload?.delivery?.price)) * 100
          )
            ? (+newMargeNet / (+newMontantHT - +payload?.delivery?.price)) * 100
            : null,
          panier_items: payload?.panierItems?.data,
        },
      });

      // AFTER DELETING AN ITEM, BACK THE STOCK OF HIS PACKING
      if (deletedPackings) {
        for (const packing of deletedPackings) {
          await API.put(`api/packings/${packing}?populate=*`, {
            data: {
              sold: false,
            },
          });
        }
      }

      /**
       * CREATE NEW INVOICE WITH NEW AMOUNTS
       */
      // dispatch({ type: "INVOICES_LOADING" });
      // const { data: createdInvoiceAttach } = await API.post(
      //   `${API_END_POINT}api/attachments`,
      //   {
      //     data: {
      //       title: `facture_commande_${data?.id}`,
      //     },
      //   }
      // );

      // await API.post(`${API_END_POINT}api/orders/create-order-invoice`, {
      //   attachmentID: createdInvoiceAttach?.data?.id,
      //   orderID: data?.id,
      //   date: new Date(),
      // });

      // await API.post(`${API_END_POINT}api/invoices`, {
      //   data: {
      //     order: data?.id,
      //     attachment: createdInvoiceAttach?.data?.id,
      //     reseller: data?.attributes?.reseller?.data?.id,
      //     // user?.attributes?.profil?.data?.attributes?.reseller?.data?.id,
      //   },
      // });

      // dispatch({ type: "INVOICE_CREATED" });

      // for (const product of payload.updatedOrder.products) {
      //   if (!product.newProduct) {
      //     await API.put(`api/panier-items/${product.panierItemId}`, {
      //       data: {
      //         product: product.productID,
      //         packings: product.id,
      //         quantity: product.quantity,
      //         discount: product.discount,
      //       },
      //     });
      //   } else {
      //     if (product.panierItemId) {
      //       await API.post(`api/panier-items`, {
      //         data: {
      //           product: product.productID,
      //           packings: product.id,
      //           quantity: product.quantity,
      //           discount: product.discount,
      //           order: payload.id,
      //         },
      //       });
      //     }
      //   }
      // }
      // dispatch(
      //   updateProductsQuantities({ panierItems: payload.updatedOrder.products })
      // );
      dispatch({ type: "ORDER_UPDATED" });
      dispatch({ type: "ORDER_RELOAD" });
      dispatch({ type: "ORDERS_RELOAD" });
    } catch (error) {
      console.log(error);
      dispatch({ type: "ORDER_ERROR", payload: error });
      toast.error("Une erreur est survenue!");
    }
  };

export const updateDeliveryPrice = (payload) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await API.get(`api/orders?filters[id][$eq]=${payload?.id}&populate=*`);

    const deliveryId = data[0]?.attributes?.delivery?.data?.id;
    const oldDeliveryPrice =
      data[0]?.attributes?.delivery?.data?.attributes?.price;
    const newMontantHT =
      +data[0]?.attributes?.montantHT -
      +oldDeliveryPrice +
      +payload?.delivery?.price;

    // UPDATE DELIVERY PRICE
    await API.put(`api/deliveries/${deliveryId}`, {
      data: {
        price: payload?.delivery?.price,
      },
    });

    // UPDATE HT, TVA AND TTC PRICES
    await API.put(`api/orders/${payload?.id}`, {
      data: {
        montantHT: newMontantHT,
        total: newMontantHT * 1.2,
        TVA: newMontantHT * 1.2 - newMontantHT,
      },
    });

    dispatch({ type: "ORDERS_RELOAD" });
  } catch (error) {
    console.log(error);
    toast.error("Une erreur est survenue!");
  }
};
