import React from "react";
import ReactDOM from "react-dom";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { DownloadPdfSvg } from "../../Svgs";
import { saveAs } from "file-saver";

const MediaPreview = ({
  isUrl,
  mediaType,
  file,
  setPreviewMedia = () => {},
}) => {
  const Component = () => {
    return (
      <div className="z-9999 position-fixed top-0 left-0 vw-100 vh-100 p-5 d-flex align-items-center justify-content-center">
        <div
          className="backdrop show bg-darkBlue"
          onClick={() => setPreviewMedia()}
        />
        <div className="relative mx-auto d-flex align-items-center justify-content-center my-auto z-1">
          <button
            className="close-btn-modal btn-close btn-close-white"
            aria-label="Close"
            onClick={() => setPreviewMedia()}
          >
            x
          </button>
          <button
            className="download-btn-modal bg-transparent"
            onClick={(e) => {
              e.stopPropagation();
              saveAs(IMAGE_END_POINT + file);
            }}
          >
            <DownloadPdfSvg bgColor="#FFFFFFC0" />
          </button>

          {(file?.type?.includes("video") || mediaType === "video") && (
            <video
              style={{
                maxHeight: "calc(100vh - 6rem)",
                width: "auto",
                maxWidth: "100%",
              }}
              controls
              autoPlay
            >
              <source src={IMAGE_END_POINT + file} />
            </video>
          )}

          {(file?.type?.includes("image") || mediaType === "image") && (
            <img
              src={IMAGE_END_POINT + file}
              alt="preview"
              style={{
                maxHeight: "calc(100vh - 6rem)",
                width: "auto",
                maxWidth: "100%",
              }}
            />
          )}
        </div>
      </div>
    );
  };
  return ReactDOM.createPortal(<Component />, document.body);
};

export default MediaPreview;
