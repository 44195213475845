import React, { useEffect, useState } from "react";

import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import Button from "../../Common/FormElements/Button/Button";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import UpdateTicketSAVContent from "./UpdateTicketSAVContent";
import { useDispatch, useSelector } from "react-redux";
import {
  createCartSAV,
  getOneTicketSAV,
  updateTicketSAV,
} from "../../../REDUX/actions/ticketSAV";
import "./UpdateTicketSav.css";
import {
  getInterventionSelectValue,
  interventionSelectValues,
} from "./UpdateSavTabContent/getInterventionType";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpdateTicketSav = ({ id }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("General information");
  const [formData, setFormData] = useState({});

  const { ticketSAV, ticketSAVLoading, ticketSAVError, ticketSAVReload } =
    useSelector((state) => state.ticketSAVReducer);
  const { user } = useSelector((state) => state.userReducer);

  const userRole = user?.attributes?.userRole;

  const tabs =
    userRole === 1
      ? ["General information", "Notes", "Attachments", "Client Comments"]
      : ["General information", "Attachments", "Client Comments"];

  const handleSubmit = () => {
    dispatch(
      updateTicketSAV(
        {
          id,
          ...formData,
        },
        t
      )
    );
  };

  useEffect(() => {
    dispatch(getOneTicketSAV({ id }));
  }, [dispatch, id, ticketSAVReload]);

  useEffect(() => {
    const intervention = ticketSAV?.intervention;
    const responsable = ticketSAV?.users.find((user) => user.userRole === 1);

    setFormData({
      ...ticketSAV,
      intervention: intervention
        ? {
            ...ticketSAV?.intervention,
            type: interventionSelectValues?.find((el) => {
              return +el?.code === +intervention?.type;
            }),
          }
        : undefined,
      responsable: responsable
        ? {
            name: responsable.firstName + " " + responsable.lastName,
            code: responsable.id,
          }
        : undefined,
    });
  }, [ticketSAV]);

  return (
    <div className="newTicketSAV d-flex flex-column justify-content-between h100">
      <div>
        <h2 className="bold rightModalTitle px-5 mb-4">
          {t("AS Ticket")} #{id}
        </h2>
        <TabsNavbar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabClassName={"me-5"}
        />
        <ShadowLoader loading={ticketSAVLoading} error={ticketSAVError}>
          <UpdateTicketSAVContent
            activeTab={activeTab}
            formData={formData}
            setFormData={setFormData}
          />
        </ShadowLoader>
      </div>
      {activeTab === "General information" && (
        <div className="d-flex justify-content-center mt-5 gap-3">
          <Button className="px-5" onClick={handleSubmit}>
            {t("Save")}
          </Button>

          <Button
            variant="outline"
            className="px-5"
            onClick={() =>
              dispatch(
                createCartSAV(navigate, {
                  reseller: ticketSAV?.reseller?.id,
                  business_affiliate:
                    ticketSAV?.reseller?.attributes?.business_affiliate?.data
                      ?.id,
                  ticket_sav: ticketSAV?.id,
                })
              )
            }
          >
            {t("Create an order")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UpdateTicketSav;
