import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import moment from "moment";
import { capitalize } from "lodash";
import adjustPrice from "../../../Utils/AdjustPrice";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelReservation,
  updateStockStatus,
} from "../../../REDUX/actions/advanced-stock";
import LoadingView from "../../../Utils/ViewWrapper/LoadingView/LoadingView";
import { columns } from "./AdvancedStockColumns";
import { headers } from "./AdvancedStockTableHeaders";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import ReserveStock from "./ReserveStock";
import ConfirmModal from "../../Common/Modal/ConfirmModal";

const AdvancedStockTable = ({ data, filters, type = "Product" }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");

  const { loading } = useSelector((state) => state.advancedStockReducer);

  const tableHeaders = headers(t)[type];
  const statusMap = {
    delivered: {
      label: "Delivered",
      color: "#829da5",
    },
    "in-stock": {
      label: "Stock",
      color: "#41cf8b",
    },
    "in-supply": {
      label: "In supply",
      color: "#ff8282",
    },
    ordered: {
      label: "Order",
      color: "#41cf8b",
    },
    "pre-ordered": {
      label: "Preorder",
      color: "#ff8282",
    },
    reserved: {
      label: "Reserved",
      color: "#a97eff",
    },
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (rowId) =>
    setExpandedRow((prev) => (prev === rowId ? null : rowId));

  const renderRowDetails = (row) => {
    return (
      <tr className="table-blue-200 medium">
        <td colSpan={tableHeaders.length} className="p-0">
          <table className="table-transparent w-full table m-0">
            <tbody>
              <tr className="table-blue-200 d-flex gap-3">
                <td className="py-3 px-4 f14 text-white alignC d-block col-3">
                  <div className="flex flex-col normal">
                    <p className="f14 text-white mb-0">
                      {capitalize(t("supply"))} :{" "}
                      {row.approvisionnement?.containerName ?? "N/A"}
                    </p>
                    <p className="f14 text-white mb-0">
                      {t("Estimated date")} :{" "}
                      {row.approvisionnement?.estimatedDate
                        ? moment(row.approvisionnement?.estimatedDate).format(
                            "DD/MM/YYYY"
                          )
                        : "N/A"}
                    </p>
                    <p className="f14 text-white mb-0">
                      {t("Arrival date")} :{" "}
                      {row.approvisionnement?.arrivalDate
                        ? moment(row.approvisionnement?.arrivalDate).format(
                            "DD/MM/YYYY"
                          )
                        : "N/A"}
                    </p>
                  </div>
                </td>
                <td className="py-3 px-4 f14 text-white alignC d-block col-3">
                  <div className="flex flex-col normal">
                    <p className="f14 text-white mb-0">
                      {t("Delivered cost")} :{" "}
                      {adjustPrice(
                        (row?.returnCost ?? 0).toFixed(2).toString()
                      )}{" "}
                      €
                    </p>
                  </div>
                </td>
                <td className="py-3 px-4 f14 text-white alignC d-block col-3">
                  <div className="flex flex-col normal">
                    <p className="f14 text-white mb-0">
                      {t("Store / Client")} :{" "}
                      {row.commande?.reseller?.name ?? "-"}
                    </p>
                    {row?.status === "reserved" ? (
                      row?.reservation && (
                        <p className="f14 text-white mb-0">
                          {t("Reserved to")}{" "}
                          {row?.reservation?.resellerName ?? "Poseidon Spa"}
                        </p>
                      )
                    ) : (
                      <p className="f14 text-white mb-0">
                        {t("Order number")} : {row?.commande?.number ?? "-"}
                      </p>
                    )}
                  </div>
                </td>
                <td className="py-3 px-4 f14 text-white alignC d-block col-3">
                  <div className="flex flex-col normal">
                    <p className="f14 text-white mb-0">
                      {t("Delivery date")} :{" "}
                      {row?.commande?.estimatedDeliveryDate
                        ? moment(row.commande?.estimatedDeliveryDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </p>
                    <p className="f14 text-white mb-0">
                      {t("Actual delivery date")} :{" "}
                      {row?.commande?.realDeliveryDate
                        ? moment(row.commande?.realDeliveryDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </p>
                  </div>
                </td>
              </tr>
              {row?.reservation?.note?.length > 0 && (
                <tr className="table-blue-200 d-flex gap-3">
                  <td className="py-3 px-4 f14 text-white alignC d-block col-12">
                    <div className="d-flex gap-1 flex-column">
                      <p className="f14 text-white mb-0 text-nowrap">Note :</p>
                      <p className="f14 text-white mb-0">
                        {row?.reservation?.note}{" "}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="table table-transparent">
        <thead className="table-dimGrey text-nowrap">
          <tr className="border-none">
            {tableHeaders.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="py-3 px-4 text-lightBlack sBold f14"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={tableHeaders.length} className="">
                <div className="d-flex flex-column align-items-center justify-content-center py-5 ">
                  <LoadingView />
                </div>
              </td>
            </tr>
          ) : data?.length > 0 ? (
            data?.map((row) => {
              const status = statusMap[row.status];
              const expanded = expandedRow === row.ids[0];

              return (
                <React.Fragment key={row.ids}>
                  <tr
                    onClick={() => handleRowClick(row.ids[0])}
                    className={`cursor-pointer align-middle table-row ${
                      expanded ? "table-blue expanded" : ""
                    }`}
                  >
                    {columns(t, i18n.language)[type].map((column) => (
                      <td
                        className={`py-3 px-4 f14 ${
                          expanded ? "text-white" : "text-brightGrey"
                        }`}
                        key={column.name}
                      >
                        {column.render(row, status)}
                      </td>
                    ))}
                    <td className="py-3 px-4 f14">
                      {["reserved"]?.includes(row?.status) && (
                        <VerticalPoints
                          showLeft
                          options={[
                            {
                              text: t("Cancel Reservation"),
                              callback: () =>
                                dispatch(
                                  openCenteredModal(
                                    <ConfirmModal
                                      label={t("Product")}
                                      id={row?.ids[0]}
                                      text="Are you sure you want to cancel reservation for"
                                      confirmText="Confirmer"
                                      onConfirm={() =>
                                        dispatch(cancelReservation(row?.ids[0]))
                                      }
                                    />
                                  )
                                ),
                            },
                          ]}
                          color={expanded ? "white" : ""}
                          className="ms-auto"
                        />
                      )}
                      {["in-stock", "in-supply"]?.includes(row?.status) && (
                        <VerticalPoints
                          showLeft
                          options={[
                            {
                              text: t("Reserve"),
                              callback: () =>
                                dispatch(
                                  openCenteredModal(
                                    <ReserveStock
                                      action={(formData) => {
                                        dispatch(
                                          updateStockStatus(
                                            row.name,
                                            row.ids[0],
                                            {
                                              status: "reserved",
                                            },
                                            filters,
                                            `${row?.name ?? t("Product")} ${t(
                                              "is reserved"
                                            )}`,
                                            formData
                                          )
                                        );
                                      }}
                                    />
                                  )
                                ),
                            },
                          ]}
                          color={expanded ? "white" : ""}
                          className="ms-auto"
                        />
                      )}
                    </td>
                  </tr>
                  {expanded && renderRowDetails(row)}
                </React.Fragment>
              );
            })
          ) : (
            loading !== undefined && (
              <tr>
                <td colSpan={tableHeaders.length} className="">
                  <div className="d-flex flex-column align-items-center justify-content-center py-5 ">
                    <i
                      className="bi bi-search text-secondary mb-3"
                      style={{ fontSize: "3rem" }}
                    ></i>
                    <h5 className="text-secondary">{t("No result found")}</h5>
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdvancedStockTable;
