import React from "react";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import Evenement from "../../Calendar/Modals/Evenement/Evenement";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { useTranslation } from "react-i18next";
import { eventTypeFr } from "../../../Utils/fileUtils";

const EventAndNewCard = ({ Image, title, id, eventType, creator }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(
      openRightModal(
        <Evenement
          id={id}
          modalTitle={t(`${eventTypeFr[eventType] ?? ""}`)}
          creator={creator}
        />,
      ),
    );
  };

  /* ******************************** Rendering ******************************* */
  return (
    <div className="colAlignC align-items-start col-2 p-3 gap-3 minW250">
      <div style={{ height: "150px", width: "100%" }}>
        {Image?.data?.attributes?.url && (
          <img
            src={IMAGE_END_POINT + Image?.data?.attributes?.url}
            alt={title}
            className="w-100 br8 ob-cover h100"
          />
        )}
      </div>

      <span className="text-customBlack f18 semi-bold">{title}</span>
      <Button variant="outline" onClick={handleClick}>
        {t("View More")}
      </Button>
    </div>
  );
};

export default EventAndNewCard;
