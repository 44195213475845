import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import CalendarEvent from "../../../../Common/Calendar/CalendarEvent/CalendarEvent";
import Evenement from "../../../Modals/Evenement/Evenement";
import { openRightModal } from "../../../../../REDUX/actions/rightModal";

import "./CalendarSlot.css";
import { eventTypeFr, formatTimeToHHMM } from "../../../../../Utils/fileUtils";
import { useTranslation } from "react-i18next";

function CalendarSlot({
  date,
  day,
  index,
  indexOfOne,
  events = [],
  selectedOption,
}) {
  const { t } = useTranslation("common");
  const lastInLineClassName = (index + 1) % 7 === 0 ? "lastInLine" : "";
  const dispatch = useDispatch();

  const numberColor =
    index < indexOfOne || (index > 27 && day < 10)
      ? "text-softGrey"
      : "text-fadedGrey";

  const isCurrentDate =
    moment().format("YYYY/M/D") === date ? "isCurrentDate" : "";

  const handleEventClick = (type, eventId, creator) => {
    dispatch(
      openRightModal(
        <Evenement modalTitle={type} id={eventId} creator={creator} />,
      ),
    );
  };

  return (
    <div
      className={`calendarSlot pt-1 pb-3 d-flex flex-column ${lastInLineClassName}`}
    >
      <p
        className={`slotDate mb-1 text-center f14 sBold br50 align-self-end ${numberColor} ${isCurrentDate}`}
      >
        {day}
      </p>
      {events
        ?.filter((event) => {
          return (
            selectedOption?.code === null ||
            selectedOption?.code === event?.attributes?.eventType
          );
        })
        .map((event) => {
          const startDate = moment(event?.attributes?.startDate);
          const endDate = moment(event?.attributes?.endDate);
          const today = moment(date);

          if (startDate.format("YYYY/M/D") === date) {
            const { id, attributes } = event;
            const startTime =
              attributes?.startTime && formatTimeToHHMM(attributes?.startTime);
            const endTime =
              attributes?.endTime && formatTimeToHHMM(attributes?.endTime);
            return (
              <CalendarEvent
                key={id}
                id={id}
                title={
                  attributes?.title ??
                  t(`${eventTypeFr[attributes?.eventType] ?? ""}`)
                }
                type={attributes?.eventType}
                startTime={startTime}
                endTime={endTime}
                isStartDate
                multipleDays={endDate.diff(startDate, "days") > 0}
                callback={() =>
                  handleEventClick(
                    t(`${eventTypeFr[attributes?.eventType] ?? ""}`),
                    id,
                    attributes?.creator,
                  )
                }
              />
            );
          }
          if (endDate.format("YYYY/M/D") === date) {
            const { id, attributes } = event;
            const startTime =
              attributes?.startTime && formatTimeToHHMM(attributes?.startTime);
            const endTime =
              attributes?.endTime && formatTimeToHHMM(attributes?.endTime);
            return (
              <CalendarEvent
                key={id}
                id={id}
                title={
                  attributes?.title ??
                  t(`${eventTypeFr[attributes?.eventType] ?? ""}`)
                }
                type={attributes?.eventType}
                startTime={startTime}
                endTime={endTime}
                isEndDate
                callback={() =>
                  handleEventClick(
                    t(`${eventTypeFr[attributes?.eventType] ?? ""}`),
                    id,
                    attributes?.creator,
                  )
                }
              />
            );
          }
          if (today.isBetween(startDate, endDate)) {
            const { id, attributes } = event;
            const startTime =
              attributes?.startTime && formatTimeToHHMM(attributes?.startTime);
            const endTime =
              attributes?.endTime && formatTimeToHHMM(attributes?.endTime);
            return (
              <CalendarEvent
                key={id}
                id={id}
                title={
                  attributes?.title ??
                  t(`${eventTypeFr[attributes?.eventType] ?? ""}`)
                }
                type={attributes?.eventType}
                startTime={startTime}
                endTime={endTime}
                isBetween
                callback={() =>
                  handleEventClick(
                    t(`${eventTypeFr[attributes?.eventType] ?? ""}`),
                    id,
                    attributes?.creator,
                  )
                }
              />
            );
          }
          return null;
        })}
    </div>
  );
}

export default CalendarSlot;
