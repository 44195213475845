import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RevendeurStats from "./RevendeurStats";
import RevendeurEvents from "./RevendeurEvents";
import RevendeurFiles from "./RevendeurFiles";
import RevendeurGallery from "./RevendeurGallery";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import RevendeurInstallations from "./RevendeurInstallations";
import RevendeurList from "./RevendeurList";
import RevendeurFilters from "./RevendeurFilters";
import AnnualRevendeurList from "./AnnualRevendeurList";
import AnnualTurnover from "./AnnualTurnover";
import { useTranslation } from "react-i18next";
import { isDefined } from "../../Utils";

const RevendeurViewContent = () => {
  const { t } = useTranslation("common");
  const [reseller, setReseller] = useState({});
  const [filters, setFilters] = useState({});
  const { user } = useSelector((state) => state.userReducer);
  const [userRole, setUserRole] = useState(user?.attributes?.userRole);
  const [id, setId] = useState(
    user?.attributes?.profil?.data?.attributes?.reseller?.data?.id
  );

  const isManager = userRole === 2;
  const isAdmin = userRole === 1;

  const addMediaToGallery = (list) => {
    setReseller((prev) => ({
      ...prev,
      attributes: {
        ...prev?.attributes,
        gallery: { data: [...list, ...prev?.attributes?.gallery?.data] },
      },
    }));
  };

  const addInstallation = (installation) => {
    setReseller((prev) => ({
      ...prev,
      attributes: {
        ...prev?.attributes,
        installations: {
          data: [installation, ...prev?.attributes?.installations?.data],
        },
      },
    }));
  };

  useEffect(() => {
    // TEMP
    if (userRole !== 0) return;
    (async function () {
      const {
        data: { data },
      } = await API.get(`${API_END_POINT}api/organizations/${id}`, {
        params: {
          populate: {
            gallery: {
              fields: ["id", "name", "mime", "url"],
              sort: ["createdAt:desc"],
            },
            installations: {
              fields: ["id", "refClient"],
              populate: {
                media: { fields: ["id", "name", "mime", "url"] },
              },
              sort: ["createdAt:desc"],
            },
            contract: {
              fields: ["id"],
              populate: {
                file: { fields: ["name", "url"] },
                warranty: { fields: ["name", "url"] },
                area: { fields: ["name", "url"] },
                products: { fields: ["id", "name", "name_en"] },
                pvls: { fields: ["id", "name", "name_en"] },
              },
            },
          },
        },
      });
      setReseller(data);
    })();
  }, [id, userRole]);

  useEffect(() => {
    if (isDefined(filters?.reseller)) {
      setId(filters.reseller.code);
      setUserRole(0);
    } else if (isDefined(filters?.business_affiliate)) {
      setId(filters?.business_affiliate?.code);
      setUserRole(2);
    } else {
      setUserRole(user?.attributes?.userRole);
      setId(user?.attributes?.profil?.data?.attributes?.reseller?.data?.id);
    }
  }, [
    filters,
    user?.attributes?.profil?.data?.attributes?.reseller?.data?.id,
    user?.attributes?.userRole,
  ]);

  /* ******************************** Rendering ******************************* */
  return (
    <div className="flex-1">
      <div className="">
        <h2 className="text-blue bold f30 mb-1">
          {isAdmin || isManager || user?.attributes?.userRole !== 0
            ? t("Stores")
            : t("My Store")}
          {filters.reseller && ` - ${filters?.reseller?.name}`}
        </h2>
        {[1, 2]?.includes(user?.attributes?.userRole) && (
          <RevendeurFilters filters={filters} setFilters={setFilters} />
        )}
        <RevendeurStats filters={filters} userRole={userRole} />
        {[1, 2]?.includes(userRole) && (
          <>
            {userRole === 1 && <AnnualTurnover filters={filters} />}
            <AnnualRevendeurList filters={filters} userRole={userRole} />
          </>
        )}
        <RevendeurEvents filters={filters} />
      </div>
      {userRole === 0 && (
        <>
          <RevendeurFiles contract={reseller?.attributes?.contract} />
          <RevendeurGallery
            gallery={reseller?.attributes?.gallery}
            resellerId={reseller?.id}
            addMediaToGallery={addMediaToGallery}
          />
          <RevendeurInstallations
            resellerName={reseller?.attributes?.name}
            installations={reseller?.attributes?.installations}
            resellerId={reseller?.id}
            addInstallation={addInstallation}
          />
        </>
      )}
      {[1, 2]?.includes(userRole) && <RevendeurList filters={filters} />}
    </div>
  );
};

export default RevendeurViewContent;
