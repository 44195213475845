import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";
import { toast } from "react-toastify";

export const getStock =
  ({
    page = 1,
    search,
    gamme,
    status,
    model,
    approvisionnement,
    entrepot,
    type,
  }) =>
  async (dispatch, getState) => {
    try {
      const { nextOffset } = getState().advancedStockReducer;
      if (page > 1) {
        dispatch({ type: "FETCH_STOCK_LAZY_LOADING" });
      } else {
        dispatch({ type: "FETCH_STOCK_LOADING" });
      }
      const { data } = await API.get(`${API_END_POINT}api/stock`, {
        params: {
          page,
          search,
          gamme: gamme?.code,
          model: model?.code,
          approvisionnement: approvisionnement?.code,
          status: status?.code,
          entrepot: entrepot?.code,
          type: type?.code,
          nextOffset,
        },
      });

      if (page > 1) {
        dispatch({
          type: "FETCH_STOCK_LAZY_SUCCESS",
          payload: data ?? [],
        });
      } else {
        dispatch({ type: "FETCH_STOCK_SUCCESS", payload: data ?? [] });
      }
    } catch (error) {
      dispatch({ type: "FETCH_STOCK_ERROR", payload: error });
    }
  };

export const updateStockStatus =
  (name, id, payload, filters, message, data) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_STOCK_LOADING" });

      if (payload?.status === "reserved") {
        await API.post(`${API_END_POINT}api/reservations`, {
          data: {
            packing: id,
            ...data,
          },
        });
      }

      const response = await API.put(
        `${API_END_POINT}api/packings/${id}?populate[reservation][populate][0]=reseller`,
        {
          data: {
            ...payload,
          },
        }
      );

      const reservation = {
        resellerName:
          response?.data?.data?.attributes?.reservation?.data?.attributes
            ?.reseller?.data?.attributes?.name,
        note: response?.data?.data?.attributes?.reservation?.data?.attributes
          ?.note,
      };

      // dispatch(getStock(filters, false));

      dispatch({
        type: "UPDATE_STOCK_SUCCESS",
        payload: { id, name, ...payload, reservation, filters },
      });
      toast.success(message);
    } catch (error) {
      dispatch({ type: "UPDATE_STOCK_ERROR", payload: error });
    }
  };

export const cancelReservation = (id) => async (dispatch) => {
  try {
    dispatch({ type: "CANCEL_RESERVATION_STOCK_LOADING" });

    const { data } = await API.put(
      `${API_END_POINT}api/cancel-reservation/${id}`
    );

    dispatch({
      type: "CANCEL_RESERVATION_STOCK_SUCCESS",
      payload: { ...data, id },
    });
  } catch (error) {
    dispatch({ type: "CANCEL_RESERVATION_STOCK_ERROR", payload: error });
  }
};
