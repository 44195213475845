import React, { useState } from "react";
import AnnualDataTable from "./AnnualDataTable";
import API from "../../Api/Api";
import { formatCurrency } from "../../Utils";
// import { useSelector } from "react-redux";

const AnnualRevendeurList = ({ filters, userRole }) => {
  const pageSize = 10;
  // const { user } = useSelector((state) => state.userReducer);

  // const userRole = user?.attributes?.userRole;
  const isAdmin = userRole === 1;

  const [resellers, setResellers] = useState([]);
  const [pageCount, setPageCount] = useState(null);

  const years = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
  ];

  const fetchData = async (page = 1, year = years[0], search) => {
    try {
      const params = {
        year,
        search,
        page,
        pageSize,
      };

      Object.keys(filters)?.forEach((key) => {
        if (filters[key]) {
          params[key] = filters[key]?.code;
        }
      });

      const { data } = await API.get(`api/annual-organizations`, {
        params,
      });

      if (page === 1) {
        setResellers(data?.data);
      } else {
        setResellers((prev) => [...prev, ...data?.data]);
      }
      setPageCount(data?.meta?.pagination?.pageCount);
    } catch (error) {
      console.log(error);
    }
  };

  const headers = [
    "N°",
    "Stores",
    "Note",
    "SEO Local",
    "Nber Events",
    "Budget Ads",
    "Nber Spas",
    "Nber Swim Spa",
    "CA",
    isAdmin ? "Margin Rate" : "Commission",
  ];

  const columns = [
    {
      name: "Index",
      render: (row) => row?.index + 1,
    },
    {
      name: "Store",
      render: (row) => row?.name ?? "-",
    },
    {
      name: "Note",
      render: (row) =>
        !isNaN(row?.note?.globalNote) && row?.note?.globalNote !== null
          ? `${row?.note?.globalNote.toString().padStart(2, "0")}/20`
          : "-",
    },
    {
      name: "scale",
      render: (row) => (!!row?.assistance?.scale ? "Yes" : "No"),
    },
    {
      name: "Events",
      render: (row) => row?.eventCount ?? 0,
    },
    {
      name: "Budget Ads",
      render: (row) => formatCurrency(row?.budgetAds) ?? "-",
    },
    {
      name: "spa_sold",
      render: (row) => row?.spaCount ?? 0,
    },
    {
      name: "swim_spa_sold",
      render: (row) => row?.swimSpaCount ?? 0,
    },
    {
      name: "ca",
      render: (row) => formatCurrency(row.ca),
    },
    isAdmin
      ? {
          name: "txMarge",
          render: (row) =>
            row?.txMarge > 0 ? row.txMarge.toFixed(2) + "%" : "0%",
        }
      : {
          name: "commission",
          render: (row) => formatCurrency(row.comission),
        },

    // {
    //   name: "commission",
    //   render: (row) => formatCurrency(row.comission),
    // },
  ];
  if (isAdmin) {
    headers.push("Manager");
    columns.push({
      name: "Manager",
      render: (row) => {
        const firstName = row?.business_affiliate?.user?.firstName ?? "";
        const lastName = row?.business_affiliate?.user?.firstName ?? "";
        const fullName = [firstName, lastName].join(" ")?.trim();
        return fullName?.length > 0 ? fullName : "-";
      },
    });
  }

  return (
    <AnnualDataTable
      headers={headers}
      columns={columns}
      fetchData={fetchData}
      data={resellers}
      totalPages={pageCount}
      title="Stores"
      years={years}
      noResultsMessage="No results found"
      filters={filters}
    />
  );
};

export default AnnualRevendeurList;
