import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TabContent from "./TabContent";
import Button from "../Common/FormElements/Button/Button";
import TabsNavbar from "../Common/TabsNavBar/TabsNavbar";
import { changePassword, updateProfile } from "../../REDUX/actions/user";
import { closeRightModal } from "../../REDUX/actions/rightModal";

import "./Profile.css";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState("General information");
  const { user, userUpdateLoad, changePasswordLoading } = useSelector(
    (state) => state.userReducer
  );
  const [currPassword, setCurrPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newConfirmPassword, setNewConfirmPassword] = useState();
  const [matchPassword, setMatchPassword] = useState();
  const [identicPassword, setIdenticPassword] = useState();

  const [newUser, setNewUser] = useState({
    email: user?.attributes?.email,
    firstName: user?.attributes?.firstName,
    lastName: user?.attributes?.lastName,
    occupation: user?.attributes?.occupation,
    birthdate: user?.attributes?.birthdate || moment().format("YYYY-MM-DD"),
    phoneNumber: user?.attributes?.phoneNumber,
    photo: user?.attributes?.photo?.data?.attributes?.url,
    newPassword: "",
  });
  const [invalidFields, setInvalidFields] = useState({});
  const dispatch = useDispatch();

  const handleChangeUpdateUser = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePassword = () => {
    setInvalidFields((prevInvalidFields) => {
      const { password, ...rest } = prevInvalidFields;
      return rest;
    });

    if (newPassword !== newConfirmPassword) {
      setMatchPassword(false);
    } else {
      setMatchPassword(true);
      if (currPassword === newConfirmPassword && currPassword === newPassword) {
        setIdenticPassword(true);
      } else {
        setIdenticPassword(false);
        dispatch(
          changePassword(currPassword, newPassword, newConfirmPassword, t)
        );
      }
    }
  };

  const validateFields = () => {
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      newUser.email
    );
    const isValidPhoneNumber = /^(\+|\d{1,4})\s?(\d\s?){5,}$/.test(
      newUser.phoneNumber
    );
    let errors = {
      ...(isValidEmail ? {} : { email: "emailError" }),
      ...(isValidPhoneNumber ? {} : { phoneNumber: "phoneNumberError" }),
    };

    return errors;
  };
  const handleSave = () => {
    const validationErrors = validateFields();

    // proceed with saving erros
    // Proceed with saving
    if (activeTab === "General information") {
      if (Object.keys(validationErrors)?.length === 0) {
        dispatch(updateProfile(user?.id, newUser, t));
      }
    } else if (activeTab === "Password") {
      if (newPassword?.length < 6) {
        setInvalidFields({
          password: "passwordError",
        });
        return;
      }
      handleChangePassword();
    } else {
      setInvalidFields(validationErrors);
    }
  };
  /* ******************************** rendering ******************************* */
  return (
    <div className="profileModal d-flex flex-column justify-content-between h100 ">
      <div>
        <h1 className="quickSand f25 sBold text-blue mb-5 px-5">
          {t("My profile")}
        </h1>
        <TabsNavbar
          tabs={["General information", "Password"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
        />
        <TabContent
          activeTab={activeTab}
          newUser={newUser}
          setNewUser={setNewUser}
          handleChangeUpdateUser={handleChangeUpdateUser}
          currPassword={currPassword}
          newPassword={newPassword}
          newConfirmPassword={newConfirmPassword}
          setCurrPassword={setCurrPassword}
          setNewPassword={setNewPassword}
          setNewConfirmPassword={setNewConfirmPassword}
          matchPassword={matchPassword}
          identicPassword={identicPassword}
          invalidFields={invalidFields}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Button
          variant="outline"
          className="me-3"
          onClick={() => dispatch(closeRightModal())}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSave}
          loading={
            activeTab === "General information"
              ? userUpdateLoad
              : activeTab === "Password"
              ? changePasswordLoading
              : false
          }
          disabled={
            activeTab === "Password" &&
            !currPassword &&
            !newPassword &&
            !newConfirmPassword
          }
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
}

export default Profile;
