import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import "./Calendar.css";
import { getEvents } from "../../../REDUX/actions/events";
import DateTemplate from "./CustomDateTemplate";
import { formatDate } from "../../../Utils/fileUtils";
import { useTranslation } from "react-i18next";

const CustomCalendar = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [dates, setDates] = useState([]);
  const { events } = useSelector((state) => state.eventsReducer);
  const { orders } = useSelector((state) => state.ordersReducer);
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch, user?.id]);

  const eventBgStyles = {
    expo_fair: "bg-softPink",
    open_house: "bg-iceberg",
    google_ads: "bg-treebrown",
    meta_ads: "bg-papaya",
    flyer_campaign: "bg-snowGreen",
    radio_campaign: "bg-pink",
    city_poster: "bg-blueSky",
  };

  const formatEventsAndOrders = (events) => {
    const formattedDates = [];
    events?.data?.forEach((event) => {
      if (!event?.attributes?.startDate) {
        return null;
      }
      const formattedDate = formatDate(event?.attributes?.startDate);
      formattedDates.push({
        id: event?.id,
        startDate: formattedDate,
        eventType: event?.attributes?.eventType,
        key: "event",
        backgroundColor: eventBgStyles[event?.attributes?.eventType],
      });
    });

    return formattedDates;
  };

  if (!currentLanguage) return null;

  /* ******************************** Rendering ******************************* */
  return (
    <div className="card flex justify-content-center calendarContainer">
      <Calendar
        value={dates}
        onChange={(e) => setDates(e.value)}
        inline
        className="calendar"
        selectionMode="multiple"
        locale={currentLanguage?.includes("en") ? "en" : "fr"}
        dateFormat="dd/mm/yy"
        dateTemplate={(date) => (
          <DateTemplate
            date={date}
            eventsDates={formatEventsAndOrders(events, orders)}
          />
        )}
      />
    </div>
  );
};

export default CustomCalendar;
