import React, { useState } from "react";
import Button from "../Common/FormElements/Button/Button";
import { useTranslation } from "react-i18next";
import UploadButton from "../Common/FormElements/UploadButton/UploadButton";
import { CloseSvg } from "../Svgs";
import API from "../../Api/Api";
import { closeCenteredModal } from "../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";

const RevendeurAddMedia = ({
  resellerId,
  currentIds = [],
  addMediaToGallery,
}) => {
  const dispatch = useDispatch();
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    if (files?.length > 0) {
      setMedia((prev) => [...prev, ...files]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    media.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await API.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          fields: ["id", "name", "mime", "url"],
          sort: ["createdAt:desc"],
        },
      });
      const ids = response?.data?.map((file) => file.id);
      await API.put(`/api/organizations/${resellerId}`, {
        data: {
          gallery: [...currentIds, ...ids],
        },
      });
      addMediaToGallery(
        response?.data.map((file) => {
          const { id, ...rest } = file;
          return { id, attributes: rest };
        })
      );
      dispatch(closeCenteredModal());
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <p className="text-blue bold f22 quickSand">{t("Add photo/video")}</p>
      <div className="row g-3">
        {media?.map((file, index) => (
          <div className="col-3">
            <div className="bg-darkGrey w-100 h-100 alignC br10 bg-opacity-50 position-relative">
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="media"
                  className="w-100 h-100 ob-contain max-h-200px"
                />
              ) : file.type.startsWith("video/") ? (
                <video
                  src={URL.createObjectURL(file)}
                  controls
                  className="w-100 h-100 ob-contain max-h-200px"
                />
              ) : null}

              <button
                className="message-media-close position-absolute border-0 d-flex align-items-center justify-content-center bg-white p-2"
                onClick={() => setMedia(media?.filter((_, i) => i !== index))}
              >
                <CloseSvg />
              </button>
            </div>
          </div>
        ))}
        <div className={`${media?.length > 0 ? "col-3" : "col-12"}`}>
          <UploadButton
            multiple
            id="media"
            name="media"
            type="file"
            text={t("Add photo/video (max. 15 MB)")}
            callback={handleChange}
            className="h-100 flex-grow-1"
          />
        </div>
      </div>
      <div className="mt-4 alignC">
        <Button
          loading={loading}
          disabled={!media?.length > 0}
          onClick={handleSubmit}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default RevendeurAddMedia;
