import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Common/FormElements/Button/Button";
import MediaInstallation from "./MediaInstallation";
import { useDispatch } from "react-redux";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import RevendeurAddInstallation from "./RevendeurAddInstallation";

const RevendeurInstallations = ({
  resellerName,
  installations,
  resellerId,
  addInstallation,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1);

  const handleClick = () => {
    dispatch(
      openCenteredModal(
        <RevendeurAddInstallation
          resellerId={resellerId}
          currentIds={installations?.data?.map(
            (installation) => installation?.id
          )}
          addInstallation={addInstallation}
        />
      )
    );
  };

  return (
    <div className="py-4">
      <div className="d-flex alignH justify-content-between mb-3">
        <span className="text-blue bold f22 quickSand">
          {t("Installations")} {resellerName ?? ""}
        </span>
        <Button onClick={handleClick}>{t("Add an installation")}</Button>
      </div>

      <div className="row gx-4">
        {installations?.data?.slice(0, page * 3).map((installation) => (
          <MediaInstallation installation={installation} />
        ))}
      </div>

      {installations?.data?.length >= page * 3 && (
        <div className="w-100 text-center mt-4">
          <button
            className="text-brightGrey underline bg-transparent border-0 sBold outline-none"
            onClick={() => setPage((prev) => prev + 1)}
          >
            {t("See more")}
          </button>
        </div>
      )}
    </div>
  );
};

export default RevendeurInstallations;
