import React from "react";
import adjustPrice from "../../Utils/AdjustPrice";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updatePacking } from "../../REDUX/actions/catalogue";
import { useTranslation } from "react-i18next";

const tabsMap = {
  Products: "Produits",
  Accessories: "Accessoires",
  "Marketing Products": "Produits marketing",
  "Spare Part": "Pièce Détachée",
  Services: "Services",
  "Other branded products": "Produits d'autres marques",
  SPA: "SPA",
  "SWIM SPA": "SPA DE NAGE",
  SAUNA: "SAUNA",
};

const CartPackingItem = ({
  packingGroup,
  activeTab,
  searchInput,
  productsactiveTab,
  showFields = false,
  hideActions,
}) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);
  const { t } = useTranslation("common");

  return packingGroup.productType === 1 ? (
    <div>
      {packingGroup.packings.map((packing) => {
        return (
          <div
            className="border text-start br10 p-2  my-1 d-flex justify-content-between w100 pb-3"
            key={packing.id}
          >
            <div>
              <div className="f12 bold">
                {t("SN")} : {packing.serialNumber}
              </div>
              <div className="f12 bold">
                {t("Tank")} : {t(`${packing.cuve}`)}
              </div>
              <div className="f12 bold">
                {t("Panel")} : {t(`${packing.panel}`)}
              </div>
              <div className="f14 bold ps">
                {t("Price")}{" "}
                {cart.particulier ? t("Incl. Tax") : t("Excl. Tax")}:{" "}
                {cart.particulier
                  ? adjustPrice(
                      (packing?.soldPrice !== undefined &&
                      packing?.soldPrice !== null
                        ? packing?.soldPrice
                        : packing?.model?.publicPriceTTC
                      )
                        ?.toFixed(2)
                        ?.toString()
                    )
                  : adjustPrice(
                      (packing?.soldPrice !== undefined &&
                      packing?.soldPrice !== null
                        ? cart?.majorAccountOrder &&
                          cart?.currency === "Dollar" &&
                          cart?.exchangeRate
                          ? packing?.soldPrice / cart?.exchangeRate
                          : packing?.soldPrice
                        : cart?.majorAccountOrder &&
                          cart?.currency === "Dollar" &&
                          cart?.exchangeRate
                        ? packing?.model?.retailerPriceHT / cart?.exchangeRate
                        : packing?.model?.retailerPriceHT
                      )
                        ?.toFixed(2)
                        ?.toString()
                    )}
                {cart?.majorAccountOrder && cart?.currency === "Dollar"
                  ? " $"
                  : " €"}
              </div>
              {showFields ? (
                <>
                  {userRole === "admin" && (
                    <div className="my-1 alignH gap-2">
                      <label htmlFor="remise">
                        {t("Discount")} ({" "}
                        {cart?.majorAccountOrder && cart?.currency === "Dollar"
                          ? " $"
                          : " €"}
                        ) :{" "}
                      </label>{" "}
                      {!hideActions ? (
                        <input
                          id="remise"
                          placeholder={t("Discount")}
                          className="refClientItem p-1"
                          type="number"
                          defaultValue={
                            parseFloat(
                              cart?.majorAccountOrder &&
                                cart?.currency === "Dollar" &&
                                cart?.exchangeRate
                                ? packing.discount / cart?.exchangeRate
                                : packing.discount
                            ) || 0
                          }
                          onChange={(e) =>
                            dispatch(
                              updatePacking(
                                packingGroup.packings?.map(
                                  (packing) => packing?.id
                                ),
                                {
                                  discount:
                                    parseFloat(
                                      cart?.majorAccountOrder &&
                                        cart?.currency === "Dollar" &&
                                        cart?.exchangeRate
                                        ? e.target.value * cart?.exchangeRate
                                        : e.target.value
                                    ) || 0,
                                },
                                {
                                  activeTab: tabsMap[activeTab],
                                  searchInput,
                                  productsactiveTab: tabsMap[productsactiveTab],
                                }
                              )
                            )
                          }
                        />
                      ) : (
                        <div>{parseFloat(packing.discount) || 0}€</div>
                      )}
                    </div>
                  )}
                  <div className="alignH gap-2">
                    <label htmlFor="elRefClient">
                      {t("Client reference")} :
                    </label>{" "}
                    {!hideActions ? (
                      <input
                        id="elRefClient"
                        placeholder={t("Client reference")}
                        className="refClientItem p-1"
                        defaultValue={packing.refClient}
                        onChange={(e) =>
                          dispatch(
                            updatePacking(
                              packingGroup.packings?.map(
                                (packing) => packing?.id
                              ),
                              { refClient: e.target.value },
                              {
                                activeTab: tabsMap[activeTab],
                                searchInput,
                                productsactiveTab: tabsMap[productsactiveTab],
                              }
                            )
                          )
                        }
                      />
                    ) : (
                      <div> {packing.refClient || " -"} </div>
                    )}
                  </div>
                </>
              ) : null}
            </div>
            {!hideActions && (
              <div className="alignH">
                <i
                  className="bi bi-trash3 text-lameRed counterIcon me-3 pointer"
                  onClick={() =>
                    dispatch(
                      removeFromCart(packing.id, {
                        activeTab: tabsMap[activeTab],
                        searchInput,
                        productsactiveTab: tabsMap[productsactiveTab],
                      })
                    )
                  }
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <div className="border br10 p-2  my-1 alignH justify-content-between w100 pb-3">
      {cart.particulier ? (
        <div>
          {t("Quantity")} : {packingGroup.packings.length}
          <div className="f12 bold ps">
            {t("Unit Price incl. tax")} :{" "}
            {adjustPrice(
              (packingGroup?.packings[0]?.soldPrice !== undefined &&
              packingGroup?.packings[0]?.soldPrice !== null
                ? packingGroup?.packings[0]?.soldPrice
                : packingGroup?.packings[0]?.model?.productType === 5 ||
                  packingGroup?.packings[0]?.model?.productType === 6
                ? packingGroup?.packings[0]?.publicPriceTTC
                : packingGroup?.packings[0]?.model?.publicPriceTTC
              )
                ?.toFixed(2)
                ?.toString()
            )}
            €
          </div>
          <div className="f12 bold ps">
            {t("Total Price incl. tax")} :{" "}
            {adjustPrice(
              (
                (packingGroup?.packings[0]?.soldPrice !== undefined &&
                packingGroup?.packings[0]?.soldPrice !== null
                  ? packingGroup?.packings[0]?.soldPrice
                  : packingGroup?.packings[0]?.model?.productType === 5 ||
                    packingGroup?.packings[0]?.model?.productType === 6
                  ? packingGroup?.packings[0]?.publicPriceTTC
                  : packingGroup?.packings[0]?.model?.publicPriceTTC) *
                packingGroup?.packings?.length
              )
                ?.toFixed(2)
                ?.toString()
            )}
            €
          </div>
          <>
            {userRole === "admin" && showFields && (
              <div className="my-1 alignH gap-2">
                <label htmlFor="remise">{t("Discount")} (€) : </label>{" "}
                {!hideActions ? (
                  <input
                    id="remise"
                    placeholder={t("Discount")}
                    className="refClientItem p-1"
                    type="number"
                    defaultValue={
                      parseFloat(
                        packingGroup?.packings[0]?.discount /
                          (cart?.exchangeRate || 1)
                      ) || 0
                    }
                    onChange={(e) =>
                      dispatch(
                        updatePacking(
                          packingGroup.packings?.map((packing) => packing?.id),
                          {
                            discount:
                              parseFloat(
                                e.target.value * (cart?.exchangeRate || 1)
                              ) || 0,
                          },
                          {
                            activeTab: tabsMap[activeTab],
                            searchInput,
                            productsactiveTab: tabsMap[productsactiveTab],
                          }
                        )
                      )
                    }
                  />
                ) : (
                  <div>
                    {parseFloat(
                      packingGroup?.packings[0]?.discount /
                        (cart?.exchangeRate || 1)
                    ) || 0}
                    €
                  </div>
                )}
              </div>
            )}
          </>
        </div>
      ) : (
        <div>
          {t("Quantity")} : {packingGroup?.packings?.length}
          <div className="f12 bold ps">
            {t("Unit Price excl. tax")} :{" "}
            {adjustPrice(
              (packingGroup?.packings[0]?.soldPrice !== undefined &&
              packingGroup?.packings[0]?.soldPrice !== null
                ? cart?.majorAccountOrder &&
                  cart?.currency === "Dollar" &&
                  cart?.exchangeRate
                  ? packingGroup?.packings[0]?.soldPrice / cart?.exchangeRate
                  : packingGroup?.packings[0]?.soldPrice
                : cart?.majorAccountOrder &&
                  cart?.currency === "Dollar" &&
                  cart?.exchangeRate
                ? packingGroup?.packings[0]?.model?.productType === 5 ||
                  packingGroup?.packings[0]?.model?.productType === 6
                  ? packingGroup?.packings[0]?.retailerPriceHT /
                    cart?.exchangeRate
                  : packingGroup?.packings[0]?.model?.retailerPriceHT /
                    cart?.exchangeRate
                : packingGroup?.packings[0]?.model?.productType === 5 ||
                  packingGroup?.packings[0]?.model?.productType === 6
                ? packingGroup?.packings[0]?.retailerPriceHT
                : packingGroup?.packings[0]?.model?.retailerPriceHT
              )
                ?.toFixed(2)
                ?.toString()
            )}
            {cart?.majorAccountOrder && cart?.currency === "Dollar"
              ? " $"
              : " €"}
          </div>
          <div className="f12 bold ps">
            {t("Total Price excl. tax")} :{" "}
            {adjustPrice(
              (
                (packingGroup?.packings[0]?.soldPrice !== undefined &&
                packingGroup?.packings[0]?.soldPrice !== null
                  ? cart?.majorAccountOrder &&
                    cart?.currency === "Dollar" &&
                    cart?.exchangeRate
                    ? packingGroup?.packings[0]?.soldPrice / cart?.exchangeRate
                    : packingGroup?.packings[0]?.soldPrice
                  : cart?.majorAccountOrder &&
                    cart?.currency === "Dollar" &&
                    cart?.exchangeRate
                  ? (packingGroup?.packings[0]?.model?.productType === 5 ||
                    packingGroup?.packings[0]?.model?.productType === 6
                      ? packingGroup?.packings[0]?.retailerPriceHT
                      : packingGroup?.packings[0]?.model?.retailerPriceHT) /
                    cart?.exchangeRate
                  : packingGroup?.packings[0]?.model?.productType === 5 ||
                    packingGroup?.packings[0]?.model?.productType === 6
                  ? packingGroup?.packings[0]?.retailerPriceHT
                  : packingGroup?.packings[0]?.model?.retailerPriceHT) *
                packingGroup?.packings?.length
              )
                ?.toFixed(2)
                ?.toString()
            )}
            {cart?.majorAccountOrder && cart?.currency === "Dollar"
              ? " $"
              : " €"}
          </div>
          {showFields ? (
            <>
              {userRole === "admin" && (
                <div className="my-1 alignH gap-2">
                  <label htmlFor="remise">
                    {t("Discount")} ({" "}
                    {cart?.majorAccountOrder && cart?.currency === "Dollar"
                      ? " $"
                      : " €"}
                    ) :{" "}
                  </label>{" "}
                  {!hideActions ? (
                    <input
                      id="remise"
                      placeholder={t("Discount")}
                      className="refClientItem p-1"
                      type="number"
                      defaultValue={
                        parseFloat(
                          packingGroup.packings[0].discount /
                            (cart?.exchangeRate || 1)
                        ) || 0
                      }
                      onChange={(e) =>
                        dispatch(
                          updatePacking(
                            packingGroup.packings?.map(
                              (packing) => packing?.id
                            ),
                            {
                              discount:
                                parseFloat(
                                  e.target.value * (cart?.exchangeRate || 1)
                                ) / packingGroup?.numberOfProducts || 0,
                            },
                            {
                              activeTab: tabsMap[activeTab],
                              searchInput,
                              productsactiveTab: tabsMap[productsactiveTab],
                            },
                            packingGroup?.productType !== 1 &&
                              packingGroup?.numberOfProducts > 1
                          )
                        )
                      }
                    />
                  ) : (
                    <div>
                      {parseFloat(packingGroup.packings[0].discount) || 0}{" "}
                      {cart?.majorAccountOrder && cart?.currency === "Dollar"
                        ? " $"
                        : " €"}
                    </div>
                  )}
                </div>
              )}
              {/* <div className="alignH gap-2">
                <label htmlFor="elRefClient">Référence client : </label>{" "}
                {!hideActions ? (
                  <input
                    id="elRefClient"
                    placeholder="reference client"
                    className="refClientItem p-1"
                    defaultValue={packingGroup.packings[0].refClient}
                    onChange={(e) =>
                      dispatch(
                        updatePacking(
                          packingGroup.packings[0].id,
                          { refClient: e.target.value },
                          {
                            activeTab,
                            searchInput,
                            productsactiveTab,
                          }
                        )
                      )
                    }
                  />
                ) : (
                  <div> {packingGroup.packings[0].refClient || " -"} </div>
                )}
              </div> */}
            </>
          ) : null}
        </div>
      )}

      {!hideActions && (
        <button
          className="bold alignC qteButton f16 br6"
          onClick={() => {
            dispatch(
              removeFromCart(packingGroup.packings[0].id, {
                activeTab: tabsMap[activeTab],
                searchInput,
                productsactiveTab: tabsMap[productsactiveTab],
              })
            );
          }}
        >
          -
        </button>
      )}
    </div>
  );
};

export default CartPackingItem;
