import React, { useState } from "react";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import { useTranslation } from "react-i18next";
import MediaPreview from "../Common/MediaPreview";

const MediaInstallation = ({ installation }) => {
  const { t } = useTranslation("common");
  const [previewMedia, setPreviewMedia] = useState();
  const media = installation?.attributes?.media?.data;

  return (
    <div className="col-4" key={installation?.id}>
      <div className="h-100 d-flex flex-column">
        <div className="position-relative flex-grow-1">
          <img
            src={`${IMAGE_END_POINT}${media?.attributes?.url}`}
            alt={media?.attributes?.name}
            className="w-100 h-100 ob-cover mb-2"
          />
          <button
            className="opacity-0 position-absolute start-0 top-0 w-100 h-100 z-1"
            onClick={() => setPreviewMedia(media?.attributes?.url)}
          >
            {t("Preview")}
          </button>

          {previewMedia && (
            <MediaPreview
              isUrl
              mediaType={
                media?.attributes?.mime?.includes("image")
                  ? "image"
                  : media?.attributes?.mime?.includes("video")
                  ? "video"
                  : null
              }
              file={previewMedia}
              setPreviewMedia={setPreviewMedia}
            />
          )}
        </div>
        <p className="text-bronze-500 bold f20 quickSand text-center mt-1">
          {t("Ref Client")} : {installation?.attributes?.refClient}
        </p>
      </div>
    </div>
  );
};

export default MediaInstallation;
