import React from "react";
import poseidonLogo from "../Images/Images/poseidon-logo-temp.png";

export const DashboardSvg = ({ color = "#666666" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 21.25 21.25"
    >
      <path
        id="Tracé_996"
        data-name="Tracé 996"
        d="M0,34.277A2.277,2.277,0,0,1,2.277,32H6.83a2.277,2.277,0,0,1,2.277,2.277V38.83A2.277,2.277,0,0,1,6.83,41.107H2.277A2.277,2.277,0,0,1,0,38.83Zm1.518,0V38.83a.76.76,0,0,0,.759.759H6.83a.761.761,0,0,0,.759-.759V34.277a.76.76,0,0,0-.759-.759H2.277A.759.759,0,0,0,1.518,34.277ZM0,46.42a2.277,2.277,0,0,1,2.277-2.277H6.83A2.277,2.277,0,0,1,9.107,46.42v4.554A2.277,2.277,0,0,1,6.83,53.25H2.277A2.277,2.277,0,0,1,0,50.973Zm1.518,0v4.554a.76.76,0,0,0,.759.759H6.83a.761.761,0,0,0,.759-.759V46.42a.761.761,0,0,0-.759-.759H2.277A.76.76,0,0,0,1.518,46.42ZM18.973,32a2.277,2.277,0,0,1,2.277,2.277V38.83a2.277,2.277,0,0,1-2.277,2.277H14.42a2.277,2.277,0,0,1-2.277-2.277V34.277A2.277,2.277,0,0,1,14.42,32Zm0,1.518H14.42a.76.76,0,0,0-.759.759V38.83a.761.761,0,0,0,.759.759h4.554a.761.761,0,0,0,.759-.759V34.277A.76.76,0,0,0,18.973,33.518Zm-6.83,12.9a2.277,2.277,0,0,1,2.277-2.277h4.554A2.277,2.277,0,0,1,21.25,46.42v4.554a2.277,2.277,0,0,1-2.277,2.277H14.42a2.277,2.277,0,0,1-2.277-2.277Zm1.518,0v4.554a.761.761,0,0,0,.759.759h4.554a.761.761,0,0,0,.759-.759V46.42a.761.761,0,0,0-.759-.759H14.42A.761.761,0,0,0,13.661,46.42Z"
        transform="translate(0 -32)"
        fill={color}
      />
    </svg>
  );
};

export const CatalogueSvg = ({ color = "#666666", size = 22 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20.863 20.863"
    >
      <path
        id="Tracé_1001"
        data-name="Tracé 1001"
        d="M5.961,39.079a1.118,1.118,0,1,1,1.118-1.118A1.115,1.115,0,0,1,5.961,39.079Zm2.98-1.118a.747.747,0,0,1,.745-.745h5.961a.745.745,0,1,1,0,1.49H9.687A.747.747,0,0,1,8.941,37.961Zm0,4.471a.747.747,0,0,1,.745-.745h5.961a.745.745,0,1,1,0,1.49H9.687A.747.747,0,0,1,8.941,42.432Zm0,4.471a.747.747,0,0,1,.745-.745h5.961a.745.745,0,1,1,0,1.49H9.687A.747.747,0,0,1,8.941,46.9Zm-2.98-5.588a1.118,1.118,0,1,1-1.118,1.118A1.115,1.115,0,0,1,5.961,41.314Zm0,6.706A1.118,1.118,0,1,1,7.079,46.9,1.115,1.115,0,0,1,5.961,48.02ZM0,34.98A2.98,2.98,0,0,1,2.98,32h14.9a2.982,2.982,0,0,1,2.98,2.98v14.9a2.983,2.983,0,0,1-2.98,2.98H2.98A2.982,2.982,0,0,1,0,49.883Zm1.49,0v14.9a1.49,1.49,0,0,0,1.49,1.49h14.9a1.489,1.489,0,0,0,1.49-1.49V34.98a1.49,1.49,0,0,0-1.49-1.49H2.98A1.49,1.49,0,0,0,1.49,34.98Z"
        transform="translate(0 -32)"
        fill={color}
      />
    </svg>
  );
};

export const CommandesSvg = ({
  color = "#666666",
  width = 24,
  height = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 24"
  >
    <path
      id="Tracé_997"
      data-name="Tracé 997"
      d="M6.82,3.188a2.251,2.251,0,0,1,4.359,0,.747.747,0,0,0,.727.563h.844a.752.752,0,0,1,.75.75V6h-9V4.5a.752.752,0,0,1,.75-.75h.844A.747.747,0,0,0,6.82,3.188ZM9,0A3.75,3.75,0,0,0,5.564,2.25H5.25a2.256,2.256,0,0,0-2.123,1.5H3a3,3,0,0,0-3,3V21a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V6.75a3,3,0,0,0-3-3h-.127a2.256,2.256,0,0,0-2.123-1.5h-.314A3.75,3.75,0,0,0,9,0Zm6,5.25a1.5,1.5,0,0,1,1.5,1.5V21A1.5,1.5,0,0,1,15,22.5H3A1.5,1.5,0,0,1,1.5,21V6.75A1.5,1.5,0,0,1,3,5.25V6A1.5,1.5,0,0,0,4.5,7.5h9A1.5,1.5,0,0,0,15,6ZM9.75,3.75A.75.75,0,1,0,9,4.5.75.75,0,0,0,9.75,3.75ZM8.03,11.03A.749.749,0,0,0,6.97,9.97l-1.72,1.72-.6-.6A.749.749,0,0,0,3.6,12.155L4.72,13.28a.753.753,0,0,0,1.059,0l2.25-2.25ZM9,12.75a.752.752,0,0,0,.75.75h3a.75.75,0,0,0,0-1.5h-3A.752.752,0,0,0,9,12.75Zm-1.5,4.5a.752.752,0,0,0,.75.75h4.5a.75.75,0,1,0,0-1.5H8.25A.752.752,0,0,0,7.5,17.25ZM5.25,18.375A1.125,1.125,0,1,0,4.125,17.25,1.125,1.125,0,0,0,5.25,18.375Z"
      fill={color}
    />
  </svg>
);

export const ProspectsSvg = ({ color = "#666666" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26.811 20.853"
  >
    <path
      id="Tracé_1035"
      data-name="Tracé 1035"
      d="M22.343,43.916H16.385a.745.745,0,0,0,0,1.49h5.958a.745.745,0,0,0,0-1.49ZM8.937,42.427a2.979,2.979,0,1,0-2.979-2.979A2.979,2.979,0,0,0,8.937,42.427Zm0-4.469a1.49,1.49,0,1,1-1.49,1.49A1.491,1.491,0,0,1,8.937,37.958Zm1.49,5.958H7.448A3.724,3.724,0,0,0,3.724,47.64a.745.745,0,1,0,1.49,0,2.235,2.235,0,0,1,2.234-2.234h2.979a2.234,2.234,0,0,1,2.234,2.234.745.745,0,1,0,1.49,0A3.723,3.723,0,0,0,10.427,43.916ZM23.832,32H2.979A2.979,2.979,0,0,0,0,34.979v14.9a2.979,2.979,0,0,0,2.979,2.979H23.832a2.979,2.979,0,0,0,2.979-2.979v-14.9A2.98,2.98,0,0,0,23.832,32Zm1.49,17.874a1.491,1.491,0,0,1-1.49,1.49H2.979a1.491,1.491,0,0,1-1.49-1.49v-14.9a1.491,1.491,0,0,1,1.49-1.49H23.832a1.491,1.491,0,0,1,1.49,1.49Zm-2.979-8.937H16.385a.745.745,0,0,0,0,1.49h5.958a.745.745,0,0,0,0-1.49Zm0-2.979H16.385a.745.745,0,0,0,0,1.49h5.958a.745.745,0,0,0,0-1.49Z"
      transform="translate(0 -32)"
      fill={color}
    />
  </svg>
);

export const CalendrierSvg = ({
  color = "#666666",
  width = 24,
  height = 24,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 24"
  >
    <path
      id="Tracé_999"
      data-name="Tracé 999"
      d="M4.5,17.25a.752.752,0,0,1,.75-.75h6a.75.75,0,1,1,0,1.5h-6A.752.752,0,0,1,4.5,17.25ZM15.75,12a.75.75,0,1,1,0,1.5H5.25a.75.75,0,1,1,0-1.5ZM5.25,0A.751.751,0,0,1,6,.75V3h9V.75a.75.75,0,0,1,1.5,0V3H18a3,3,0,0,1,3,3V21a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V6A3,3,0,0,1,3,3H4.5V.75A.751.751,0,0,1,5.25,0ZM19.5,9H1.5V21A1.5,1.5,0,0,0,3,22.5H18A1.5,1.5,0,0,0,19.5,21ZM18,4.5H3A1.5,1.5,0,0,0,1.5,6V7.5h18V6A1.5,1.5,0,0,0,18,4.5Z"
      fill={color}
    />
  </svg>
);

export const FAQSvg = ({ color = "#666666" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Tracé_998"
      data-name="Tracé 998"
      d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,22.5A10.5,10.5,0,1,1,22.5,12,10.513,10.513,0,0,1,12,22.5Zm-.75-6.375a1.125,1.125,0,1,0,1.125,1.125A1.125,1.125,0,0,0,11.25,16.125ZM13.388,6H10.613A3.117,3.117,0,0,0,7.5,9.113v.45a.75.75,0,0,0,1.5,0v-.45A1.612,1.612,0,0,1,10.613,7.5H13.39a1.613,1.613,0,0,1,.81,3.006L11.367,11.92A1.693,1.693,0,0,0,10.5,13.4v.853a.75.75,0,0,0,1.5,0V13.4a.187.187,0,0,1,.067-.149L14.9,11.832A3.111,3.111,0,0,0,13.388,6Z"
      fill={color}
    />
  </svg>
);

export const SAVSvg = ({ color = "#666666" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Groupe_2008"
    data-name="Groupe 2008"
    width="24"
    height="24"
    viewBox="0 0 21.094 24"
  >
    <path
      id="Tracé_1003"
      data-name="Tracé 1003"
      d="M46.234,15.937H44.125V15.28a5.428,5.428,0,0,0,1.505-1.217h1.542a2.581,2.581,0,0,0,2.578-2.578V9.141a2.112,2.112,0,0,0-1.406-1.988V6.8a6.8,6.8,0,0,0-13.594,0v.355a2.109,2.109,0,0,0,.7,4.1H36.2a5.4,5.4,0,0,0,2.767,4.03v.658H36.859A5.866,5.866,0,0,0,31,21.8v1.5a.7.7,0,0,0,.7.7H51.391a.7.7,0,0,0,.7-.7V21.8A5.866,5.866,0,0,0,46.234,15.937Zm2.109-4.453a1.173,1.173,0,0,1-1.172,1.172h-.664a5.346,5.346,0,0,0,.384-1.406h1.452Zm0-2.344v.7H46.938V8.437h.7A.7.7,0,0,1,48.344,9.141Zm-12.187.7h-.7a.7.7,0,0,1,0-1.406h.7Zm0-2.812V6.8a5.391,5.391,0,0,1,10.781,0v.234h-.456L44.33,5.31a.7.7,0,0,0-.936.052,5.661,5.661,0,0,1-4.029,1.669Zm1.406,3.516V8.437h1.8a7.052,7.052,0,0,0,4.557-1.653l1.61,1.288v2.475a3.959,3.959,0,0,1-.606,2.109H42.016a.7.7,0,0,0,0,1.406h1.4a3.982,3.982,0,0,1-5.857-3.516Zm5.156,5.262v.832a1.172,1.172,0,1,1-2.344,0v-.832a5.4,5.4,0,0,0,2.344,0Zm7.969,6.785H32.406v-.8a4.458,4.458,0,0,1,4.453-4.453h2.208a2.577,2.577,0,0,0,4.96,0h2.208A4.458,4.458,0,0,1,50.687,21.8Z"
      transform="translate(-31)"
      fill={color}
    />
  </svg>
);

export const FactureSvg = ({ color = "#666666", width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 24"
  >
    <path
      id="Tracé_1002"
      data-name="Tracé 1002"
      d="M3.75,7.5h3a.75.75,0,0,0,0-1.5h-3a.75.75,0,0,0,0,1.5Zm0-3h3a.75.75,0,0,0,0-1.5h-3a.75.75,0,0,0,0,1.5ZM17.123,5.873,12.131.881A3,3,0,0,0,10.008,0H3A3,3,0,0,0,0,3V21a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V7.992A2.99,2.99,0,0,0,17.123,5.873ZM10.5,1.6a1.477,1.477,0,0,1,.568.342L16.06,6.932A1.406,1.406,0,0,1,16.4,7.5H11.25a.752.752,0,0,1-.75-.75Zm6,19.4A1.5,1.5,0,0,1,15,22.5H3A1.5,1.5,0,0,1,1.5,21V3A1.5,1.5,0,0,1,3,1.5H9V6.75A2.251,2.251,0,0,0,11.25,9H16.5ZM3,12.75v3a1.5,1.5,0,0,0,1.5,1.5h9a1.5,1.5,0,0,0,1.5-1.5v-3a1.5,1.5,0,0,0-1.5-1.5h-9A1.5,1.5,0,0,0,3,12.75Zm10.5,3h-9v-3h9Zm.75,3.75h-3a.75.75,0,0,0,0,1.5h3a.75.75,0,1,0,0-1.5Z"
      fill={color}
    />
  </svg>
);
export const MonEquipeSVG = ({ color = "#666666" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 28.462 22.769"
    >
      <path
        id="Tracé_1036"
        data-name="Tracé 1036"
        d="M14.226,14.231A4.625,4.625,0,1,0,9.641,9.606,4.592,4.592,0,0,0,14.226,14.231Zm0-7.827a3.2,3.2,0,1,1-3.2,3.2A3.2,3.2,0,0,1,14.226,6.4Zm2.224,9.25H12.012a6.133,6.133,0,0,0-6.319,5.928,1.225,1.225,0,0,0,1.263,1.187H21.506a1.225,1.225,0,0,0,1.263-1.187A6.133,6.133,0,0,0,16.45,15.654ZM7.124,21.346a4.734,4.734,0,0,1,4.852-4.269H16.45A4.683,4.683,0,0,1,21.3,21.346ZM22.769,7.115a3.558,3.558,0,1,0-3.558-3.558A3.558,3.558,0,0,0,22.769,7.115Zm0-5.692a2.135,2.135,0,1,1-2.135,2.135A2.137,2.137,0,0,1,22.769,1.423ZM5.692,7.115A3.558,3.558,0,1,0,2.135,3.558,3.558,3.558,0,0,0,5.692,7.115Zm0-5.692A2.135,2.135,0,1,1,3.558,3.558,2.137,2.137,0,0,1,5.692,1.423ZM24.953,8.539h-2.9a3.341,3.341,0,0,0-1.444.331.711.711,0,1,0,.61,1.285,1.953,1.953,0,0,1,.834-.193h2.894a2.2,2.2,0,0,1,2.087,2.255v.591a.712.712,0,1,0,1.423,0v-.591A3.6,3.6,0,0,0,24.953,8.539ZM7.24,10.153a.711.711,0,0,0,.61-1.285A3.373,3.373,0,0,0,6.4,8.539H3.51A3.6,3.6,0,0,0,0,12.216v.591a.712.712,0,1,0,1.423,0v-.591A2.177,2.177,0,0,1,3.51,9.962H6.4A2,2,0,0,1,7.24,10.153Z"
        fill={color}
      />
    </svg>
  );
};

export const DeconnexionSvg = ({ color = "#2C659D" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="18.276"
      viewBox="0 0 19.5 18.276"
    >
      <g
        id="Groupe_1207"
        data-name="Groupe 1207"
        transform="translate(368.29 -76.141)"
      >
        <path
          id="Tracé_267"
          data-name="Tracé 267"
          d="M-352.661,90.6a.778.778,0,0,1,.006,1.1,9.072,9.072,0,0,1-6.5,2.713,9.149,9.149,0,0,1-9.138-9.138,9.149,9.149,0,0,1,9.138-9.138,9.073,9.073,0,0,1,6.5,2.713.778.778,0,0,1-.006,1.1.778.778,0,0,1-1.1-.006,7.527,7.527,0,0,0-5.39-2.251,7.59,7.59,0,0,0-7.581,7.581,7.59,7.59,0,0,0,7.581,7.581,7.527,7.527,0,0,0,5.39-2.251A.779.779,0,0,1-352.661,90.6Z"
          fill={color}
        />
        <path
          id="Tracé_268"
          data-name="Tracé 268"
          d="M-246.209,163.157a.778.778,0,0,0-1.1,0,.779.779,0,0,0,0,1.1l1.4,1.4h-8.742a.779.779,0,0,0-.778.778.779.779,0,0,0,.778.779h8.742l-1.4,1.4a.779.779,0,0,0,0,1.1.776.776,0,0,0,.55.228.776.776,0,0,0,.55-.228l2.729-2.729a.779.779,0,0,0,.228-.55.778.778,0,0,0-.228-.55Z"
          transform="translate(-105.538 -81.157)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const HeaderLogoSvg = ({ imageURL = "poseidon-spa-logo.png" }) => {
  return (
    <div className="ms-1 h-100 p-2">
      <img
        src={imageURL}
        alt="logo poseidon"
        height={"100%"}
        width={"auto"}
        className="ob-contain"
      />
    </div>
  );
};

export const SearchSVG = ({ color, width = 20, height = 20 }) => (
  <svg
    id="vuesax_linear_search-normal"
    data-name="vuesax/linear/search-normal"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <g
      id="vuesax_linear_search-normal-2"
      data-name="vuesax/linear/search-normal"
    >
      <g id="search-normal">
        <path
          id="Vector"
          d="M15,7.5A7.5,7.5,0,1,1,7.5,0,7.5,7.5,0,0,1,15,7.5Z"
          transform="translate(2 2)"
          fill="none"
          stroke={color}
          // rokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M2,2,0,0"
          transform="translate(16 16)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);

export const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Path"
      d="M15.7.3a.967.967,0,0,0-1.4,0L8,6.6,1.7.3A.967.967,0,0,0,.3.3a.967.967,0,0,0,0,1.4L6.6,8,.3,14.3a.967.967,0,0,0,0,1.4A.908.908,0,0,0,1,16a.908.908,0,0,0,.7-.3L8,9.4l6.3,6.3a.967.967,0,0,0,1.4,0,.967.967,0,0,0,0-1.4L9.4,8l6.3-6.3A.967.967,0,0,0,15.7.3Z"
      fill="#14748e"
    />
  </svg>
);
export const MarketingSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="19.668"
    viewBox="0 0 23 19.668"
  >
    <path
      id="Tracé_995"
      data-name="Tracé 995"
      d="M16.985,4.636c1.154-1.017,3.14-.335,3.14,1.065V20.866c0,1.4-1.986,2.08-3.14,1.065l-1.033-.976a13,13,0,0,0-7.327-3.2v3.9a2.363,2.363,0,0,1-2.516,2.169H5.391a2.362,2.362,0,0,1-2.516-2.169V17.62A2.7,2.7,0,0,1,0,15.107V11.389A2.724,2.724,0,0,1,2.875,8.91H6.451l1.312-.027a13.129,13.129,0,0,0,8.189-3.274Zm1.7,1.065a.36.36,0,0,0-.62-.213L17,6.462a14.346,14.346,0,0,1-6.936,3.394V16.71A14.055,14.055,0,0,1,17,20.1l1.069.976a.37.37,0,0,0,.62-.213ZM8.625,16.5V10.064c-.261.023-.521.043-.786.054l-1.352.031H2.875a1.373,1.373,0,0,0-1.437,1.239v3.718a1.326,1.326,0,0,0,1.438,1.239H6.487l1.352.1a4.443,4.443,0,0,1,.786.054ZM4.313,17.62v4.028a1.01,1.01,0,0,0,1.078.929h.719a1.01,1.01,0,0,0,1.078-.929V17.655l-.737-.035Zm17.969-6.231a.7.7,0,0,1,.719.62v2.479a.727.727,0,0,1-1.437,0V12.009a.7.7,0,0,1,.719-.62Z"
      transform="translate(0 -4.149)"
      fill="#666"
    />
  </svg>
);

export const OpenSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.06"
    height="17.333"
    viewBox="0 0 22.06 17.333"
  >
    <path
      id="Tracé_1004"
      data-name="Tracé 1004"
      d="M0,64.788A.788.788,0,0,1,.788,64H21.272a.788.788,0,0,1,0,1.576H.788A.788.788,0,0,1,0,64.788Zm0,7.879a.789.789,0,0,1,.788-.788H21.272a.788.788,0,0,1,0,1.576H.788A.789.789,0,0,1,0,72.667Zm21.272,8.667H.788a.788.788,0,0,1,0-1.576H21.272a.788.788,0,0,1,0,1.576Z"
      transform="translate(0 -64)"
      fill="#14748e"
    />
  </svg>
);

export const SocieteSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.811"
    height="20.853"
    viewBox="0 0 26.811 20.853"
  >
    <path
      id="Tracé_1035"
      data-name="Tracé 1035"
      d="M22.343,43.916H16.385a.745.745,0,0,0,0,1.49h5.958a.745.745,0,0,0,0-1.49ZM8.937,42.427a2.979,2.979,0,1,0-2.979-2.979A2.979,2.979,0,0,0,8.937,42.427Zm0-4.469a1.49,1.49,0,1,1-1.49,1.49A1.491,1.491,0,0,1,8.937,37.958Zm1.49,5.958H7.448A3.724,3.724,0,0,0,3.724,47.64a.745.745,0,1,0,1.49,0,2.235,2.235,0,0,1,2.234-2.234h2.979a2.234,2.234,0,0,1,2.234,2.234.745.745,0,1,0,1.49,0A3.723,3.723,0,0,0,10.427,43.916ZM23.832,32H2.979A2.979,2.979,0,0,0,0,34.979v14.9a2.979,2.979,0,0,0,2.979,2.979H23.832a2.979,2.979,0,0,0,2.979-2.979v-14.9A2.98,2.98,0,0,0,23.832,32Zm1.49,17.874a1.491,1.491,0,0,1-1.49,1.49H2.979a1.491,1.491,0,0,1-1.49-1.49v-14.9a1.491,1.491,0,0,1,1.49-1.49H23.832a1.491,1.491,0,0,1,1.49,1.49Zm-2.979-8.937H16.385a.745.745,0,0,0,0,1.49h5.958a.745.745,0,0,0,0-1.49Zm0-2.979H16.385a.745.745,0,0,0,0,1.49h5.958a.745.745,0,0,0,0-1.49Z"
      transform="translate(0 -32)"
      fill="#666"
    />
  </svg>
);

export const AvatarsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.462"
    height="22.769"
    viewBox="0 0 28.462 22.769"
  >
    <path
      id="Tracé_1036"
      data-name="Tracé 1036"
      d="M14.226,14.231A4.625,4.625,0,1,0,9.641,9.606,4.592,4.592,0,0,0,14.226,14.231Zm0-7.827a3.2,3.2,0,1,1-3.2,3.2A3.2,3.2,0,0,1,14.226,6.4Zm2.224,9.25H12.012a6.133,6.133,0,0,0-6.319,5.928,1.225,1.225,0,0,0,1.263,1.187H21.506a1.225,1.225,0,0,0,1.263-1.187A6.133,6.133,0,0,0,16.45,15.654ZM7.124,21.346a4.734,4.734,0,0,1,4.852-4.269H16.45A4.683,4.683,0,0,1,21.3,21.346ZM22.769,7.115a3.558,3.558,0,1,0-3.558-3.558A3.558,3.558,0,0,0,22.769,7.115Zm0-5.692a2.135,2.135,0,1,1-2.135,2.135A2.137,2.137,0,0,1,22.769,1.423ZM5.692,7.115A3.558,3.558,0,1,0,2.135,3.558,3.558,3.558,0,0,0,5.692,7.115Zm0-5.692A2.135,2.135,0,1,1,3.558,3.558,2.137,2.137,0,0,1,5.692,1.423ZM24.953,8.539h-2.9a3.341,3.341,0,0,0-1.444.331.711.711,0,1,0,.61,1.285,1.953,1.953,0,0,1,.834-.193h2.894a2.2,2.2,0,0,1,2.087,2.255v.591a.712.712,0,1,0,1.423,0v-.591A3.6,3.6,0,0,0,24.953,8.539ZM7.24,10.153a.711.711,0,0,0,.61-1.285A3.373,3.373,0,0,0,6.4,8.539H3.51A3.6,3.6,0,0,0,0,12.216v.591a.712.712,0,1,0,1.423,0v-.591A2.177,2.177,0,0,1,3.51,9.962H6.4A2,2,0,0,1,7.24,10.153Z"
      fill="#666"
    />
  </svg>
);

export const GlobeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.605"
    height="18.605"
    viewBox="0 0 18.605 18.605"
  >
    <g
      id="Groupe_2065"
      data-name="Groupe 2065"
      transform="translate(27.803 -22.231)"
    >
      <g id="Groupe_2060" data-name="Groupe 2060">
        <path
          id="Tracé_1007"
          data-name="Tracé 1007"
          d="M9.3,468.3A9.3,9.3,0,1,1,0,477.6,9.3,9.3,0,0,1,9.3,468.3Zm0,17.443c.607,0,1.468-.523,2.249-2.082a10.145,10.145,0,0,0,.843-2.569H6.178a11.619,11.619,0,0,0,.876,2.569c.781,1.559,1.643,2.082,2.249,2.082ZM5.97,479.928h6.665a16.992,16.992,0,0,0,.156-2.325,17,17,0,0,0-.156-2.326H5.97a17.386,17.386,0,0,0,0,4.651Zm6.425-5.814a9.736,9.736,0,0,0-.843-2.569c-.781-1.56-1.643-2.082-2.249-2.082s-1.468.522-2.249,2.082a11.072,11.072,0,0,0-.876,2.569Zm1.381,1.163a13.83,13.83,0,0,1,.178,2.326,13.822,13.822,0,0,1-.178,2.325H17.1a8.169,8.169,0,0,0,0-4.651ZM11.9,469.886a10.777,10.777,0,0,1,1.715,4.228h3.042a8.154,8.154,0,0,0-4.757-4.228Zm-5.2,0a8.166,8.166,0,0,0-4.758,4.228H4.989A10.777,10.777,0,0,1,6.7,469.886ZM1.163,477.6a8.151,8.151,0,0,0,.337,2.325H4.8a18.7,18.7,0,0,1,0-4.651H1.5a8.156,8.156,0,0,0-.337,2.326Zm15.495,3.489H13.616a10.76,10.76,0,0,1-1.715,4.226A8.144,8.144,0,0,0,16.658,481.091Zm-11.668,0H1.946A8.156,8.156,0,0,0,6.7,485.317a10.76,10.76,0,0,1-1.715-4.226Z"
          transform="translate(-27.803 -446.069)"
          fill="#14748e"
        />
      </g>
    </g>
  </svg>
);

export const PointerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.682"
    height="15.209"
    viewBox="0 0 19.682 15.209"
  >
    <g
      id="Groupe_2067"
      data-name="Groupe 2067"
      transform="translate(-1391.505 -3262.5)"
    >
      <g
        id="Groupe_2091"
        data-name="Groupe 2091"
        transform="translate(1391.505 3209.167)"
      >
        <g
          id="Groupe_2088"
          data-name="Groupe 2088"
          transform="translate(2.313 54.716)"
        >
          <g
            id="Groupe_2087"
            data-name="Groupe 2087"
            transform="translate(0 0)"
          >
            <path
              id="Tracé_1016"
              data-name="Tracé 1016"
              d="M71.243,90.5l-3.364-3.7a1.713,1.713,0,0,0-2.422.005,1.675,1.675,0,0,0-.433.74,1.714,1.714,0,0,0-2.685.894,1.714,1.714,0,0,0-2.25.156,1.693,1.693,0,0,0-.429.722l-1.585-1.585a1.712,1.712,0,0,0-2.422,2.422l6.091,6.091-3.613.567a2,2,0,0,0-1.712,1.974,1.344,1.344,0,0,0,1.342,1.342H66.2a4.888,4.888,0,0,0,3.479-1.441l1.407-1.407a4.914,4.914,0,0,0,.16-6.782Zm-.793,6.15-1.407,1.407A4,4,0,0,1,66.2,99.234H57.761a.448.448,0,0,1-.447-.447,1.107,1.107,0,0,1,.951-1.09l4.487-.7A.447.447,0,0,0,63,96.234l-6.712-6.712a.818.818,0,0,1,1.157-1.157l4.476,4.476a.447.447,0,1,0,.632-.633L60.722,90.38a.818.818,0,0,1,1.157-1.157l1.829,1.829a.447.447,0,1,0,.632-.633l-.933-.933a.818.818,0,0,1,1.157-1.157l.938.938h.005a.447.447,0,0,0,.624-.641l-.038-.038a.818.818,0,0,1,1.142-1.172l3.35,3.681a4.021,4.021,0,0,1-.132,5.548Z"
              transform="translate(-55.153 -86.302)"
              fill="#14748e"
            />
          </g>
        </g>
        <g
          id="Groupe_2090"
          data-name="Groupe 2090"
          transform="translate(0 53.333)"
        >
          <g
            id="Groupe_2089"
            data-name="Groupe 2089"
            transform="translate(0 0)"
          >
            <path
              id="Tracé_1017"
              data-name="Tracé 1017"
              d="M7.69,55.707a4.022,4.022,0,1,0-5.317,5.318.448.448,0,1,0,.369-.816,3.127,3.127,0,1,1,4.133-4.133.447.447,0,1,0,.815-.369Z"
              transform="translate(0 -53.333)"
              fill="#14748e"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const WhiteCalendarSvg = () => (
  <svg
    id="Groupe_1608"
    data-name="Groupe 1608"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_273"
          data-name="Rectangle 273"
          width="15"
          height="15"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="Groupe_1545" data-name="Groupe 1545" clip-path="url(#clip-path)">
      <path
        id="Tracé_707"
        data-name="Tracé 707"
        d="M366.574,190a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-355.732 -184.97)"
        fill="#fff"
      />
      <path
        id="Tracé_708"
        data-name="Tracé 708"
        d="M12.4,1.148h-.746V.574a.574.574,0,0,0-1.148,0v.574H7.893V.574a.574.574,0,1,0-1.148,0v.574H4.162V.574a.574.574,0,1,0-1.148,0v.574H2.3A2.3,2.3,0,0,0,0,3.444V12.4a2.3,2.3,0,0,0,2.3,2.3H6.688a.574.574,0,1,0,0-1.148H2.3A1.149,1.149,0,0,1,1.148,12.4V3.444A1.149,1.149,0,0,1,2.3,2.3h.718V2.87a.574.574,0,1,0,1.148,0V2.3H6.745V2.87a.574.574,0,1,0,1.148,0V2.3h2.612V2.87a.574.574,0,0,0,1.148,0V2.3H12.4a1.149,1.149,0,0,1,1.148,1.148V6.716a.574.574,0,1,0,1.148,0V3.444a2.3,2.3,0,0,0-2.3-2.3"
        transform="translate(-0.237 -0.424)"
        fill="#fff"
      />
      <path
        id="Tracé_709"
        data-name="Tracé 709"
        d="M273.473,270a3.473,3.473,0,1,0,3.473,3.473A3.477,3.477,0,0,0,273.473,270m0,5.8a2.325,2.325,0,1,1,2.325-2.325,2.328,2.328,0,0,1-2.325,2.325"
        transform="translate(-262.488 -262.674)"
        fill="#fff"
      />
      <path
        id="Tracé_710"
        data-name="Tracé 710"
        d="M372.406,331.177h-.258v-.6a.574.574,0,0,0-1.148,0v1.177a.574.574,0,0,0,.574.574h.832a.574.574,0,0,0,0-1.148"
        transform="translate(-360.589 -320.952)"
        fill="#fff"
      />
      <path
        id="Tracé_711"
        data-name="Tracé 711"
        d="M279.574,190a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-271.229 -184.97)"
        fill="#fff"
      />
      <path
        id="Tracé_712"
        data-name="Tracé 712"
        d="M192.574,277a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-186.727 -269.473)"
        fill="#fff"
      />
      <path
        id="Tracé_713"
        data-name="Tracé 713"
        d="M105.574,190a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-102.224 -184.97)"
        fill="#fff"
      />
      <path
        id="Tracé_714"
        data-name="Tracé 714"
        d="M105.574,277a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-102.224 -269.473)"
        fill="#fff"
      />
      <path
        id="Tracé_715"
        data-name="Tracé 715"
        d="M105.574,364a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-102.224 -353.976)"
        fill="#fff"
      />
      <path
        id="Tracé_716"
        data-name="Tracé 716"
        d="M192.574,364a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-186.727 -353.976)"
        fill="#fff"
      />
      <path
        id="Tracé_717"
        data-name="Tracé 717"
        d="M192.574,190a.574.574,0,1,1-.574.574.574.574,0,0,1,.574-.574"
        transform="translate(-186.727 -184.97)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const PayementCardSvg = () => (
  <svg
    id="Payment_Icon"
    data-name="Payment Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <rect id="bound" width="28" height="28" fill="none" />
    <path
      id="Combined_Shape"
      data-name="Combined Shape"
      d="M21,18H2a2,2,0,0,1-2-2V7.714H23V16A2,2,0,0,1,21,18Zm-3.9-6.428a1,1,0,0,0-1,1v.572a1,1,0,0,0,1,1h2.6a1,1,0,0,0,1-1v-.572a1,1,0,0,0-1-1ZM23,3.858H0V2A2,2,0,0,1,2,0H21a2,2,0,0,1,2,2V3.857Z"
      transform="translate(3 4.615)"
      fill="#666"
    />
  </svg>
);

export const FilterSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.043"
    height="15.489"
    viewBox="0 0 17.043 15.489"
  >
    <path
      id="Icon_feather-filter"
      data-name="Icon feather-filter"
      d="M18.543,4.5H3l6.217,7.352v5.083l3.109,1.554V11.852Z"
      transform="translate(-2.25 -3.75)"
      fill="none"
      stroke="#f8f8f8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const InvalidSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.585"
    height="17.585"
    viewBox="0 0 17.585 17.585"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-21.333 -21.333)">
      <path
        id="Tracé_1026"
        data-name="Tracé 1026"
        d="M30.125,21.333a8.792,8.792,0,1,0,8.792,8.792A8.792,8.792,0,0,0,30.125,21.333Zm3.391,11.054a.8.8,0,1,1-1.111,1.15l-.02-.02-2.26-2.261-2.26,2.261a.8.8,0,0,1-1.13-1.13L29,30.125l-2.26-2.261a.8.8,0,0,1,1.111-1.15l.02.02L30.125,29l2.26-2.261a.8.8,0,1,1,1.15,1.111l-.02.02-2.26,2.261Z"
        fill="#f90a1a"
      />
    </g>
  </svg>
);

export const DeliverySvg = () => (
  <svg
    id="Shipping_Icon"
    data-name="Shipping Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <rect id="bound" width="28" height="28" fill="none" />
    <path
      id="Combined_Shape"
      data-name="Combined Shape"
      d="M10.125,21.234a1.126,1.126,0,0,1-.547-.142l-9-5.031A1.133,1.133,0,0,1,0,15.072V5.156A1.135,1.135,0,0,1,.668,4.121L9.668.1a1.115,1.115,0,0,1,.913,0l2.776,1.241L5.175,5.911V11.1l2.25,1.132V7.243l8.507-4.754,3.65,1.632a1.135,1.135,0,0,1,.668,1.035v9.916a1.133,1.133,0,0,1-.579.989l-9,5.031A1.126,1.126,0,0,1,10.125,21.234Z"
      transform="translate(3.375 3.997)"
      fill="#666"
    />
  </svg>
);

export const NotifSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="icon"
      d="M1268.434,1756.456v-3.637a4.364,4.364,0,1,0-8.727,0v3.637a2.9,2.9,0,0,1-.389,1.455h9.506A2.9,2.9,0,0,1,1268.434,1756.456Zm2.909,2.91H1256.8a.727.727,0,0,1,0-1.455,1.455,1.455,0,0,0,1.455-1.455v-3.637a5.818,5.818,0,1,1,11.636,0v3.637a1.454,1.454,0,0,0,1.454,1.455A.727.727,0,0,1,1271.343,1759.365Zm-5.385,2.547a2.182,2.182,0,0,1-3.775,0,.727.727,0,0,1,.629-1.093h2.516A.727.727,0,0,1,1265.958,1761.912Z"
      transform="translate(-1256.07 -1746.999)"
      fill="#fff"
    />
  </svg>
);

export const PanierSVG = ({ color = "#14748e" }) => (
  <svg
    id="Cart_Icon"
    data-name="Cart Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <rect id="bound" width="28" height="28" fill="none" />
    <path
      id="Path_30"
      data-name="Path 30"
      d="M6.25,3.309,1.842,9.869a.91.91,0,0,1-1.467.165A1.514,1.514,0,0,1,.241,8.215L5.45.465a.921.921,0,0,1,1.6,0l5.208,7.75a1.514,1.514,0,0,1-.133,1.819.91.91,0,0,1-1.467-.165Z"
      transform="translate(7.75 2.583)"
      fill={color}
    />
    <path
      id="Combined_Shape"
      data-name="Combined Shape"
      d="M14.172,15.5H7.37a3.417,3.417,0,0,1-3.14-2.384L.088,1.788A1.443,1.443,0,0,1,.191.575,1.1,1.1,0,0,1,1.133,0H20.408a1.1,1.1,0,0,1,.944.576,1.442,1.442,0,0,1,.1,1.212L17.312,13.115A3.417,3.417,0,0,1,14.172,15.5Zm-3.4-10.333A2.444,2.444,0,0,0,8.5,7.75a2.443,2.443,0,0,0,2.267,2.583A2.443,2.443,0,0,0,13.038,7.75,2.444,2.444,0,0,0,10.771,5.166Z"
      transform="translate(3.229 8.625)"
      fill={color}
    />
  </svg>
);

export const PdfIcon = () => (
  <svg
    id="drive-pdf"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className="pointer"
  >
    <path
      id="Tracé_263"
      data-name="Tracé 263"
      d="M14.756,9.956h0a6.6,6.6,0,0,0,.356-1.6V8a2.776,2.776,0,0,0,0-1.778V6.044l-.178-.178h0a.174.174,0,0,1-.178.178,7.231,7.231,0,0,0,0,3.911ZM9.422,22.222a3.886,3.886,0,0,0-.889.533A8.759,8.759,0,0,0,6.222,25.6h0c1.067-.178,2.133-1.244,3.2-3.378Zm16.356-2.667c-.178-.178-.889-.711-3.378-.711h-.356v.178a9.542,9.542,0,0,0,3.378.889h.533v-.178h0A.174.174,0,0,1,25.778,19.556ZM28.444,0H3.556A3.566,3.566,0,0,0,0,3.556V28.444A3.566,3.566,0,0,0,3.556,32H28.444A3.566,3.566,0,0,0,32,28.444V3.556A3.566,3.566,0,0,0,28.444,0ZM26.489,20.978a3.55,3.55,0,0,1-1.6.356,12.815,12.815,0,0,1-5.333-1.244,29.272,29.272,0,0,0-7.111,1.422c-.178,0-.178,0-.356.178C9.956,25.422,8.178,27.2,6.756,27.2c-.356,0-.533,0-.711-.178l-.889-.533v-.178a1.6,1.6,0,0,1-.178-.889,6.842,6.842,0,0,1,3.378-3.733c.356-.178.889-.533,1.6-.889.533-.889,1.067-1.956,1.778-3.2a37.612,37.612,0,0,0,1.956-5.156h0c-.711-2.133-1.067-3.378-.356-5.867.178-.711.711-1.422,1.422-1.422h.356a2.527,2.527,0,0,1,1.067.356c1.245,1.244.711,4.089,0,6.4v.178a12.84,12.84,0,0,0,2.844,4.622,12.476,12.476,0,0,0,1.6,1.067c.889,0,1.6-.178,2.311-.178,2.133,0,3.556.356,4.089,1.244a2.254,2.254,0,0,1,.178,1.067A10.89,10.89,0,0,1,26.489,20.978ZM14.933,14.044a37.023,37.023,0,0,1-1.778,4.267c-.356.711-.711,1.244-1.067,1.956h.356a26.894,26.894,0,0,1,5.867-1.6,2.453,2.453,0,0,1-.711-.533A18.074,18.074,0,0,1,14.933,14.044Z"
      fill="#14748e"
    />
  </svg>
);

export const PhotoSvg = () => (
  <svg
    id="Groupe_1458"
    data-name="Groupe 1458"
    xmlns="http://www.w3.org/2000/svg"
    width="26.41"
    height="22.888"
    viewBox="0 0 26.41 22.888"
  >
    <path
      id="Tracé_348"
      data-name="Tracé 348"
      d="M39.2,439.788H35.415l-.44-1.32a3.209,3.209,0,0,0-3.05-2.2H26.486a3.209,3.209,0,0,0-3.05,2.2L23,439.788H19.215A3.219,3.219,0,0,0,16,443v12.941a3.218,3.218,0,0,0,3.215,3.211H39.2a3.218,3.218,0,0,0,3.211-3.215V443A3.22,3.22,0,0,0,39.2,439.788ZM40.65,455.94a1.457,1.457,0,0,1-1.454,1.454H19.215a1.457,1.457,0,0,1-1.454-1.454V443a1.456,1.456,0,0,1,1.454-1.451H23.63a.881.881,0,0,0,.834-.6l.642-1.925a1.452,1.452,0,0,1,1.379-.994h5.439a1.45,1.45,0,0,1,1.379.994l.642,1.925a.881.881,0,0,0,.834.6H39.2A1.456,1.456,0,0,1,40.65,443Z"
      transform="translate(-16 -436.267)"
      fill="#666"
    />
    <path
      id="Tracé_349"
      data-name="Tracé 349"
      d="M30.6,443.733a6.6,6.6,0,1,0,6.6,6.6A6.6,6.6,0,0,0,30.6,443.733Zm0,11.444a4.842,4.842,0,1,1,4.842-4.842A4.842,4.842,0,0,1,30.6,455.177Z"
      transform="translate(-17.397 -437.571)"
      fill="#666"
      stroke="rgba(0,0,0,0)"
      strokeWidth="1"
      stroke-dasharray="10 5"
    />
    <ellipse
      id="Ellipse_150"
      data-name="Ellipse 150"
      cx="0.88"
      cy="0.88"
      rx="0.88"
      ry="0.88"
      transform="translate(21.128 7.042)"
      fill="#666"
      stroke="rgba(0,0,0,0)"
      strokeWidth="1"
      stroke-dasharray="10 5"
    />
  </svg>
);

export const WhiteQuestionSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <path
      id="Tracé_962"
      data-name="Tracé 962"
      d="M4.6,9.522a1.226,1.226,0,1,0,0,2.449,1.254,1.254,0,0,0,1.282-1.224h0A1.256,1.256,0,0,0,4.6,9.522Zm3.4,0a1.226,1.226,0,1,0,1.281,1.225h0A1.256,1.256,0,0,0,8,9.522Zm3.4,0a1.226,1.226,0,1,0,1.281,1.225h0A1.256,1.256,0,0,0,11.4,9.522ZM15,15.147a.548.548,0,0,1-.559.533h-4.32a.5.5,0,0,0-.433.239L8,18.714,6.311,15.92a.5.5,0,0,0-.433-.239H1.558A.548.548,0,0,1,1,15.147v-8.8a.547.547,0,0,1,.558-.533H14.442A.547.547,0,0,1,15,6.347ZM14.442,4.858A1.526,1.526,0,0,1,16,6.347v8.8a1.528,1.528,0,0,1-1.558,1.489H10.41l-1.715,2.84a.825.825,0,0,1-1.1.278.787.787,0,0,1-.29-.278L5.59,16.636H1.558A1.528,1.528,0,0,1,0,15.147v-8.8A1.526,1.526,0,0,1,1.558,4.858Z"
      transform="translate(0 -4.858)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);

export const DownloadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40.424"
    height="30.318"
    viewBox="0 0 40.424 30.318"
  >
    <g id="telecharger" transform="translate(0 -3)">
      <path
        id="Tracé_1028"
        data-name="Tracé 1028"
        d="M33.6,15.122A13.466,13.466,0,0,0,10.188,7.466a6.7,6.7,0,0,0-8.5,6.482,6.456,6.456,0,0,0,.649,2.829,10.088,10.088,0,0,0,7.773,16.541h3.941a.842.842,0,1,0,0-1.684H10.106a8.4,8.4,0,0,1-6.14-14.162.842.842,0,0,0,.11-1.007,4.853,4.853,0,0,1-.707-2.517A5.059,5.059,0,0,1,8.422,8.9a4.985,4.985,0,0,1,1.747.336.847.847,0,0,0,.94-.254A11.782,11.782,0,0,1,31.97,15.846a.843.843,0,0,0,.679.782,7.577,7.577,0,0,1-1.489,15.006H26.377a.842.842,0,1,0,0,1.684h4.783a9.262,9.262,0,0,0,2.443-18.2Z"
        transform="translate(0)"
        fill="#666"
      />
      <path
        id="Tracé_1029"
        data-name="Tracé 1029"
        d="M21.1,21.248a.841.841,0,0,0-1.168.234A14.614,14.614,0,0,1,15.58,25.9V11.842a.842.842,0,0,0-1.684,0V25.91c-1.473-.712-3.349-2.925-4.352-4.429a.842.842,0,1,0-1.4.934c.37.556,3.7,5.428,6.6,5.428s6.226-4.872,6.6-5.428a.842.842,0,0,0-.234-1.168Z"
        transform="translate(5.475 5.475)"
        fill="#666"
      />
    </g>
  </svg>
);

export const SuccessSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.593"
    height="18.593"
    viewBox="0 0 18.593 18.593"
  >
    <g
      id="Groupe_2186"
      data-name="Groupe 2186"
      transform="translate(-42.667 -42.667)"
    >
      <g
        id="Groupe_2184"
        data-name="Groupe 2184"
        transform="translate(42.667 42.667)"
      >
        <circle
          id="Ellipse_186"
          data-name="Ellipse 186"
          cx="9.297"
          cy="9.297"
          r="9.297"
          transform="translate(0 0)"
          fill="#1ad598"
        />
        <circle
          id="Ellipse_187"
          data-name="Ellipse 187"
          cx="9.297"
          cy="9.297"
          r="9.297"
          fill="#1ad598"
        />
      </g>
      <g
        id="Groupe_2185"
        data-name="Groupe 2185"
        transform="translate(48.5 49.43)"
      >
        <path
          id="Tracé_1025"
          data-name="Tracé 1025"
          d="M179.066,203a.71.71,0,0,1-.465-.186l-1.859-1.859a.723.723,0,0,1,1.023-1.023l1.395,1.395,3.254-3.254a.723.723,0,1,1,1.023,1.023l-3.719,3.719a2.365,2.365,0,0,1-.651.186Z"
          transform="translate(-176.533 -197.867)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const VideoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36.583"
    height="23.422"
    viewBox="0 0 36.583 23.422"
  >
    <path
      id="Tracé_1023"
      data-name="Tracé 1023"
      d="M35.214,89a.755.755,0,0,0-.746-.013L26.3,93.444V90.769A3.774,3.774,0,0,0,22.534,87H3.769A3.774,3.774,0,0,0,0,90.769v14.883a3.774,3.774,0,0,0,3.769,3.769H22.534a3.774,3.774,0,0,0,3.769-3.769v-2.626l8.165,4.458a.754.754,0,0,0,1.115-.662V89.648A.754.754,0,0,0,35.214,89ZM24.795,105.653a2.264,2.264,0,0,1-2.262,2.262H3.769a2.264,2.264,0,0,1-2.262-2.262V90.769a2.264,2.264,0,0,1,2.262-2.262H22.534A2.264,2.264,0,0,1,24.8,90.769Zm9.28-.1L26.3,101.309V95.162l7.772-4.243Z"
      transform="translate(0.5 -86.5)"
      fill="#666"
      stroke="rgba(0,0,0,0)"
      strokeWidth="1"
      stroke-dasharray="10 5"
    />
  </svg>
);

export const LivraisonSVG = ({ color = "#666" }) => (
  <svg
    id="Shipping_Icon"
    data-name="Shipping Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <rect id="bound" width="28" height="28" fill="none" />
    <path
      id="Combined_Shape"
      data-name="Combined Shape"
      d="M10.125,21.234a1.126,1.126,0,0,1-.547-.142l-9-5.031A1.133,1.133,0,0,1,0,15.072V5.156A1.135,1.135,0,0,1,.668,4.121L9.668.1a1.115,1.115,0,0,1,.913,0l2.776,1.241L5.175,5.911V11.1l2.25,1.132V7.243l8.507-4.754,3.65,1.632a1.135,1.135,0,0,1,.668,1.035v9.916a1.133,1.133,0,0,1-.579.989l-9,5.031A1.126,1.126,0,0,1,10.125,21.234Z"
      transform="translate(3.375 3.997)"
      fill={color}
    />
  </svg>
);

export const PaymentCardSVG = ({ color = "#666" }) => (
  <svg
    id="Payment_Icon"
    data-name="Payment Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <rect id="bound" width="28" height="28" fill="none" />
    <path
      id="Combined_Shape"
      data-name="Combined Shape"
      d="M21,18H2a2,2,0,0,1-2-2V7.714H23V16A2,2,0,0,1,21,18Zm-3.9-6.428a1,1,0,0,0-1,1v.572a1,1,0,0,0,1,1h2.6a1,1,0,0,0,1-1v-.572a1,1,0,0,0-1-1ZM23,3.858H0V2A2,2,0,0,1,2,0H21a2,2,0,0,1,2,2V3.857Z"
      transform="translate(3 4.615)"
      fill={color}
    />
  </svg>
);

export const ValideSVG = ({ width = "18.593", height = "18.593" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18.593 18.593"
  >
    <g
      id="Groupe_2186"
      data-name="Groupe 2186"
      transform="translate(-42.667 -42.667)"
    >
      <g
        id="Groupe_2184"
        data-name="Groupe 2184"
        transform="translate(42.667 42.667)"
      >
        <circle
          id="Ellipse_186"
          data-name="Ellipse 186"
          cx="9.297"
          cy="9.297"
          r="9.297"
          transform="translate(0 0)"
          fill="#109167"
        />
        <circle
          id="Ellipse_187"
          data-name="Ellipse 187"
          cx="9.297"
          cy="9.297"
          r="9.297"
          fill="#109167"
        />
      </g>
      <g
        id="Groupe_2185"
        data-name="Groupe 2185"
        transform="translate(48.5 49.43)"
      >
        <path
          id="Tracé_1025"
          data-name="Tracé 1025"
          d="M179.066,203a.71.71,0,0,1-.465-.186l-1.859-1.859a.723.723,0,0,1,1.023-1.023l1.395,1.395,3.254-3.254a.723.723,0,1,1,1.023,1.023l-3.719,3.719a2.365,2.365,0,0,1-.651.186Z"
          transform="translate(-176.533 -197.867)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const DownloadPdfSvg = ({ bgColor = "#14748E33" }) => (
  <div
    style={{ width: "34px", height: "32px", backgroundColor: bgColor }}
    className="br5 colAlignC pointer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="#14748E"
      className="bi bi-download"
      viewBox="0 0 16 16"
    >
      <path
        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
        stroke="#14748E"
        stroke-width="1"
      />
      <path
        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
        stroke="#14748E"
        strokeWidth="1"
      />
    </svg>
  </div>
);

export const VideoBtnSvg = () => (
  <div
    style={{ width: "34px", height: "32px", backgroundColor: "#14748e" }}
    className="br5 colAlignC pointer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="white"
      className="bi bi-camera-video"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
        strokeWidth={1}
        stroke="white"
      />
    </svg>
  </div>
);
export const DownloadVideoSvg = () => (
  <div
    style={{ width: "34px", height: "32px", backgroundColor: "#14748E33" }}
    className="br5 colAlignC pointer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="#14748E"
      className="bi bi-play"
      viewBox="0 0 16 16"
    >
      <path
        d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
        // strokeWidth={1}
        // stroke="#14748E"
      />
    </svg>
  </div>
);

export const AvatarSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill={color}
    className="bi bi-person"
    viewBox="0 0 16 16"
  >
    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
  </svg>
);

export const CheckBoxSVG = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="21"
      height="21"
      viewBox="0 0 256 256"
      enableBackground="new 0 0 256 256"
      xml="preserve"
      className={className}
    >
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M1.000000,49.000000 
	C1.000000,33.020668 1.000000,17.041336 1.000005,1.057667 
	C86.333344,1.053331 171.666687,1.053331 257.000000,1.053331 
	C257.000000,12.425335 257.000000,23.744009 256.626160,35.703468 
	C252.147644,34.125282 249.163773,35.144871 245.805710,38.527763 
	C210.234879,74.361519 174.475449,110.008018 138.810349,145.748383 
	C137.430679,147.130966 136.478378,148.940002 135.157242,150.787643 
	C120.284004,135.871384 106.408829,121.947578 92.522247,108.035172 
	C88.951164,104.457458 84.530258,103.792900 82.127968,106.424606 
	C79.884834,108.881912 80.652077,113.130798 83.979736,116.462036 
	C98.229492,130.727081 112.487015,144.984360 126.742943,159.243225 
	C133.320053,165.821686 135.653992,165.829041 142.209320,159.274582 
	C178.974991,122.513680 215.731216,85.743332 252.534668,49.020309 
	C253.783890,47.773796 255.500992,46.996181 257.000000,46.000008 
	C257.000000,116.214348 257.000000,186.428696 257.000000,257.000000 
	C241.314240,257.000000 225.625778,257.000000 209.300705,256.661316 
	C209.868073,255.513458 211.044022,254.658401 212.280624,253.902557 
	C219.620346,249.416489 224.773758,243.043854 224.864929,234.396408 
	C225.198654,202.747269 224.981186,171.092484 225.019165,139.439880 
	C225.023224,136.059235 223.870087,134.030685 220.190430,133.996399 
	C216.446106,133.961517 214.210159,135.538971 214.021439,139.452972 
	C213.965347,140.616608 214.000320,141.784851 214.000290,142.950974 
	C213.999481,171.105179 214.004959,199.259369 213.996994,227.413574 
	C213.993576,239.540619 207.481903,245.996429 195.253998,245.997482 
	C140.444931,246.002182 85.635864,246.001984 30.826801,245.997711 
	C18.341112,245.996750 12.002631,239.596909 12.001873,226.998489 
	C11.998586,172.356018 11.998728,117.713539 12.001736,63.071068 
	C12.002440,50.249458 18.312544,44.002369 31.258257,44.001499 
	C73.739449,43.998650 116.220665,44.018173 158.701736,43.944866 
	C160.730850,43.941368 163.388382,43.761738 164.619080,42.530476 
	C165.993027,41.155865 167.238129,37.555481 166.460907,36.580227 
	C165.007095,34.755997 162.057617,33.155258 159.722916,33.144913 
	C115.576660,32.949268 71.429047,33.083275 27.282387,32.939960 
	C16.635241,32.905403 9.015485,37.338860 3.809319,46.451279 
	C3.212935,47.495136 1.952525,48.159615 1.000000,49.000000 
z"
      />
      <path
        fill="#1eb1d8"
        opacity="1.000000"
        stroke="none"
        name="line"
        d="
M1.000000,49.468658 
	C1.952525,48.159615 3.212935,47.495136 3.809319,46.451279 
	C9.015485,37.338860 16.635241,32.905403 27.282387,32.939960 
	C71.429047,33.083275 115.576660,32.949268 159.722916,33.144913 
	C162.057617,33.155258 165.007095,34.755997 166.460907,36.580227 
	C167.238129,37.555481 165.993027,41.155865 164.619080,42.530476 
	C163.388382,43.761738 160.730850,43.941368 158.701736,43.944866 
	C116.220665,44.018173 73.739449,43.998650 31.258257,44.001499 
	C18.312544,44.002369 12.002440,50.249458 12.001736,63.071068 
	C11.998728,117.713539 11.998586,172.356018 12.001873,226.998489 
	C12.002631,239.596909 18.341112,245.996750 30.826801,245.997711 
	C85.635864,246.001984 140.444931,246.002182 195.253998,245.997482 
	C207.481903,245.996429 213.993576,239.540619 213.996994,227.413574 
	C214.004959,199.259369 213.999481,171.105179 214.000290,142.950974 
	C214.000320,141.784851 213.965347,140.616608 214.021439,139.452972 
	C214.210159,135.538971 216.446106,133.961517 220.190430,133.996399 
	C223.870087,134.030685 225.023224,136.059235 225.019165,139.439880 
	C224.981186,171.092484 225.198654,202.747269 224.864929,234.396408 
	C224.773758,243.043854 219.620346,249.416489 212.280624,253.902557 
	C211.044022,254.658401 209.868073,255.513458 208.832047,256.661316 
	C145.312439,257.000000 81.624878,257.000000 17.636347,256.661072 
	C11.099074,252.411484 5.293649,248.081543 2.241490,241.015594 
	C2.174522,240.860565 1.428993,240.998657 1.000000,241.000000 
	C1.000000,177.312439 1.000000,113.624878 1.000000,49.468658 
z"
      />
      <path
        fill="#1eb1d8"
        opacity="1.000000"
        stroke="none"
        name="line"
        d="
M257.000000,45.545898 
	C255.500992,46.996181 253.783890,47.773796 252.534668,49.020309 
	C215.731216,85.743332 178.974991,122.513680 142.209320,159.274582 
	C135.653992,165.829041 133.320053,165.821686 126.742943,159.243225 
	C112.487015,144.984360 98.229492,130.727081 83.979736,116.462036 
	C80.652077,113.130798 79.884834,108.881912 82.127968,106.424606 
	C84.530258,103.792900 88.951164,104.457458 92.522247,108.035172 
	C106.408829,121.947578 120.284004,135.871384 135.157242,150.787643 
	C136.478378,148.940002 137.430679,147.130966 138.810349,145.748383 
	C174.475449,110.008018 210.234879,74.361519 245.805710,38.527763 
	C249.163773,35.144871 252.147644,34.125282 256.626160,36.172127 
	C257.000000,39.030594 257.000000,42.061188 257.000000,45.545898 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M1.000000,241.471191 
	C1.428993,240.998657 2.174522,240.860565 2.241490,241.015594 
	C5.293649,248.081543 11.099074,252.411484 17.167690,256.661072 
	C11.741570,257.000000 6.483140,257.000000 1.000000,257.000000 
	C1.000000,251.982285 1.000000,246.962341 1.000000,241.471191 
z"
      />
    </svg>
  );
};
