import qs from "qs";

import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { invoicesQuery } from "../apiQueries/invoices";
import { invoicesExtractor } from "../../Utils/DataExtractors/invoicesExtractor";
import { uploadFile } from "../../Utils";
import { toast } from "react-toastify";
import moment from "moment";
import { adminOrdersQuery } from "../apiQueries/orders";

export const getInvoices = (payload) => async (dispatch, getState) => {
  const { invoices: currentInvoices, done } = getState().invoicesReducer;
  if (done) return;
  dispatch({ type: "INVOICES_LOADING" });
  const { user, userRole } = getState().userReducer;
  const { searchInput } = payload;

  const idFilter =
    currentInvoices?.length > 0
      ? `filters[id][$lt]=${
          currentInvoices[currentInvoices?.length - 1]?.id ?? ""
        }&`
      : "";

  const getResellerFilters = () => {
    if (user?.attributes?.profil?.data?.attributes?.reseller?.data?.id) {
      return `&filters[commande][reseller][id][$eq]=${user?.attributes?.profil?.data?.attributes?.reseller?.data?.id}`;
    }
  };
  const getBusinessAffiliateFilters = () => {
    if (user?.attributes?.business_affiliate?.data?.id) {
      return `&filters[commande][business_affiliate][id][$eq]=${user?.attributes?.business_affiliate?.data?.id}`;
    }
  };

  const getMarqueFilters = () => {
    if (user?.attributes?.marque?.data?.id) {
      return `&filters[commande][marque][id][$eq]=${user?.attributes?.marque?.data?.id}`;
    }
  };

  try {
    let userFilters = "";
    if (userRole === "reseller") {
      userFilters = getResellerFilters();
    } else if (userRole === "BusinessBroker") {
      userFilters = getBusinessAffiliateFilters();
    }

    const urlFilters = payload.searchInput
      ? `filters[$or][0][number][$containsi]=${searchInput}&filters[$or][1][commande][packings][refClient][$containsi]=${searchInput}&filters[$or][2][commande][refClient][$containsi]=${searchInput}`
      : "";
    const fetchUrl = `${API_END_POINT}api/factures?${idFilter}${urlFilters}${invoicesQuery()}${userFilters}${getMarqueFilters()}&sort[0]=createdAt:desc`;
    const { data: allInvoices } = await API.get(fetchUrl, {
      params: {
        pagination: { pageSize: 10 },
      },
    });
    if (allInvoices?.meta?.pagination?.total === 0) {
      dispatch({ type: "INVOICE_DONE" });
    }
    const invoices = invoicesExtractor(allInvoices.data);
    dispatch({ type: "INVOICES_FETCHED", payload: invoices });
  } catch (error) {
    throw error;
  }
};

export const deleteInvoice = (payload) => async (dispatch) => {
  dispatch({ type: "INVOICES_LOADING" });
  try {
    await API.delete(`${API_END_POINT}api/factures/${payload.id}`);
    dispatch({ type: "INVOICE_DELETED", payload: [payload.id] });
  } catch (error) {
    throw error;
  }
};

export const deleteInvoices = (payload) => async (dispatch) => {
  try {
    let invoiceIDs = [];
    const { toDeleteInvoices } = payload;
    for (let i = 0; i < toDeleteInvoices.length; i++) {
      await API.delete(
        `${API_END_POINT}api/factures/${[toDeleteInvoices[i]?.id]}`
      );
      invoiceIDs.push(toDeleteInvoices[i]?.id);
    }
    dispatch({ type: "INVOICE_DELETED", payload: invoiceIDs });
  } catch (error) {
    throw error;
  }
};

// COMMENTS

export const getInvoicesComments = (invoice) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COMMENTS_INVOICE_LOADING" });
    const query = qs.stringify({
      populate: ["attachments.file", "user.photo", "facture", "user.marque"],
      sort: ["createdAt:desc"],
      filters: {
        facture: { id: { $eq: invoice } },
      },
    });
    const {
      data: { data: commentData },
    } = await API.get(`${API_END_POINT}api/comments?${query}`);

    dispatch({ type: "FETCH_COMMENTS_INVOICE_SUCCESS", payload: commentData });
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_INVOICE_ERROR", payload: error });
  }
};

export const createInvoiceComment =
  (comment, invoice, user, successCallback, t) => async (dispatch) => {
    dispatch({ type: "CREATE_COMMENTS_INVOICE_LOADING" });

    try {
      const {
        data: { data: commentData },
      } = await API.post(`${API_END_POINT}api/comments?populate=*`, {
        data: {
          text: comment?.text,
          facture: invoice,
          user,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                date: new Date(),
                file: fileId,
                comment: commentData?.id,
              },
            }
          );
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      dispatch({
        type: "CREATE_COMMENTS_INVOICE_SUCCESS",
        payload: {
          ...commentData,
          attributes: { ...commentData?.attributes },
        },
      });
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

// CREATE INVOICE
export const createInvoice = (payload) => async (dispatch) => {
  dispatch({ type: "INVOICES_LOADING" });

  try {
    const { id, resellerID, withOrderReload } = payload;

    const { data: fetchedOrder } = await API.get(
      `api/orders/${id}?${adminOrdersQuery()}`
    );

    const orderSlug = fetchedOrder?.data?.attributes?.slug;
    const invoiceSlug =
      fetchedOrder?.data?.attributes?.invoice?.data?.attributes?.slug;
    const invoiceSlugDate =
      fetchedOrder?.data?.attributes?.invoice?.data?.attributes?.slugDate;

    const { data: createdInvoiceAttach } = await API.post(
      `${API_END_POINT}api/attachments`,
      {
        data: {
          title: `facture_commande_${orderSlug}`,
        },
      }
    );

    const {
      data: { maxInvoiceID },
    } = await API.post(`${API_END_POINT}api/orders/create-order-invoice`, {
      attachmentID: createdInvoiceAttach?.data?.id,
      orderID: id,
      date: new Date(),
      orderSlug,
      invoiceSlug,
      invoiceSlugDate,
    });

    if (fetchedOrder?.data?.attributes?.invoice?.data?.id) {
      // INVOICE ALREADY EXIST
      await API.put(
        `${API_END_POINT}api/invoices/${fetchedOrder?.data?.attributes?.invoice?.data?.id}`,
        {
          data: {
            attachment: createdInvoiceAttach?.data?.id,
            slug: String(invoiceSlug),
            slugDate: moment(invoiceSlugDate)?.utc(true).toDate(),
          },
        }
      );
    } else {
      // INVOICE DONT EXIST

      const res = await API.post(`${API_END_POINT}api/invoices`, {
        data: {
          order: id,
          attachment: createdInvoiceAttach?.data?.id,
          reseller: resellerID,
        },
      });

      await API.put(`${API_END_POINT}api/invoices/${res?.data?.data?.id}`, {
        data: {
          slug: String(+maxInvoiceID + 1),
          // String(res?.data?.data?.id),
          slugDate: moment(res?.data?.data?.attributes?.createdAt)
            ?.utc(true)
            .toDate(),
        },
      });
    }

    dispatch({ type: "INVOICE_CREATED" });
    if (withOrderReload) {
      await dispatch({ type: "ORDER_RELOAD" });
      await dispatch({ type: "ORDERS_RELOAD" });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "INVOICES_ERROR" });
  }
};
