import React from "react";

import UpdateTicketSAVEchanges from "../UpdateTicketSavAdmin/UpdateSavTabContent/UpdateSavContentEchanges";
function NewSAVComment({ isAdmin }) {
  return (
    <UpdateTicketSAVEchanges activeTab={isAdmin ? "Admin notes" : "Comments"} />
  );
}

export default NewSAVComment;
