import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../Common/FormElements/Select/Select";
import { MultiSelect } from "primereact/multiselect";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { contractForm } from "./data";
import UploadButton from "../../Common/FormElements/UploadButton/UploadButton";
import API from "../../../Api/Api";
import { API_END_POINT, IMAGE_END_POINT } from "../../../Api/EndPoints";

const Contract = ({ initialValues, handleFormChange }) => {
  const { t, i18n } = useTranslation("common");
  const [selectOptions, setSelectOptions] = useState({});
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData({ ...formData, [name]: file });
  };

  useEffect(() => {
    handleFormChange("contract", formData);
  }, [formData]);

  const getSelectValue = (value, options) => {
    if (["number", "string"].includes(typeof value)) {
      return options.find((option) => option.code === value);
    }
    return value;
  };

  useEffect(() => {
    (async function () {
      try {
        const { data } = await API.get(`${API_END_POINT}api/products`, {
          params: {
            fields: ["id", "name", "name_en", "productType"],
          },
        });

        setSelectOptions({
          products: data?.data
            ?.filter((product) => product?.attributes?.productType === 1)
            .map((product) => ({
              value: product.id,
              label: i18n?.language?.includes("en")
                ? product?.attributes?.name_en
                : product?.attributes?.name,
            })),
          pvls: data?.data
            ?.filter((product) => product?.attributes?.productType !== 1)
            .map((product) => ({
              value: product.id,
              label: i18n?.language?.includes("en")
                ? product?.attributes?.name_en
                : product?.attributes?.name,
            })),
        });
      } catch {}
    })();
  }, [i18n?.language]);

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="modalContent px-5 pt-4 pb-5">
        <div className="row">
          {contractForm.slice(0, 4).map((el) => (
            <>
              <div className="col-6 mt-4" key={el?.name}>
                <p className="f16 text-ultraBlack mb-2">{t(`${el?.label}`)}</p>
                {el.type === "select" ? (
                  <Select
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={getSelectValue(formData[el?.name], el.options ?? [])}
                    options={el.options ?? []}
                    callback={handleChange}
                    appendToSelf
                  />
                ) : el.type === "multiSelect" ? (
                  <MultiSelect
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    options={selectOptions[el.name] ?? el.options ?? []}
                    onChange={handleChange}
                    appendToSelf
                    className="h-40px w-100"
                  />
                ) : ["image", "file"].includes(el?.type) ? (
                  <UploadButton
                    {...el}
                    defaultFilePreview={
                      el.type === "image" && formData[el?.name]?.data
                        ? `
                      ${IMAGE_END_POINT}${
                            formData[el?.name]?.data?.attributes?.url
                          }`
                        : formData[el?.name]
                    }
                    text={`${
                      formData[el?.name]?.name ||
                      formData[el?.name]?.data?.attributes?.name ||
                      t("Add attachments (max. 15 MB)")
                    }`}
                    callback={handleFileChange}
                    className="coverImg mt-2"
                  />
                ) : (
                  <TextInput
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    callback={handleChange}
                  />
                )}
              </div>
              {el.offset > 0 && (
                <div className={`col-${el.offset} mt-4`} key={el?.name}></div>
              )}
            </>
          ))}
          <div className="col-6 mt-4 d-flex flex-column justify-content-between">
            {contractForm.slice(4).map((el) => (
              <div key={el?.name}>
                <p className="f16 text-ultraBlack mb-2">{t(`${el?.label}`)}</p>
                {el.type === "select" ? (
                  <Select
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={getSelectValue(formData[el?.name], el.options ?? [])}
                    options={el.options ?? []}
                    callback={handleChange}
                    appendToSelf
                    filter
                  />
                ) : el.type === "multiSelect" ? (
                  <MultiSelect
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    options={selectOptions[el.name] ?? el.options ?? []}
                    onChange={handleChange}
                    className="h-40px w-100"
                    filter
                    appendTo="self"
                  />
                ) : el.type === "file" ? (
                  <UploadButton
                    {...el}
                    type={"file"}
                    text={`${
                      formData[el?.name]?.name ||
                      formData[el?.name]?.data?.attributes?.name ||
                      t("Add attachments (max. 15 MB)")
                    }`}
                    callback={handleFileChange}
                    className="coverImg mt-2"
                  />
                ) : (
                  <TextInput
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    callback={handleChange}
                  />
                )}
                {el.offset > 0 && (
                  <div className={`col-${el.offset} mt-4`} key={el?.name}></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contract;
