import React from "react";
import RightModal from "../Components/Modals/RightModal/RightModal";
import RevendeurViewContent from "../Components/Revendeur/RevendeurViewContent";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";

const RevendeurView = () => {
  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-4 h-100">
        <RevendeurViewContent />
        <RightModal width={60} />
      </div>
    </ViewScroller>
  );
};

export default RevendeurView;
