import React from "react";
import moment from "moment";
import adjustPrice from "../../Utils/AdjustPrice";
import { useDispatch, useSelector } from "react-redux";
import { getResellerEvents } from "../../REDUX/actions/reseller";
import AnnualDataTable from "./AnnualDataTable";
import { isDefined } from "../../Utils";

const RevendeurEvents = ({ filters }) => {
  const dispatch = useDispatch();
  const { resellerEvents, resellerEventsPages } = useSelector(
    (state) => state.resellerReducer
  );

  const fetchData = (page, year, search) => {
    let appliedFilters = {};
    if (isDefined(filters?.business_affiliate)) {
      appliedFilters.resellers = {
        business_affiliate: {
          id: { $eq: filters.business_affiliate.code },
        },
      };
    }
    if (isDefined(filters?.reseller)) {
      appliedFilters.resellers = {
        id: { $eq: filters.reseller.code },
      };
    }

    if (isDefined(filters?.country)) {
      appliedFilters.resellers = {
        delivery_addresses: {
          country: {
            id: { $eq: filters.country.code },
          },
        },
      };
    }

    dispatch(getResellerEvents(page, year, appliedFilters, search));
  };

  const years = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
  ];

  const headers = [
    "Stores",
    "Name",
    "Date",
    "Budget",
    "Nber Spas",
    "Budget/Spa",
  ];

  const columns = [
    {
      name: "reseller",
      render: (row) =>
        row?.attributes?.resellers?.data?.length > 0 ? (
          <div className="d-flex flex-column">
            {row?.attributes?.resellers?.data?.map((reseller) => (
              <span key={reseller?.id}>{reseller?.attributes?.name}</span>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      name: "title",
      render: (row) => row?.attributes?.title ?? "-",
    },
    {
      name: "date",
      render: (row) =>
        `${moment(row?.attributes?.startDate).format("DD/MM")} au ${moment(
          row?.attributes?.endDate
        ).format("DD/MM")}`,
    },
    {
      name: "budget",
      render: (row) =>
        row?.attributes?.budget
          ? `${adjustPrice(row?.attributes?.budget?.toString())} €`
          : "-",
    },
    {
      name: "spa_sold",
      render: (row) => row?.attributes?.spa_sold ?? "-",
    },
    {
      name: "budget/spa",
      render: (row) => {
        const value =
          row?.attributes?.budget && row?.attributes?.spa_sold
            ? row?.attributes.budget / row?.attributes?.spa_sold
            : 0;
        return value ? `${value} €` : "-";
      },
    },
  ];

  return (
    <AnnualDataTable
      headers={headers}
      columns={columns}
      fetchData={fetchData}
      data={resellerEvents}
      totalPages={resellerEventsPages}
      title="Events / Campagn Ads"
      years={years}
      noResultsMessage="No results found"
      filters={filters}
    />
  );
};

export default RevendeurEvents;
