import moment from "moment";
import { getProductType } from "../../../Utils";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";

export const columns = (t, language) => ({
  1: [
    {
      name: "arrivalDate",
      render: (row) =>
        row?.approvisionnement?.arrivalDate
          ? moment(row.approvisionnement.arrivalDate).format("DD/MM/YYYY")
          : "-",
    },
    {
      name: "estimatedDate",
      render: (row) => {
        if (["pre-ordered", "in-supply"].includes(row?.status)) {
          return row?.approvisionnement?.estimatedDate
            ? moment(row.approvisionnement.estimatedDate).format("DD/MM/YYYY")
            : "-";
        }
        return "";
      },
    },
    {
      name: "category",
      render: (row) => t(`${row.model?.category?.name ?? "-"}`),
    },
    {
      name: "name",
      render: (row) =>
        language?.includes("en")
          ? row.model?.name_en ?? "-"
          : row.model?.name ?? "-",
    },
    {
      name: "gamme",
      render: (row) => row.model?.gamme?.name ?? "-",
    },
    {
      name: "coloring",
      render: (row) =>
        row.cuve && row.panel
          ? [row.cuve, row.panel].join(" / ")
          : row.cuve ?? row.panel ?? "-",
    },
    {
      name: "serialNumber",
      render: (row) => row.serialNumber || "-",
    },
    {
      name: "containerName",
      render: (row) => row.approvisionnement?.containerName ?? "-",
    },
    {
      name: "entrepot",
      render: (row) => row.entrepot?.name ?? "-",
    },
    {
      name: "status",
      render: (row, status) => {
        if (!status) return null;
        if (status?.label === "Reserved") {
          const title = `${t("Reserved to")} : ${
            row?.reservation?.resellerName
          }`;
          return (
            <CustomTooltip title={title}>
              <span
                className="p-2 br10 text-white alignC text-nowrap"
                style={{ backgroundColor: status?.color }}
              >
                {t(`${status?.label}`)}
              </span>
            </CustomTooltip>
          );
        }
        return (
          <span
            className="p-2 br10 text-white alignC text-nowrap"
            style={{ backgroundColor: status?.color }}
          >
            {t(`${status?.label}`)}
          </span>
        );
      },
    },
  ],
  2: [
    {
      name: "arrivalDate",
      render: (row) =>
        row?.approvisionnement?.arrivalDate
          ? moment(row.approvisionnement.arrivalDate).format("DD/MM/YYYY")
          : "-",
    },
    {
      name: "estimatedDate",
      render: (row) => {
        if (["pre-ordered", "in-supply"].includes(row?.status)) {
          return row?.approvisionnement?.estimatedDate
            ? moment(row.approvisionnement.estimatedDate).format("DD/MM/YYYY")
            : "-";
        }
        return "";
      },
    },
    {
      name: "type",
      render: (row) => getProductType(row.productType ?? "-", t),
    },
    {
      name: "reference",
      render: (row) => row.reference,
    },
    {
      name: "model",
      render: (row) => row.model?.name ?? "-",
    },
    {
      name: "coloring",
      render: (row) =>
        row.cuve && row.panel
          ? [row.cuve, row.panel].join(" / ")
          : row.cuve ?? row.panel ?? "-",
    },
    {
      name: "quantity",
      render: (row) => row.quantity || 1,
    },
    {
      name: "entrepot",
      render: (row) => row.entrepot?.name ?? "-",
    },
    {
      name: "containerName",
      render: (row) => row.approvisionnement?.containerName ?? "-",
    },
    {
      name: "status",
      render: (_, status) => (
        <span
          className="p-2 br10 text-white alignC text-nowrap"
          style={{ backgroundColor: status.color }}
        >
          {t(`${status.label}`)}
        </span>
      ),
    },
  ],
});
