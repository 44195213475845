import React, { useState } from "react";
import Select from "../../Common/FormElements/Select/Select";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import ListDeleteIcon from "../../Common/DeleteIcon/ListDeleteIcon";
import { v4 as uuidv4 } from "uuid";

import {
  CuveOptionsApp,
  JupeOptionsApp,
} from "../../../helpers/formLabels/cuveJupeOptions";
import "./PackingList.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatFloat, isDefined } from "../../../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";
import { useTranslation } from "react-i18next";

function PackingListItem({
  packingItemData,
  taux,
  totalTransport,
  handleItemChange,
  handleDeleteItem,
  formData,
  setFormData,
}) {
  const { t } = useTranslation("common");
  const { models, informations } = useSelector((state) => state.modelReducer);
  const { approv } = useSelector((state) => state.approvReducer);

  const [itemFormData, setItemFormData] = useState(packingItemData);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setItemFormData({ ...itemFormData, [name]: value });
  };

  useEffect(() => {
    handleItemChange(packingItemData?.id, itemFormData);
  }, [itemFormData]);

  useEffect(() => {
    const currModel = informations?.find(
      (item) => +item?.id === +itemFormData?.model?.code
    )?.attributes;

    setItemFormData((formData) => ({
      ...formData,
      buyingPrice: currModel?.buyingPrice ?? 0,
      reference: currModel?.reference ?? "",
    }));
  }, [itemFormData?.model?.code, informations]);

  useEffect(() => {
    setItemFormData((formData) => ({
      ...formData,
      buyingCost: approv?.packingList?.find(
        (approvPacking) => +approvPacking?.id === +itemFormData?.id
      )?.buyingCost
        ? approv?.packingList?.find(
            (approvPacking) => +approvPacking?.id === +itemFormData?.id
          )?.buyingCost
        : (parseFloat(formData?.qc ?? 0) + parseFloat(formData?.buyingPrice)) *
          (parseFloat(taux) || 0),
    }));
  }, [itemFormData?.qc, itemFormData?.buyingPrice, taux]);

  useEffect(() => {
    if (isDefined(totalTransport) && isDefined(itemFormData)) {
      const currentProduct = informations?.find(
        (el) => +el?.id === +itemFormData?.model?.code
      );
      setItemFormData((formData) => ({
        ...formData,
        transport:
          parseFloat(totalTransport) /
            parseFloat(currentProduct?.attributes?.multipleContainer) || 0,
        isProduct: currentProduct?.attributes?.productType === 1,
      }));
    }
  }, [totalTransport, itemFormData?.buyingCost]);

  useEffect(() => {
    setItemFormData((formData) => ({
      ...formData,
      deliveredCost: parseFloat(formData?.buyingCost) || 0,
    }));
  }, [itemFormData?.buyingCost, itemFormData?.transport]);

  // useEffect(() => {
  //   if (itemFormData?.quantity > 1) {
  //     for (let i = 0; i < itemFormData?.quantity; i++) {
  //       setFormData((formData) => {
  //         let updatedFormData = formData?.filter(
  //           (item) => item?.id !== itemFormData?.id,
  //         );
  //         for (let i = 0; i < itemFormData?.quantity; i++) {
  //           updatedFormData.push({ ...itemFormData, quantity: 1, hide: true });
  //         }
  //         return updatedFormData;
  //       });
  //     }
  //   }
  // }, [itemFormData?.quantity, setFormData]);

  const handleAddItemDuplicated = ({
    packingId,
    packingModelName,
    packingModelProductType,
    packingModelId,
    packingReference,
    packingCuve,
    packingPanel,
  }) => {
    setFormData([
      ...formData,
      {
        id: uuidv4(),
        newPacking: true,
        model: {
          name: packingModelName,
          code: packingModelId,
          productType: packingModelProductType,
          retailerPriceHT: itemFormData?.model?.retailerPriceHT,
          publicPriceTTC: itemFormData?.model?.publicPriceTTC,
        },
        reference: packingReference,
        cuve: packingCuve
          ? { code: packingCuve, name: t(`${packingCuve}`) }
          : null,
        panel: packingPanel
          ? { code: packingPanel, name: t(`${packingPanel}`) }
          : null,
      },
    ]);
  };

  if (itemFormData?.hide) return null;

  return (
    <div className="packingListItem alignH py-3 ps-2 justify-content-between w100 gap-2">
      {!itemFormData?.newPacking &&
      (itemFormData?.isProduct || itemFormData?.model?.productType === 1) ? (
        <CustomTooltip title={t("Duplicate")}>
          <FontAwesomeIcon
            icon={faCopy}
            size={"lg"}
            color="lightBlue"
            className="pointer"
            onClick={() => {
              handleAddItemDuplicated({
                packingId: itemFormData?.id,
                packingModelName: itemFormData?.model?.name,
                packingModelProductType: itemFormData?.model?.productType,

                packingModelId: itemFormData?.model?.code,
                packingReference: itemFormData?.reference,
                packingCuve: itemFormData?.cuve,
                packingPanel: itemFormData?.panel,
              });
            }}
          />
        </CustomTooltip>
      ) : (
        <div className="ms-3 ps-1"></div>
      )}

      <div className="w10">
        <Select
          filter
          appendToSelf
          placeholder={t("Model")}
          options={models}
          name="model"
          callback={handleChange}
          value={itemFormData?.model}
        />
      </div>
      <div className="w10">
        <TextInput
          placeholder={t("Reference")}
          name="reference"
          callback={handleChange}
          value={itemFormData?.reference ?? ""}
          disabled
        />
      </div>
      <div className="w5">
        <TextInput
          type="number"
          name="quantity"
          min="1"
          callback={handleChange}
          value={itemFormData?.quantity ?? 1}
          disabled={
            itemFormData?.isProduct ||
            itemFormData?.model?.productType === 1 ||
            !isDefined(itemFormData?.model?.code)
          }
        />
      </div>
      <div className="w10">
        {(itemFormData?.isProduct ||
          itemFormData?.model?.productType === 1) && (
          <Select
            className="cuveDropdown"
            options={CuveOptionsApp?.map(({ name, code }) => ({
              code,
              name: t(`${name}`),
            }))}
            name="cuve"
            callback={handleChange}
            value={itemFormData?.cuve}
            // defaultValue={itemFormData?.cuve?.code}
            disabled={
              !(
                itemFormData?.isProduct ||
                itemFormData?.model?.productType === 1
              )
            }
            placeholder={t(`${itemFormData?.cuve}`) ?? t("Choose")}
          />
        )}
      </div>
      <div className="w10">
        {(itemFormData?.isProduct ||
          itemFormData?.model?.productType === 1) && (
          <Select
            className="cuveDropdown"
            options={JupeOptionsApp.map(({ name, code }) => ({
              code,
              name: t(`${name}`),
            }))}
            name="panel"
            callback={handleChange}
            value={itemFormData?.panel}
            // value={itemFormData?.panel?.code}
            disabled={
              !(
                itemFormData?.isProduct ||
                itemFormData?.model?.productType === 1
              )
            }
            defaultValue={itemFormData?.panel}
            placeholder={t(`${itemFormData?.panel}`) ?? t("Choose")}
          />
        )}
      </div>
      <div className="w10">
        {(itemFormData?.isProduct ||
          itemFormData?.model?.productType === 1) && (
          <TextInput
            placeholder={t("Serial number")}
            name="serialNumber"
            callback={handleChange}
            value={itemFormData?.serialNumber}
          />
        )}
      </div>
      <div className="w10">
        <TextInput
          placeholder={t("Purchase Price")}
          name="buyingPrice"
          callback={handleChange}
          value={formatFloat(
            itemFormData?.buyingPrice * (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <div className="w5">
        <TextInput
          placeholder={"QC"}
          name="qc"
          type="number"
          callback={handleChange}
          value={itemFormData?.qc}
        />
      </div>
      <div className="w10">
        <TextInput
          placeholder={t("Coût d'achat")}
          name="buyingCost"
          type="number"
          callback={handleChange}
          value={formatFloat(
            itemFormData?.buyingCost * (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <div className="w5">
        <TextInput
          placeholder={t("Transport")}
          name="transport"
          callback={handleChange}
          value={formatFloat(
            itemFormData?.transport * (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <div className="w10">
        <TextInput
          placeholder={t("Delivered cost")}
          name="deliveredCost"
          type="number"
          callback={handleChange}
          value={formatFloat(
            (itemFormData?.deliveredCost + itemFormData?.transport) *
              (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <span className="w5">
        <ListDeleteIcon
          callback={() =>
            handleDeleteItem(packingItemData?.id, packingItemData?.model?.code)
          }
          withoutMargin={true}
        />
      </span>
    </div>
  );
}

export default PackingListItem;
