import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { TicketProblemPicker } from "../NewTicketSav/TicketProblemPicker";
import TicketStatusTag from "../NewTicketSav/TicketStatusTag";
import { handleValue } from "../../../Utils";
import {
  openRightModal,
  closeRightModal,
} from "../../../REDUX/actions/rightModal";
import NewRevendeur from "../../Revendeur/NewRevendeur/NewRevendeur";
import ModifyOrder from "../../Commandes/ModifyOrder/ModifyOrder";
import UpdateProductAdmin from "../../Produits/UpdateProductAdmin";
import { handleShowOrderCard } from "../../../REDUX/actions/orders";
import { useTranslation } from "react-i18next";

function SavPreviewInfos() {
  const { t, i18n } = useTranslation("common");
  const { ticketSAV } = useSelector((state) => state.ticketSAVReducer);

  const { user } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    // <LoaderWrapper loading={ticketSAVLoading} error={ticketSAVError}>
    <div className="savPreviewInfo mx-5">
      <div className="d-flex justify-content-between mb-4">
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">{t("Reseller")}</p>
          <p
            className={`f16 text-ultraBlack mb-2 ${
              user?.attributes?.userRole !== 0 && "pointer"
            }`}
            onClick={() => {
              user?.attributes?.userRole !== 0 &&
                dispatch(
                  openRightModal(
                    <NewRevendeur
                      id={ticketSAV?.reseller?.id}
                      savID={ticketSAV?.id}
                    />
                  )
                );
            }}
          >
            {handleValue(ticketSAV?.reseller?.attributes?.name)}
          </p>
        </div>
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">{t("Order")}</p>
          <p
            className="f16 text-ultraBlack mb-2 pointer"
            onClick={() => {
              if (user?.attributes?.userRole === 1) {
                dispatch(
                  openRightModal(
                    <ModifyOrder
                      id={ticketSAV?.commande?.id}
                      savID={ticketSAV?.id}
                    />
                  )
                );
              } else {
                dispatch(closeRightModal());
                navigate(`/commandes`);

                dispatch(
                  handleShowOrderCard(handleValue(ticketSAV?.commande?.id))
                );
              }
            }}
          >
            {`
            #${handleValue(ticketSAV?.commande?.attributes?.number)} ${
              ticketSAV?.commande?.attributes?.oldId
                ? `/${ticketSAV?.commande?.attributes?.oldId}`
                : ""
            }`}
          </p>
        </div>
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">
            {t("Client reference")}
          </p>
          <p className="f16 text-ultraBlack mb-2">
            {handleValue(ticketSAV?.refClient)}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-4">
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">{t("Product")}:</p>
          <p
            className="f16 text-ultraBlack mb-2 pointer"
            onClick={() => {
              user?.attributes?.userRole !== 1
                ? navigate(`/catalogue/produit/${ticketSAV?.product?.id}`)
                : dispatch(
                    openRightModal(
                      <UpdateProductAdmin
                        id={ticketSAV?.product?.id}
                        savID={ticketSAV?.id}
                      />
                    )
                  );
            }}
          >
            {handleValue(
              i18n.language === "en"
                ? ticketSAV?.product?.attributes?.name_en ??
                    ticketSAV?.product?.attributes?.name
                : ticketSAV?.product?.attributes?.name
            )}
          </p>
        </div>
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">
            {t("Serial number full")}:
          </p>
          <p className="f16 text-ultraBlack mb-2">
            {handleValue(ticketSAV?.serialNumber)}
          </p>
        </div>
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">{t("Delivery date")}:</p>
          <p className="f16 text-ultraBlack mb-2">
            {handleValue(moment(ticketSAV?.deliveryDate).format("DD-MM-YYYY"))}
          </p>
        </div>
      </div>
      <div className="mb-4 d-flex justify-content-between">
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">
            {t("Warranty expiration date")}:
          </p>
          <div className="d-flex">
            <p className="f16 text-ultraBlack mb-2">
              {handleValue(
                moment(ticketSAV?.warrantyEndDate).format("DD-MM-YYYY")
              )}
            </p>
            <p className="text-lameRed f16 mb-0 ps-2">
              {ticketSAV?.warrantyEndDate &&
              moment(ticketSAV?.warrantyEndDate) < moment()
                ? `(${t("Expired")})`
                : null}
            </p>
          </div>
        </div>
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">{t("Problem")}</p>
          <p className="f16 text-ultraBlack mb-2">
            {handleValue(TicketProblemPicker(ticketSAV?.problem))}
          </p>
        </div>
        <div className="w30">
          <p className="f16 bold text-ultraBlack mb-2">{t("Status")}</p>
          <TicketStatusTag status={ticketSAV?.status} />
        </div>
      </div>
      <div className="w-100">
        <p className="f16 bold text-ultraBlack mb-2">{t("Explanation")}</p>
        <p className="f16 text-ultraBlack mb-2">
          <pre>{ticketSAV?.explanation ?? "-"}</pre>
        </p>
      </div>
    </div>
    // </LoaderWrapper>
  );
}

export default SavPreviewInfos;
