import { useEffect, useRef, useState } from "react";
import AdvancedStockHeader from "./AdvancedStockHeader";
import AdvancedStockTable from "./AdvancedStockTable";
import Statistics from "./Statistics";
import { getStock } from "../../../REDUX/actions/advanced-stock";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OutOfStock from "./OutOfStock";
import { Spinner } from "react-bootstrap";

const AdvancedStock = () => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const observerElement = useRef(null);
  const [page, setPage] = useState(1);
  const { packings, lazyLoading } = useSelector(
    (state) => state.advancedStockReducer
  );
  const [filters, setFilters] = useState({
    locale: i18n.language?.includes("en") ? "en" : "fr",
  });
  const [type, setType] = useState({
    name: t("Product"),
    code: 1,
  });

  const clearStock = () => {
    dispatch({ type: "CLEAR_STOCK" });
    setPage(1);
  };

  useEffect(() => {
    dispatch(getStock({ page, ...filters, type }));
  }, [page, filters, type, dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 0.2 }
    );

    if (observerElement.current) {
      observer.observe(observerElement.current);
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_STOCK" });
    };
  }, [dispatch]);

  return (
    <div>
      <Statistics />
      <AdvancedStockHeader
        filters={filters}
        setFilters={setFilters}
        clearStock={clearStock}
        type={type}
        setType={setType}
      />
      <AdvancedStockTable
        type={type.code}
        data={packings?.data}
        filters={filters}
      />
      {lazyLoading ? (
        <div className="text-center w-100 pt-3 pb-1">
          <Spinner />
        </div>
      ) : (
        packings?.totalPages > packings?.page && (
          <div className="w-100 text-center mt-2">
            <button
              className="text-brightGrey underline bg-transparent border-0 sBold outline-none"
              onClick={() => {
                setPage((prev) => prev + 1);
              }}
            >
              {t("See more")}
            </button>
          </div>
        )
      )}
      {/* <div style={{ width: "100%", height: "1px" }} ref={observerElement} /> */}
      <div className="py-5">
        <OutOfStock />
      </div>
    </div>
  );
};

export default AdvancedStock;
