import React, { useEffect, useState } from "react";
import API from "../../../Api/Api";
import { API_END_POINT } from "../../../Api/EndPoints";
import { useTranslation } from "react-i18next";

const headers = [
  "Name",
  "Category",
  "Range",
  // "SEO Local",
  // "Nber Events",
  // "Budget Ads",
  // "Nber Spas",
  // "Nber Swim Spa",
  // "CA",
  // "Commission",
];

const tableHeaders = (t) => headers.map((header) => t(header));

const columns = (language) => [
  {
    name: "name",
    render: (row) => (language?.includes("en") ? row?.name : row?.name_en),
  },
  {
    name: "category",
    render: (row) => row?.category?.name ?? "-",
  },
  {
    name: "range",
    render: (row) => row?.gamme?.name ?? "-",
  },
  // {
  //   name: "scale",
  //   render: (row) => (!!row?.assistance?.scale ? "Yes" : "No"),
  // },
  // {
  //   name: "Events",
  //   render: (row) => row?.eventCount ?? 0,
  // },
  // {
  //   name: "Budget Ads",
  //   render: (row) => formatCurrency(row?.budgetAds) ?? "-",
  // },
  // {
  //   name: "spa_sold",
  //   render: (row) => row?.spaCount ?? 0,
  // },
  // {
  //   name: "swim_spa_sold",
  //   render: (row) => row?.swimSpaCount ?? 0,
  // },
  // {
  //   name: "ca",
  //   render: (row) => formatCurrency(row.ca),
  // },
  // {
  //   name: "commission",
  //   render: (row) => formatCurrency(row.comission),
  // },
];

const OutOfStock = () => {
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState([]);
  useEffect(() => {
    (async function () {
      try {
        const { data } = await API.get(
          `${API_END_POINT}api/products-out-of-stock`
        );

        setData(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <div className="overflow-x-auto mb-4">
      <span className="text-blue bold f22 quickSand mb-2 d-inline-block">
        {t("Products out of stock")}
      </span>
      <table className="table table-transparent">
        <thead className="table-dimGrey text-nowrap">
          <tr className="border-none">
            {tableHeaders(t, headers).map((header, index) => (
              <th
                key={index}
                scope="col"
                className="py-3 px-4 text-lightBlack sBold f14"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={row.id || rowIndex} className="align-middle table-row">
                {columns(i18n.language).map((column) => (
                  <td
                    key={column.name}
                    className="py-3 px-4 f14 text-lightBlack"
                  >
                    {column.render({ ...row, index: rowIndex })}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="py-3 px-4 f14 text-lightBlack"
              >
                {t("No results found")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OutOfStock;
