import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventAndNewCard from "./EventAndNewCard";
import Button from "../../Common/FormElements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import NewEvent from "../../Calendar/Modals/Evenement/NewEvent";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import { getEvents } from "../../../REDUX/actions/events";
import { useTranslation } from "react-i18next";

const EventsAndNews = () => {
  const { t } = useTranslation("common");
  // consts:
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  // get all events
  useEffect(() => {
    dispatch(getEvents());
  }, []);
  const { events } = useSelector((state) => state.eventsReducer);
  // filter the events with type event
  const eventsAndNewsList = events?.data
    ?.filter((event) => event?.attributes?.eventType !== "salon")
    .slice(0, 8);

  /* ******************************** Rendering ******************************* */
  return (
    <div className="px-4 pt-4 pb-3 w-100 h-100 bg-white br5 shadow14">
      <div className="alignH justify-content-between">
        <span className="quickSand f22 bold text-blue">
          {t("Events & News")}
        </span>
        {[1, 2, 0]?.includes(user?.attributes?.userRole) && (
          <Button
            Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
            onClick={() => dispatch(openRightModal(<NewEvent />))}
          >
            {t("Add an Event")}
          </Button>
        )}
      </div>
      <div className="alignH mt-3 w-100 overflow-auto">
        {eventsAndNewsList?.map((event) => (
          <EventAndNewCard
            key={event?.id}
            {...event.attributes}
            id={event?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default EventsAndNews;
