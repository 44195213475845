import React, { useState } from "react";
import Button from "../Common/FormElements/Button/Button";
import { useTranslation } from "react-i18next";
import UploadButton from "../Common/FormElements/UploadButton/UploadButton";
import API from "../../Api/Api";
import { closeCenteredModal } from "../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";
import TextInput from "../Common/FormElements/TextInput/TextInput";

const RevendeurAddInstallation = ({ resellerId, addInstallation }) => {
  const dispatch = useDispatch();
  const [media, setMedia] = useState();
  const [refClient, setRefClient] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("files", media);

    try {
      const response = await API.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          fields: ["id", "name", "mime", "url"],
        },
      });
      const { data } = await API.post(
        `/api/installations`,
        {
          data: {
            refClient,
            media: response?.data[0]?.id,
            reseller: resellerId,
          },
        },
        {
          params: {
            fields: ["id", "refClient"],
            populate: {
              media: { fields: ["id", "name", "mime", "url"] },
            },
            sort: ["createdAt:desc"],
          },
        }
      );

      addInstallation(data?.data);
      dispatch(closeCenteredModal());
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="image-modal min-w-600px">
      <p className="text-blue bold f22 quickSand">{t("Add Installation")}</p>
      <div className="d-flex justify-content-start flex-column gap-3">
        <div className="w-100">
          <p className="f16 text-ultraBlack mb-2">{t("Ref Client")}</p>
          <TextInput
            className={"w100"}
            type="text"
            name="refClient"
            value={refClient}
            callback={(e) => setRefClient(e.target.value)}
          />
        </div>
        <div className="w-100">
          <p className="f16 text-ultraBlack mb-2">{t("Image")}</p>
          <UploadButton
            id="media"
            name="media"
            type="image"
            defaultFilePreview={media}
            text={media?.name || t("Add installation (max. 15 MB)")}
            callback={handleChange}
            className="h-100 w-100 d-flex max-w-500px max-h-400px"
          />
        </div>
      </div>
      <div className="mt-5 alignC">
        <Button
          loading={loading}
          disabled={!media || !refClient?.trim()?.length > 0}
          onClick={handleSubmit}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default RevendeurAddInstallation;
