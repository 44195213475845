import { useEffect, useState } from "react";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

const RevendeurFiles = ({ contract }) => {
  const { t, i18n } = useTranslation("common");

  const language = i18n?.language;
  const map = contract?.data?.attributes?.area?.data?.attributes;
  const file = contract?.data?.attributes?.file?.data?.attributes;
  const warranty = contract?.data?.attributes?.warranty?.data?.attributes;

  const products = contract?.data?.attributes?.products?.data;
  const pvls = contract?.data?.attributes?.pvls?.data;

  const handleDownload = async (fileUrl, name) => {
    saveAs(`${IMAGE_END_POINT}${fileUrl}`, name);
  };

  return (
    <div className="py-4">
      <span className="text-blue bold f22 quickSand">
        {t("Map of the catchment area of the contract")}
      </span>
      <div className="w-100 d-flex mt-3 align-items-start">
        {map && (
          <div className="w-75 br10 overflow-hidden me-3">
            <img
              src={`${IMAGE_END_POINT}${map?.url}`}
              alt={map?.name}
              className="ob-cover h-100 w-100"
            />
          </div>
        )}
        <div className="w-25 d-flex flex-column gap-3 h-100">
          <div className="d-flex flex-column gap-3">
            {file && (
              <button
                className="p-3 br10 bg-blue-500 border-0 text-start bold text-white f18 alignH justify-content-between"
                onClick={() => handleDownload(file?.url, file?.name)}
              >
                {t("Partner Contract")}
                <i class="bi bi-cloud-arrow-down f24"></i>
              </button>
            )}
            {warranty && (
              <button
                className="p-3 br10 bg-blue-25 border-0 text-start bold text-white f18 alignH justify-content-between"
                onClick={() => handleDownload(warranty?.url, warranty?.name)}
              >
                {t("Product warranty")}
                <i class="bi bi-cloud-arrow-down f24"></i>
              </button>
            )}
          </div>

          <div className="d-flex gap-3">
            {products?.length > 0 && (
              <div className="p-3 br10 bg-bronze-300 text-white w-50">
                <span className="f18 bold">{t("Expo Products")}</span>
                <div
                  className="mt-2 d-flex flex-column gap-2 overflow-auto h-100 no-scrollbar"
                  style={{
                    maxHeight: "400px",
                  }}
                >
                  {products?.map((product) => {
                    return (
                      <span key={product}>
                        {language?.includes("en")
                          ? product?.attributes?.name_en ??
                            product?.attributes?.name
                          : product?.attributes?.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            {pvls?.length > 0 && (
              <div className="p-3 br10 bg-gray-500 text-white w-50">
                <span className="f18 bold">{t("Expo PVL")}</span>
                <div
                  className="mt-2 d-flex flex-column gap-2 overflow-auto h-100 no-scrollbar"
                  style={{
                    maxHeight: "400px",
                  }}
                >
                  {pvls?.map((product) => {
                    return (
                      <span key={product}>
                        {language?.includes("en")
                          ? product?.attributes?.name_en ??
                            product?.attributes?.name
                          : product?.attributes?.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevendeurFiles;
