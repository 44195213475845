import React from "react";
import { useTranslation } from "react-i18next";

const CalendarFooter = () => {
  const { t } = useTranslation("common");
  const calendarFooterItems = [
    { id: 1, label: t("Expo and Fair"), color: "bg-softPink" },
    { id: 2, label: t("Open House"), color: "bg-iceberg" },
    { id: 3, label: t("Google Ads"), color: "bg-treebrown" },
    { id: 4, label: t("Meta Ads"), color: "bg-papaya" },
    { id: 5, label: t("Flyer Campaign"), color: "bg-snowGreen" },
    { id: 6, label: t("Radio Campaign"), color: "bg-pink" },
    { id: 6, label: t("Radio Campaign"), color: "bg-blueSky" },
  ];
  return (
    <div>
      <hr style={{ borderColor: "#abb8b2" }} className="mt-0" width={"100%"} />
      <div className="alignH flex-wrap gap-3 ">
        {calendarFooterItems?.map(({ id, label, color }) => (
          <div className="alignH flex-wrap mx-1" key={id}>
            <div className="alignH gap-2">
              <div
                className={`rounded-circle ${color}`}
                style={{ width: "14px", height: "14px" }}
              ></div>
              <span className="text-brightGrey f16 normal">{label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarFooter;
