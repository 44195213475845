import React from "react";
import { formatCurrency } from "../../../Utils";

const LineChartHeader = ({
  colors,
  years,
  // dateActiveTab,
  // setDateActiveTab,
  data,
  unity,
  showData = false,
}) => {
  return (
    <div className="alignH justify-content-between px-5 py-2">
      <div className="alignH gap-4">
        {years?.map((year, index) => {
          const total =
            data && data[year]?.reduce((acc, curr) => acc + curr, 0);
          return (
            <div className="d-flex">
              <div className="alignH gap-2">
                <span
                  className={`br50 bg-${colors[index]}`}
                  style={{ width: "11px", height: "11px" }}
                ></span>
                <span className="br50 text-lightBlack sBold f12">{year}</span>
              </div>
              {showData && (
                <div className="alignH gap-2 ms-1">
                  <span className="br50 text-lightBlack sBold f12">
                    :{" "}
                    {total > 0
                      ? unity
                        ? unity === "%"
                          ? total.toFixed(2) + "%"
                          : formatCurrency(total)
                        : total
                      : 0}
                    {/* {unity} */}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Filters month/year */}
      {/* <TabsNavbar
        tabs={["Year"]}
        activeTab={dateActiveTab}
        setActiveTab={setDateActiveTab}
        className="justify-content-between py-2  gap-3"
        tabClassName="f14 normal"
        bottom="-7px"
      /> */}
    </div>
  );
};

export default LineChartHeader;
