import React, { useEffect, useState } from "react";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import "./CatalogueCard.css";
import adjustPrice from "../../Utils/AdjustPrice";

import CatalogueItemCard from "./CatalogueItemCard";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import { addServPackingToCart } from "../../REDUX/actions/approvisionnement";
import Button from "../Common/FormElements/Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { updateCart } from "../../REDUX/actions/catalogue";

const tabsMap = {
  Products: "Produits",
  Accessories: "Accessoires",
  "Marketing Products": "Produits marketing",
  "Spare Part": "Pièce Détachée",
  Services: "Services",
  "Other branded products": "Produits d'autres marques",
  SPA: "SPA",
  "SWIM SPA": "SPA DE NAGE",
  SAUNA: "SAUNA",
};

const CatalogCard = ({
  product,
  activeTab,
  searchInput,
  productsactiveTab,
}) => {
  const { t, i18n } = useTranslation("common");
  const [servicePriceHT, setservicePriceHT] = useState(0);
  const [servicePriceTTC, setservicePriceTTC] = useState(0);
  const { cart } = useSelector((state) => state.catalogueReducer);

  const dispatch = useDispatch();

  const { language } = i18n;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/catalogue/produit/${product.id}`);
  };

  const addServiceToCart = () => {
    dispatch(
      addServPackingToCart(
        product?.id,
        {
          customRetailerPriceHT: servicePriceHT,
          customPublicPriceTTC: servicePriceTTC,
        },
        {
          activeTab: tabsMap[activeTab],
          searchInput,
          productsactiveTab: tabsMap[productsactiveTab],
        },
        cart?.exchangeRate
      )
    );
  };

  useEffect(() => {
    const initialValueTTC = product?.attributes?.publicPriceTTC
      ? product?.attributes?.publicPriceTTC?.toFixed(2).toString()
      : (product?.attributes?.retailerPriceHT * 1.2).toFixed(2).toString();

    // console.log({
    //   aaaa: adjustPrice(
    //     product?.attributes?.retailerPriceHT?.toFixed(2).toString()
    //   ),
    // });
    const initialValueHT = product?.attributes?.retailerPriceHT
      ? product?.attributes?.retailerPriceHT?.toFixed(2).toString()
      : 0;

    setservicePriceHT(initialValueHT);
    setservicePriceTTC(initialValueTTC);
  }, [product.attributes.retailerPriceHT, product.attributes.publicPriceTTC]);

  const image = product?.attributes?.attachments?.find((item) => {
    return item?.file?.mime.includes("image");
  })?.file?.url;

  return (
    <div
      className="newCatalogueCardContainer p-4 border"
      onClick={handleNavigate}
    >
      <div className="d-flex flex-column alignC w100 gap-2 flex-1 p-2">
        <img
          className="newCatalogueImg"
          src={`${IMAGE_END_POINT}${image}`}
          alt=""
        />

        {product?.attributes?.productType !== 5 &&
          product?.attributes?.productType !== 6 &&
          (product.attributes.totalNumberOfPackings === 0 ? (
            <div
              className={`text-red f14 bold w100 ${
                product.attributes.productType === 5 ||
                product.attributes.productType === 6
                  ? "mb-5"
                  : "mt-5"
              }`}
            >
              {product.attributes.productType !== 5 ||
              product.attributes.productType === 6
                ? t("Out of stock")
                : ""}
            </div>
          ) : (
            <div className="f14 bold w100 d-flex justify-content-between">
              <div className="text-green">
                {product.attributes.totalNumberArrived} {t("In stock")}
              </div>
              <div className="text-brown">
                {product.attributes.totalNumberUnarrived} {t("in supply")}
              </div>
            </div>
          ))}
        <div className="f20 bold quickSand ultraBlack w100">
          {language === "en"
            ? product.attributes.name_en ?? product.attributes.name
            : product.attributes.name}
        </div>
        <div className="f20 bold quickSand ultraBlack w100 mt-2"></div>
        <div className=" w100">
          {product.attributes.productType !== 5 &&
          product.attributes.productType !== 6 ? (
            <p className="f16 bold text-softBlack mb-0">
              {t("Price")} :{" "}
              {product.attributes.retailerPriceHT
                ? adjustPrice(
                    //   cart?.majorAccountOrder &&
                    // cart?.currency === "Dollar" &&
                    // cart?.exchangeRate
                    //   ? product.attributes.retailerPriceHT / cart?.exchangeRate :
                    product?.attributes?.retailerPriceHT?.toFixed(2).toString()
                  )
                : "0.00"}
              {/* {cart?.majorAccountOrder && cart?.currency === "Dollar"
                ? "$" :*/}
              € {t("Excl. Tax")}
            </p>
          ) : (
            <div className="d-flex flex-column gap-3">
              <div
                className="f16 bold text-softBlack mb-0 d-flex align-items-center "
                onClick={(e) => e.stopPropagation()}
              >
                <span className="col-3">{t("Price excl. tax")} : </span>
                <TextInput
                  name="value"
                  className={"col-9"}
                  // deviseType={isParticulier ? "TTC" : "HT"}
                  value={
                    // cart?.majorAccountOrder &&
                    // cart?.currency === "Dollar" &&
                    // cart?.exchangeRate
                    //   ? servicePriceHT / cart?.exchangeRate :

                    servicePriceHT
                  }
                  inputClassName={"f14"}
                  placeholderStyle={"f14"}
                  callback={(e) =>
                    setservicePriceHT(
                      // cart?.majorAccountOrder &&
                      //   cart?.currency === "Dollar" &&
                      //   cart?.exchangeRate
                      //   ? e.target.value * cart?.exchangeRate
                      e.target.value
                    )
                  }
                  type="number"
                  surfixText={
                    // cart?.majorAccountOrder && cart?.currency === "Dollar"
                    product.attributes.productType === 6 ? "$" : "€"
                  }
                />
              </div>
              <div
                className="f16 bold text-softBlack mb-0 d-flex align-items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="col-3">{t("Price incl. tax")} : </span>
                <TextInput
                  name="value"
                  className={"col-9"}
                  // deviseType={"TTC"}
                  value={
                    // cart?.majorAccountOrder &&
                    // cart?.currency === "Dollar" &&
                    // cart?.exchangeRate
                    //   ? servicePriceTTC / cart?.exchangeRate
                    servicePriceTTC
                  }
                  inputClassName={"f14"}
                  placeholderStyle={"f14"}
                  callback={(e) =>
                    setservicePriceTTC(
                      // cart?.majorAccountOrder &&
                      //   cart?.currency === "Dollar" &&
                      //   cart?.exchangeRate
                      //   ? e.target.value * cart?.exchangeRate
                      e.target.value
                    )
                  }
                  type="number"
                  surfixText={
                    // cart?.majorAccountOrder && cart?.currency === "Dollar"
                    //   ? "$"
                    //   : "€"
                    product.attributes.productType === 6 ? "$" : "€"
                  }
                />
              </div>
            </div>
          )}
          {product.attributes.productType !== 5 &&
            product.attributes.productType !== 6 && (
              <p className="f16 bold text-softBlack mb-0">
                {t("Recommended Retail Price")} :{" "}
                {product?.attributes?.publicPriceTTC
                  ? adjustPrice(
                      // (cart?.majorAccountOrder &&
                      // cart?.currency === "Dollar" &&
                      // cart?.exchangeRate
                      //   ? product.attributes.publicPriceTTC / cart?.exchangeRate
                      product?.attributes?.publicPriceTTC
                        // )
                        .toFixed(2)
                        .toString()
                    )
                  : "0.00"}
                {/* {cart?.majorAccountOrder && cart?.currency === "Dollar" */}€
              </p>
            )}
        </div>
        {(product?.attributes?.productType === 5 ||
          product?.attributes?.productType === 6) && (
          <div className="d-flex w100 gap-3 mt-5 justify-content-center">
            <Button
              className="br30 bold flex-1 my-3"
              onClick={(e) => {
                e.stopPropagation();
                addServiceToCart();
              }}
            >
              {t("Add to cart")}
            </Button>
          </div>
        )}
      </div>
      {product?.attributes?.productType !== 5 &&
        product?.attributes?.productType !== 6 && (
          <div className="packingsList d-flex flex-column w100 gap-2 flex-1 p-2 mt-3">
            {product.attributes.groupedPackings.map((packingGroup, index) => (
              <div className="my-3" key={index}>
                <div
                  className={`flex-1 f14 bold ${
                    packingGroup.arrived ? "text-green" : "text-brown"
                  }`}
                >
                  {packingGroup.numberOfProducts}x {packingGroup.cuve}
                  {",   "}
                  {packingGroup.panel}, appro {packingGroup.containerName}{" "}
                  {packingGroup.arrived
                    ? ""
                    : `,${t("Arrives on")} ${
                        packingGroup.arrivalDate ||
                        packingGroup.estimatedArrivalDate
                      }`}
                </div>
                <CatalogueItemCard
                  product={product}
                  packingGroup={packingGroup}
                  activeTab={activeTab}
                  searchInput={searchInput}
                  productsactiveTab={productsactiveTab}
                />
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default CatalogCard;
