import React from "react";
import Button from "../../../Common/FormElements/Button/Button";
import { formatTimeToHHMM } from "../../../../Utils/fileUtils";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EventDetails = ({ event }) => {
  const { t } = useTranslation("common");
  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };

  const attributes = event?.data?.attributes || {};
  const {
    isGlobal,
    startTime,
    endTime,
    startDate,
    endDate,
    title,
    description,
    Image,
    resellers,
    visitLink,
    budget,
    spa_sold,
    swim_spa_sold,
    quotes_count,
    document,
  } = attributes;

  return (
    <div className="px-5">
      {Image?.data?.attributes?.url && (
        <div style={{ height: "250px" }}>
          <img
            src={`${IMAGE_END_POINT}${Image.data.attributes.url}`}
            alt="evenement"
            className="w100 br5 ob-cover h100"
          />
        </div>
      )}
      <h2 className="quickSand rightModalTitle mb-3 mt-5">{title}</h2>
      <p className="f17 text-brightGrey mb-4">
        <pre>{description}</pre>
      </p>
      <div className="row flex-wrap gy-2 gx-4">
        <div className="col-4">
          <p className="f16 mb-1 bold text-softBlack">{t("Start date")}</p>
          <p className="f16 text-softBlack">
            {moment(startDate)?.format("DD-MM-YYYY")}
          </p>
        </div>
        <div className="col-4">
          <p className="f16 mb-1 bold text-softBlack">{t("End date")}</p>
          <p className="f16 text-softBlack">
            {moment(endDate)?.format("DD-MM-YYYY")}
          </p>
        </div>
        {resellers?.data?.length > 0 && (
          <div className="col-4">
            <p className="f16 mb-1 bold text-softBlack">{t("Resellers")}</p>
            <div className="max-h-200px overflow-auto">
              {resellers.data.map((item) => (
                <p className="f16 text-softBlack mb-1" key={item?.id}>
                  {item?.attributes?.name ?? ""}
                </p>
              ))}
            </div>
          </div>
        )}
        {startTime && (
          <div className="col-4">
            <p className="f16 mb-1 bold text-softBlack">{t("Hour")}</p>
            <p className="f16 text-softBlack">
              {formatTimeToHHMM(startTime)}
              {endTime && ` - ${formatTimeToHHMM(endTime)}`}
            </p>
          </div>
        )}

        {!isGlobal && (
          <>
            <div className="col-4">
              <p className="f16 mb-1 bold text-softBlack">{t("Budget")}</p>
              <p className="f16 text-softBlack">{budget ?? 0} €</p>
            </div>
            <div className="col-4">
              <p className="f16 mb-1 bold text-softBlack">
                {t("Number of spas sold")}
              </p>
              <p className="f16 text-softBlack">{spa_sold ?? "0"}</p>
            </div>
            <div className="col-4">
              <p className="f16 mb-1 bold text-softBlack">
                {t("Number of swim spas sold")}
              </p>
              <p className="f16 text-softBlack">{swim_spa_sold ?? "0"}</p>
            </div>
            <div className="col-4">
              <p className="f16 bold mb-1 text-softBlack">
                {t("Number of quotes")}
              </p>
              <p className="f16 text-softBlack">{quotes_count ?? "0"}</p>
            </div>
          </>
        )}
        {isGlobal && document?.data && (
          <div className="col-4">
            <p className="f16 bold mb-1 text-softBlack">{t("Document")}</p>

            <a
              href={`${IMAGE_END_POINT}${document?.data?.attributes?.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {document?.data?.attributes?.name ?? "-"}
            </a>
          </div>
        )}
      </div>
      {visitLink?.length > 0 && (
        <a
          href={getClickableLink(visitLink)}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-3 d-inline-block"
        >
          <Button>{t("Visit the link")}</Button>
        </a>
      )}
    </div>
  );
};

export default EventDetails;
