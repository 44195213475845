import React from "react";

import NewSAVCommande from "../NewTicketSav/NewSAVCommande";
import NewSAVComment from "../NewTicketSav/NewSAVComment";
import UpdateSAVInfos from "./UpdateSavTabContent/UpdateSAVInfos";
import UpdateSAVRevendeur from "./UpdateSavTabContent/UpdateSAVRevendeur";
import SavPreviewAttachments from "../SavPreview/SavPreviewAttachments";
import RevendeurNotFound from "./RevendeurNotFound";

const UpdateTicketSAVContent = ({ activeTab, formData, setFormData }) => {
  const revendeurUser = formData?.users?.find((user) => user.userRole === 0);

  switch (activeTab) {
    case "General information":
      return <UpdateSAVInfos formData={formData} setFormData={setFormData} />;
    case "Reseller":
      return revendeurUser ? (
        <UpdateSAVRevendeur formData={formData} />
      ) : (
        <RevendeurNotFound />
      );
    case "AS Order":
      return <NewSAVCommande />;
    case "Attachments":
      return <SavPreviewAttachments ticketId={formData.id} />;
    case "Client Comments":
      return <NewSAVComment />;
    case "Notes":
      return <NewSAVComment isAdmin />;
    // case t("Summary"):
    //   return <NewSAVSummary />;
    default:
      <UpdateSAVInfos />;
  }
};

export default UpdateTicketSAVContent;
