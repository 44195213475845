import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";
import { Overlay } from "react-bootstrap";
import MediaPreview from "../../../Common/MediaPreview";
import userAvatar from "../../../../Images/Images/avatar.png";
import { useTranslation } from "react-i18next";
import VerticalPoints from "../../../Common/VerticalPoints/VerticalPoints";
import { openCenteredModal } from "../../../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../Common/Modal/ConfirmModal";
import EditMessageInput from "./EditMessageInput";
import { deleteComment } from "../../../../REDUX/actions/comment";
// import { CloseSvg } from "../../../Svgs";

const ACTION_TYPE = {
  ORDER: {
    edit: "UPDATE_ORDER_COMMENT_SUCCESS",
    delete: "DELETE_ORDER_COMMENT_SUCCESS",
  },
  CONTAINER: {
    edit: "UPDATE_CONTAINER_COMMENT_SUCCESS",
    delete: "DELETE_CONTAINER_COMMENT_SUCCESS",
  },
  PROSPECT: {
    edit: "UPDATE_PROSPECT_COMMENT_SUCCESS",
    delete: "DELETE_PROSPECT_COMMENT_SUCCESS",
  },
  SAV: {
    edit: "UPDATE_SAV_COMMENT_SUCCESS",
    delete: "DELETE_SAV_COMMENT_SUCCESS",
  },
  SAV_ADMIN: {
    edit: "UPDATE_ADMIN_SAV_COMMENT_SUCCESS",
    delete: "DELETE_ADMIN_SAV_COMMENT_SUCCESS",
  },
  EXPENSE: {
    edit: "UPDATE_EXPENSE_COMMENT_SUCCESS",
    delete: "DELETE_EXPENSE_COMMENT_SUCCESS",
  },
  INVOICE: {
    edit: "UPDATE_INVOICE_COMMENT_SUCCESS",
    delete: "DELETE_INVOICE_COMMENT_SUCCESS",
  },
};

function MessageItem({ message, index, isOwner, view }) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const ref = useRef();
  const target = useRef(null);
  const [previewMedia, setPreviewMedia] = useState();
  const [showOverlay, setShowOverlay] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const date = moment(message?.attributes?.createdAt).format("HH:mm");

  const user = message?.attributes?.user?.data;
  const photoUrl = user?.attributes?.photo?.data?.attributes?.url;

  let title = "";
  if (user?.attributes?.userRole === 1) {
    title = t("Administrator");
  } else if (user?.attributes?.userRole === 0) {
    title =
      user?.attributes?.profil?.data?.attributes?.reseller?.data?.attributes
        ?.name ?? "";
  } else if (user?.attributes?.userRole === 2) {
    title = user?.attributes?.business_affiliate?.data?.attributes?.name;
  }

  const fullName = [user?.attributes?.firstName, user?.attributes?.lastName]
    .join(" ")
    ?.trim();

  const attachments = message?.attributes?.attachments?.data;
  const hasText = message?.attributes?.text?.length > 0;

  const handleDelete = () => {
    dispatch(
      openCenteredModal(
        <ConfirmModal
          label={t("Comment")}
          id={message?.id}
          onConfirm={() =>
            dispatch(
              deleteComment(
                message?.id,
                {
                  attachments: message?.attributes?.attachments?.data,
                },
                ACTION_TYPE[view].delete
              )
            )
          }
        />
      )
    );
  };

  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     if (index === 0) ref?.current?.scrollIntoView();
  //   }, 0);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [message, index]);

  if (isEditing) {
    return (
      <EditMessageInput
        message={message}
        close={() => setIsEditing(false)}
        actionType={ACTION_TYPE[view].edit}
      />
    );
  }

  return (
    <div
      className={`messageContainer d-flex mt-3 mb-2 ${
        isOwner ? "ms-auto flex-row-reverse" : "me-auto"
      }`}
      ref={ref}
    >
      <img
        ref={target}
        src={photoUrl ? `${IMAGE_END_POINT}${photoUrl}` : userAvatar}
        alt={fullName}
        className={`message-user mt-auto mb-4 ${isOwner ? "ms-3" : "me-3"}`}
        onMouseOver={() => setShowOverlay(true)}
        onMouseOut={() => setShowOverlay(false)}
      />
      <Overlay
        target={target.current}
        show={showOverlay}
        placement="bottom-start"
      >
        {(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: 99999,
              boxShadow: "0px 3px 6px #0000001a",
            }}
            className="d-flex align-items-center p-12px bg-white br5"
          >
            <img
              src={photoUrl ? `${IMAGE_END_POINT}${photoUrl}` : userAvatar}
              alt=""
              width={34}
              height={34}
              className="ob-cover rounded-circle"
            />

            <div className="d-flex flex-column ms-2 ps-1">
              {fullName?.trim()?.length > 0 && (
                <p className="f14 mb-0 semi-bold text-dark">{fullName}</p>
              )}
              <p className="f14 mb-0 text-brightGrey">{title}</p>
            </div>
          </div>
        )}
      </Overlay>
      <div>
        <div
          className={`d-flex flex-column br5 ${
            isOwner ? "ms-auto bg-blue-100" : "bg-gray-200"
          }`}
        >
          <div
            className={`f14 text-blue-900 medium d-flex justify-content-between pe-1 `}
          >
            {message?.attributes?.text?.length > 0 && (
              <div className="py-2 px-3 wordBreak">
                {message?.attributes?.text?.split("\n").map((t) => (
                  <p className="mb-0">{t?.length > 0 ? t : <br />}</p>
                ))}
              </div>
            )}
            {isOwner && (
              <VerticalPoints
                small
                showLeft
                className="mt-2 ms-auto"
                options={[
                  {
                    text: t("Edit"),
                    callback: () => setIsEditing(true),
                  },
                  {
                    text: t("Delete"),
                    callback: handleDelete,
                  },
                ]}
              />
            )}
          </div>
          {attachments?.length > 0 && (
            <div
              className={`d-flex p-3 flex-wrap gap-2 ${
                isOwner ? "justify-content-end" : "justify-content-start"
              }`}
            >
              {attachments?.map((attach) => {
                const url = attach?.attributes?.file?.data?.attributes?.url;
                const fileName =
                  attach?.attributes?.file?.data?.attributes?.name;
                const fileType =
                  attach?.attributes?.file?.data?.attributes?.mime;
                const isImage = fileType?.includes("image");
                const isVideo = fileType?.includes("video");
                return (
                  <div
                    key={attach?.id}
                    className="d-flex align-items-center justify-content-center position-relative parent-hover"
                  >
                    {/* <button
                  className="top-0 end-0 position-absolute border-0 d-flex align-items-center justify-content-center bg-white p-2 child-hover-show rounded size-24px"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete();
                  }}
                >
                  <CloseSvg />
                </button> */}
                    <button
                      className={`outline-none border-0 br5 overflow-hidden w-100px h-100px d-flex align-items-center justify-content-center ${
                        isOwner ? "ms-auto bg-blue-100" : "bg-gray-200"
                      }`}
                      onClick={() =>
                        (isImage || isVideo) &&
                        setPreviewMedia(attach?.attributes?.file?.data)
                      }
                    >
                      {isImage ? (
                        <img
                          src={IMAGE_END_POINT + url}
                          alt={fileName}
                          className="ob-cover br5"
                          width={100}
                          height={100}
                        />
                      ) : isVideo ? (
                        <video width={100} height={100}>
                          <source src={IMAGE_END_POINT + url} type={fileType} />
                        </video>
                      ) : (
                        <a
                          href={`${IMAGE_END_POINT}${url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex align-items-center justify-content-center mb-0 text-blue f14 h-100 w-100 bg-white"
                        >
                          {t("Document")}
                        </a>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between align-items-center gap-5 mt-2">
          <div className="alignC gap-1">
            <p className="mb-0 sBold f11 text-gray-500">{fullName}</p>
            {/* {user?.attributes?.marque?.data?.attributes?.name?.length >
              0 >
              0 && (
              <p className="mb-0 sBold f11 text-blue-600">
                {" "}
                - {user?.attributes?.marque?.data?.attributes?.name}
              </p>
            )} */}
            {user?.attributes?.occupation?.length > 0 && (
              <p className="mb-0 sBold f11 text-blue-600">
                {" "}
                - {user?.attributes?.occupation}
              </p>
            )}
          </div>

          <p className="mb-0 sBold f11 text-gray-500">{date}</p>
        </div>
      </div>
      {previewMedia && (
        <MediaPreview
          file={previewMedia?.attributes?.url}
          setPreviewMedia={setPreviewMedia}
          mediaType={
            previewMedia?.attributes?.mime?.includes("image")
              ? "image"
              : previewMedia?.attributes?.mime?.includes("video")
              ? "video"
              : null
          }
          isUrl
        />
      )}
    </div>
  );
}

export default MessageItem;
