import QueryString from "qs";

export const savProductsQuery = () =>
  QueryString.stringify(
    {
      fields: ["id", "name"],
    },
    { encodeValuesOnly: true }
  );
export const ticketSavsQuery = () =>
  QueryString.stringify(
    {
      sort: ["createdAt:desc"],
      fields: [
        "id",
        "deliveryDate",
        "refClient",
        "status",
        "serialNumber",
        "warrantyEndDate",
        "problem",
        "createdAt",
        "relaunch",
      ],
      populate: {
        intervention: true,
        users_permissions_users: {
          fields: ["id", "firstName", "lastName", "userRole"],
        },
        product: {
          fields: ["id", "name", "name_en"],
        },
        savOrders: {
          fields: ["id"],
        },
        commandes_from_sav: {
          fields: ["id", "createdAt", "status", "number"],
          populate: {
            packings: {
              fields: ["id", "name"],
              populate: {
                model: {
                  fields: ["id", "name"],
                },
              },
            },
          },
        },
        order: {
          fields: ["id", "realRefClient", "oldId", "slug"],
        },
        commande: {
          fields: ["id", "number", "oldId"],
        },
        reseller: {
          fields: ["id", "name"],
          populate: {
            business_affiliate: {
              fields: ["id"],
              populate: {
                users_permissions_users: {
                  fields: ["id", "firstName", "lastName", "userRole"],
                },
              },
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  );

export const ticketSavQuery = () =>
  QueryString.stringify(
    {
      fields: [
        "id",
        "deliveryDate",
        "refClient",
        "status",
        "serialNumber",
        "warrantyEndDate",
        "problem",
        "createdAt",
        "relaunch",
        "explanation",
      ],
      populate: {
        attachments: {
          fields: ["id", "title", "createdAt"],
          populate: {
            file: { fields: "*" },
          },
        },
        product: {
          fields: ["id", "name", "name_en"],
        },
        order: {
          fields: ["id", "realRefClient", "oldId", "slug"],
        },
        commande: {
          fields: ["id", "number", "oldId"],
        },
        savOrders: {
          fields: ["id", "createdAt", "status"],
          populate: {
            panier_items: {
              fields: ["id"],
              populate: {
                product: {
                  fields: ["id", "name", "name_en"],
                },
              },
            },
          },
        },
        commandes_from_sav: {
          fields: ["id", "createdAt", "status", "number"],
          populate: {
            packings: {
              fields: ["id", "name"],
              populate: {
                model: {
                  fields: ["id", "name"],
                },
              },
            },
          },
        },
        reseller: {
          fields: ["id", "name"],
          populate: {
            business_affiliate: {
              fields: ["id"],
              populate: {
                user: {
                  fields: ["id", "firstName", "lastName", "userRole"],
                },
              },
            },
          },
        },
        intervention: {
          fields: "*",
        },
        users_permissions_users: {
          fields: ["id", "firstName", "lastName", "userRole"],
          populate: {
            photo: true,
          },
        },
      },
    },
    { encodeValuesOnly: true }
  );
