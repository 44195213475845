import React from "react";
import { useTranslation } from "react-i18next";

function AvailabilityVerifier({
  availableQuantity,
  replenishmentDate,
  packings,
  fromProductDetails,
  sold,
  totalUnarrived,
  fromPanier,
}) {
  const { t } = useTranslation("common");

  const panierItemCondition =
    totalUnarrived === undefined && availableQuantity !== 0;
  return (
    <>
      <div className="position-relative d-flex justify-content-between align-items-center">
        {availableQuantity !== 0 && (
          <div className="d-flex align-items-center mb-3">
            <p className="text-greenPist mb-0 f14">
              {availableQuantity} {t("In stocky")}
            </p>
          </div>
        )}

        {!fromPanier &&
          (!replenishmentDate && !availableQuantity && sold ? (
            <p className="text-red f14">{t("Out of stock")}</p>
          ) : (
            totalUnarrived !== 0 &&
            !panierItemCondition && (
              <p className="text-brown f14 me-3">
                {`${
                  totalUnarrived ? `${totalUnarrived} ${t("in")}` : t("In")
                } ${t("replenishment")}`}
              </p>
            )
          ))}
      </div>
    </>
  );
}

export default AvailabilityVerifier;
