import React, { useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import LineChartHeader from "./LineChartHeader";
import CustomLineChart from "../../Common/CustomLineChart/CustomLineChart";
import "./Synteses.css";
import { useTranslation } from "react-i18next";

const SyntheseRecettes = ({
  stats,
  tabs = ["Current Revenue", "Net Margin", "Margin Rate"],
}) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [dateActiveTab, setDateActiveTab] = useState(t("Year"));
  const currentYear = new Date().getFullYear();
  let years = [currentYear - 1, currentYear];
  const hasUnity = !["Nber Spa", "Nber Swim Spa"]?.includes(activeTab);

  const defaultStats = {
    2024: Array.from({ length: 12 }).fill(0),
    2025: Array.from({ length: 12 }).fill(0),
  };

  const getCurrentStats = () => {
    switch (activeTab) {
      case "Current Revenue": {
        return stats?.totalProductsHTMap;
      }
      case "Net Margin": {
        return stats?.margeMap;
      }
      case "Margin Rate": {
        return stats?.txMargeMap;
      }
      case "Nber Spa": {
        return stats?.spaMap;
      }
      case "Nber Swim Spa": {
        return stats?.swimSpaMap;
      }
      case "Commission": {
        return stats?.comissionMap;
      }
      default: {
        return {};
      }
    }
  };

  return (
    <div className="syntheseRecettes bg-white br5 shadow26 px-4 py-3 d-flex flex-column gap-3">
      <span className="text-blue bold f22 quickSand">
        {t("Revenue Summary")}
      </span>
      <TabsNavbar
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className="justify-content-between"
        tabClassName="f14 normal"
      />

      <LineChartHeader
        years={years}
        colors={["yellowOrange", "aquaGreen"]}
        setDateActiveTab={setDateActiveTab}
        dateActiveTab={dateActiveTab}
        data={getCurrentStats() || defaultStats}
        unity={hasUnity ? (activeTab === "Margin Rate" ? "%" : "k €") : ""}
        showData
      />
      <CustomLineChart
        years={years}
        colors={["#FCB03E", "#1AD5AF"]}
        data={getCurrentStats() || defaultStats}
        unity={hasUnity ? (activeTab === "Margin Rate" ? "%" : "k") : ""}
        hasUnity={hasUnity}
      />
    </div>
  );
};

export default SyntheseRecettes;
