import React, { useEffect, useState } from "react";

import Attachements from "../../Common/Forms/Attachements/Attachements";
import { formLabelsNewDep } from "../../../helpers/fakeData/Factures/depense";
import { Col, Row } from "react-bootstrap";
import Select from "../../Common/FormElements/Select/Select";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import CalendarInput from "../../Common/FormElements/CalendarInput/CalendarInput";
import Button from "../../Common/FormElements/Button/Button";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateExpenses } from "../../../REDUX/actions/expenses";
import moment from "moment";
import { useTranslation } from "react-i18next";

const NewDepenseGeneralInfo = ({
  initialValues,
  handleFormChange,
  attachments,
  setAttachments,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { currentExpensesItem, currentExpensesItemLoading: loading } =
    useSelector((state) => state.expensesReducer);
  const [formData, setFormData] = useState(initialValues);
  const [invalidFields, setInvalidFields] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabledInput, setDisabledInput] = useState(false);

  const checkFormValidation = () => {
    const updatedInvalidFields = [];
    formLabelsNewDep?.forEach((row) => {
      row?.forEach(({ name, required }) => {
        if (required) {
          if (
            formData[name] === undefined ||
            formData[name] === null ||
            !String(formData[name])?.trim()?.length > 0
          ) {
            updatedInvalidFields.push(name);
          }
        }
      });
    });
    if (!attachments[0]?.file && !attachments[0]?.fileId) {
      updatedInvalidFields.push("file");
    }

    setInvalidFields(updatedInvalidFields);
  };

  // Helpers
  const calculateVATRelatedValues = (amountTTC, VAT_PERCENTAGE, formData) => {
    const TVA = amountTTC * (VAT_PERCENTAGE / 100 / (1 + VAT_PERCENTAGE / 100));
    const amountHT = amountTTC - TVA;
    return { ...formData, amountHT: amountHT.toFixed(2), TVA: TVA.toFixed(2) };
  };

  const divideAmountAmongPayments = (amountTTC, formData) => {
    const paymentAmount = amountTTC / 3;
    return {
      ...formData,
      payment_1: amountTTC.toFixed(2),
      payment_2: 0,
      payment_3: 0,
    };
  };

  const reintialiseTTCandTVAprices = (formData) => {
    return {
      ...formData,
      amountTTC: undefined,
      TVA: undefined,
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData };

    // Update the form data
    updatedFormData = { ...updatedFormData, [name]: value };

    if (name === "amountTTC") {
      // Divide the amountTTC equally : payment_1, payment_2, and payment_3
      updatedFormData = divideAmountAmongPayments(+value, updatedFormData);
    }

    if (name === "amountTTC") {
      updatedFormData = calculateVATRelatedValues(+value, 20, updatedFormData);
    }
    if (name === "amountHT") {
      updatedFormData = reintialiseTTCandTVAprices(updatedFormData);
      setDisabledInput(true);
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    formData?.amountHT &&
    formData?.amountTTC === undefined &&
    formData?.TVA === undefined
      ? setFormData({ ...formData, amountTTC: undefined, TVA: undefined })
      : setDisabledInput(false);
  }, [formData?.amountHT]);

  const handleClick = () => {
    if (!invalidFields?.length > 0) {
      dispatch(
        createUpdateExpenses(
          formData,
          currentExpensesItem?.id,
          attachments[0],
          () => dispatch(closeRightModal()),
          t
        )
      );
    }
  };

  useEffect(() => {
    checkFormValidation();
    handleFormChange("infos", formData);
  }, [formData, attachments]);

  useEffect(() => {
    setDisabled(invalidFields?.length > 0);
  }, [invalidFields]);

  useEffect(() => {
    setFormData((currState) => {
      const updatedFormData = { ...currState };
      formLabelsNewDep?.forEach((row) => {
        row?.forEach(({ name }) => {
          updatedFormData[name] = currentExpensesItem?.attributes[name];
        });
      });
      updatedFormData.date = currentExpensesItem?.attributes?.date;
      return updatedFormData;
    });

    const attachment = currentExpensesItem?.attributes?.attachment?.data;
    if (attachment) {
      setAttachments((attach) => [
        {
          ...attach[0],
          id: attachment?.id ?? attach[0]?.id,
          fileId: attachment?.attributes?.file?.data?.id,
          fileUrl: attachment?.attributes?.file?.data?.attributes?.url,
          fileName:
            attach[0]?.fileName ??
            attachment?.attributes?.file?.data?.attributes?.name,
          attachmentId: attachment?.id,
          title: attachment?.attributes?.title,
          mediaType:
            attachment?.attributes?.file?.data?.attributes?.mime?.includes(
              "image"
            )
              ? "image"
              : attachment?.attributes?.file?.data?.attributes?.mime?.includes(
                  "video"
                )
              ? "video"
              : null,
        },
      ]);
    }
  }, [currentExpensesItem]);

  return (
    <>
      <div className="d-flex flex-column gap-3 my-5 ">
        <div className=" d-flex flex-column gap-3 mx-5">
          {formLabelsNewDep?.map((formRow, index) => (
            <Row key={`form-row-${index}`}>
              {formRow?.map((el) => {
                if (el.type === "calendar")
                  return (
                    <>
                      <Col lg={4} key={el.name} className="mt-1">
                        <p className="f16 text-ultraBlack mb-2">{el.label}</p>
                        <CalendarInput
                          name={el?.name}
                          key={el?.name}
                          className="w100"
                          value={
                            formData[el.name]
                              ? moment(formData[el?.name])?.utc(true)?.toDate()
                              : null
                          }
                          placeholder={t(`${el?.placeholder ?? ""}`)}
                          callback={handleChange}
                          {...el}
                          withMinDate={false}
                        />
                      </Col>
                    </>
                  );

                if (el.type === "select") {
                  return (
                    <Col lg={4} key={el.name} className="mt-1">
                      <p className="f16 text-ultraBlack mb-2">
                        {t(`${el.label ?? ""}`)}
                      </p>
                      <Select
                        name={el.name}
                        placeholder={t(`${el.label ?? ""}`)}
                        value={formData[el.name]}
                        options={el?.options}
                        label={t(`${el.label ?? ""}`)}
                        callback={handleChange}
                        {...el}
                      />
                    </Col>
                  );
                }
                return (
                  <Col lg={4} key={el.name} className="mt-1">
                    <p className="f16 text-ultraBlack mb-2">
                      {t(`${el.label}`)}
                    </p>
                    <TextInput
                      className={"w100"}
                      type={el.type}
                      name={el.name}
                      value={formData[el.name]}
                      placeholder={t(`${el.placeholder}`)}
                      euro={el.euro}
                      callback={handleChange}
                      {...el}
                      disabled={
                        el.label === "Amount incl. tax" ||
                        el?.label.includes("VAT")
                          ? disabledInput
                          : false
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          ))}
          <hr
            style={{ borderColor: "#abb8b2" }}
            className="mt-4 mb-2"
            width={"100%"}
          />
        </div>
        <Attachements
          attachments={attachments}
          setAttachments={setAttachments}
          fromDepense
        />
      </div>
      <div className="buttonsContainer d-flex justify-content-center pb-3 mt-auto">
        <Button
          variant="outline"
          className="me-4"
          onClick={() => dispatch(closeRightModal())}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="me-4"
          disabled={disabled}
          loading={loading}
          onClick={handleClick}
        >
          {t("Save")}
        </Button>
      </div>
    </>
  );
};

export default NewDepenseGeneralInfo;
