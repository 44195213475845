import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResellerStats } from "../../REDUX/actions/reseller";
import { useTranslation } from "react-i18next";
import SyntheseRecettes from "../Dashboard/Syntheses/SyntheseRecettes";
import RevendeurRangeStats from "./RevendeurRangeStats";
import { formatCurrency } from "../../Utils";

const RevendeurStats = ({ filters, userRole }) => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  // const { user } = useSelector((state) => state.userReducer);
  const { stats } = useSelector((state) => state.resellerReducer);

  // const userRole = user?.attributes?.userRole;

  const tabs = ["Current Revenue", "Nber Spa", "Nber Swim Spa"];

  if (userRole === 2) {
    tabs.push("Commission");
  } else if (userRole === 1) {
    tabs.push("Net Margin");
  }

  const { orderStats, generalStats } = stats;

  const commissionTotal =
    orderStats?.comissionMap &&
    orderStats?.comissionMap[currentYear]?.reduce(
      (acc, curr) => acc + (curr || 0),
      0
    );

  const generalStatsData = [
    {
      label: "Objectif Spa/Contract",
      value: generalStats?.objectifByContract,
      bgColor: "#577b87",
    },
    {
      label: userRole === 1 ? "CA Spa/Contract" : "Commission Spa/Contract",
      value: formatCurrency(
        (userRole === 1
          ? generalStats?.caByContract
          : generalStats?.comissionByContract) ?? 0
      ),
      bgColor: "#577b87",
      roles: [1, 2],
    },
    {
      label: "Nber Spa",
      value: generalStats?.spaCount,
      bgColor: "#94adb2",
      showYear: true,
    },
    {
      label: "Nber Swim Spa",
      value: generalStats?.swimSpaCount,
      bgColor: "#878a8b",
      showYear: true,
    },
    {
      label: "CA",
      value: formatCurrency(generalStats?.ca ?? 0),
      bgColor: "#cabaab",
      showYear: true,
      roles: [1],
    },
    {
      label: "Invest Solution",
      value: formatCurrency(generalStats?.investment ?? 0),
      bgColor: "#cabaab",
      roles: [0],
    },
    {
      label: "Commission",
      value: formatCurrency(commissionTotal ?? 0),
      bgColor: "#cabaab",
      roles: [2],
      showYear: true,
    },
    {
      label: "Margin Rate",
      value:
        (generalStats?.txMarge > 0 ? generalStats?.txMarge.toFixed(1) : 0) +
        "%",
      bgColor: "#cabaab",
      roles: [1],
      showYear: true,
    },
  ];

  useEffect(() => {
    dispatch(getResellerStats(filters));
  }, [dispatch, filters]);

  return (
    <div>
      <div className="row flex-nowrap g-3 pt-3 pb-5 overflow-auto">
        {generalStatsData.map(({ label, value, bgColor, showYear, roles }) => {
          const show = roles?.length > 0 ? roles?.includes(userRole) : true;
          if (!show) return null;
          return (
            <div className="col">
              <div
                key={label}
                className={`p-3 px-4 br20 flex-1 flex-column h-100`}
                style={{
                  backgroundColor: bgColor,
                }}
              >
                <span className="d-block text-white sBold f20 mb-1">
                  {t(`${label}`)} {showYear ? currentYear : ""}
                </span>
                <span className="d-block text-white text-nowrap bold f33">
                  {value ?? 0}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="gap-4 w-100 d-flex">
        <div className="w-50">
          <SyntheseRecettes stats={orderStats} tabs={tabs} />
        </div>
        <div className="d-flex w-50">
          <RevendeurRangeStats />
        </div>
      </div>
    </div>
  );
};

export default RevendeurStats;
