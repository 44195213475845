import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../Common/FormElements/Select/Select";
import { MultiSelect } from "primereact/multiselect";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { noteForm } from "./data";
import TextArea from "../../Common/FormElements/TextArea/TextArea";

const Note = ({ initialValues, handleFormChange }) => {
  const { t } = useTranslation("common");
  const [selectOptions, setSelectOptions] = useState({});
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e, max) => {
    const { value, name } = e.target;

    if (max) {
      if (value <= max) {
        if (
          [
            "storeNote",
            "communicationNote",
            "eventNote",
            "serviceNote",
          ]?.includes(name)
        ) {
          setFormData((prev) => ({
            ...prev,
            [name]: value,
          }));
          setFormData((prev) => ({
            ...prev,
            globalNote: [
              "storeNote",
              "communicationNote",
              "eventNote",
              "serviceNote",
            ].reduce((acc, curr) => acc + +prev[curr], 0),
          }));
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    handleFormChange("note", formData);
  }, [formData]);

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="modalContent px-5 pt-4 pb-5">
        <div className="row">
          {noteForm.map((el) => (
            <>
              <div
                className={`col-${el.type === "textarea" ? 12 : 6} mt-4`}
                key={el?.name}
              >
                <p className="f16 text-ultraBlack mb-2">{t(`${el?.label}`)}</p>
                {el.type === "select" ? (
                  <Select
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    options={el.options ?? []}
                    callback={handleChange}
                    appendToSelf
                  />
                ) : el.type === "multiSelect" ? (
                  <MultiSelect
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    options={selectOptions[el.name] ?? el.options ?? []}
                    callback={handleChange}
                    appendToSelf
                    className="h-40px w-100"
                  />
                ) : el.type === "textarea" ? (
                  <TextArea
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    callback={handleChange}
                  />
                ) : (
                  <TextInput
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    surfixText={`/${el?.max}`}
                    callback={(e) => handleChange(e, el?.max)}
                    disabled={el?.disabled}
                  />
                )}
              </div>
              {el.offset > 0 && (
                <div className={`col-${el.offset} mt-4`} key={el?.name}></div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Note;
