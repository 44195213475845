import darkImg from "../../../Images/Images/Catalaogue/cuveJupe/dark.jpg";
import blueImg from "../../../Images/Images/Catalaogue/cuveJupe/blue.jpg";
import greyImg from "../../../Images/Images/Catalaogue/cuveJupe/grey.jpg";
import midnightImg from "../../../Images/Images/Catalaogue/cuveJupe/midnight-opal.png";
import whiteMarble from "../../../Images/Images/Catalaogue/cuveJupe/white-marble.png";
import medet from "../../../Images/Images/Catalaogue/cuveJupe/medet.png";
import marron from "../../../Images/Images/Catalaogue/cuveJupe/marron.png";
import oceanWave from "../../../Images/Images/Catalaogue/cuveJupe/ocean-wave.png";
import {
  CuveJupeIcon,
  cuveMapNames,
  jupeMapNames,
} from "../../../helpers/formLabels/cuveJupeOptions";

export const getPackingSelectValue = (approv) => {
  return (
    approv?.packingList?.map((packing) => {
      return {
        ...packing,
        model: {
          name: packing.model?.data?.attributes?.name,
          code: packing.model?.data?.id,
          productType: packing.model?.data?.attributes?.productType,
          retailerPriceHT: packing.model?.data?.attributes?.retailerPriceHT,
          publicPriceTTC: packing.model?.data?.attributes?.publicPriceTTC,
        },
        reference: packing.reference,
        cuve: packing?.isProduct ? cuveMapNames[packing?.cuve] : null,
        panel: packing?.isProduct ? jupeMapNames[packing?.panel] : null,
      };
    }) || []
  );
};

export const getCuveValue = (code) => {
  switch (code) {
    case "dark":
      return { name: <CuveJupeIcon src={darkImg} />, code: "dark" };
    case "blue":
      return { name: <CuveJupeIcon src={blueImg} />, code: "blue" };
    case "grey":
      return { name: <CuveJupeIcon src={greyImg} />, code: "grey" };
    case "Midnight Opal":
      return {
        name: <CuveJupeIcon src={midnightImg} />,
        code: "Midnight Opal",
      };
    case "White Marble":
      return { name: <CuveJupeIcon src={whiteMarble} />, code: "White Marble" };
    case "Gris":
      return { name: <CuveJupeIcon src={greyImg} />, code: "Gris" };
    case "Mediterranean sunset":
      return {
        name: <CuveJupeIcon src={medet} />,
        code: "Mediterranean sunset",
      };
    case "Marron":
      return { name: <CuveJupeIcon src={marron} />, code: "Marron" };
    case "Ocean Wave":
      return { name: <CuveJupeIcon src={oceanWave} />, code: "Ocean Wave" };
    default:
      return null;
  }
};

export const getCuvePanelListImg = (value) => {
  switch (value) {
    case "dark":
      return <CuveJupeIcon src={darkImg} />;
    case "blue":
      return <CuveJupeIcon src={blueImg} />;
    case "grey":
      return <CuveJupeIcon src={greyImg} />;
    case "Midnight Opal":
      return <CuveJupeIcon src={midnightImg} />;
    case "White Marble":
      return <CuveJupeIcon src={whiteMarble} />;
    case "Gris":
      return <CuveJupeIcon src={greyImg} />;
    case "Mediterranean sunset":
      return <CuveJupeIcon src={medet} />;
    case "Marron":
      return <CuveJupeIcon src={marron} />;
    case "Ocean Wave":
      return <CuveJupeIcon src={oceanWave} />;
    default:
      return null;
  }
};
