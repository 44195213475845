import React, { Fragment, useEffect, useMemo, useState } from "react";
import MessageInput from "./NewTicketSav/ChatContainer/MessageInput";
import { useDispatch, useSelector } from "react-redux";
import { createCommentSAV, getComments } from "../../REDUX/actions/ticketSAV";
import MessageItem from "./NewTicketSav/ChatContainer/MessageItem";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";
import moment from "moment";

const SavChatMessages = ({ isAdmin }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const {
    ticketSAV,
    currentAdminComments,
    currentComments,
    currentCommentsLoading,
    currentAdminCommentsLoading,
    createCommentLoading,
  } = useSelector((state) => state.ticketSAVReducer);
  const [newMessage, setNewMessage] = useState({ text: "", attachments: [] });
  const comments = useMemo(() => {
    return {
      list: isAdmin ? currentAdminComments : currentComments,
      loading: isAdmin ? currentAdminCommentsLoading : currentCommentsLoading,
    };
  }, [
    currentAdminComments,
    currentCommentsLoading,
    currentAdminCommentsLoading,
    currentComments,
    isAdmin,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attachments") {
      setNewMessage({
        ...newMessage,
        [name]:
          newMessage?.attachments?.length > 0
            ? [...newMessage?.attachments, ...value]
            : value,
      });
    } else {
      setNewMessage({ ...newMessage, [name]: value });
    }
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    if (
      newMessage?.text?.trim()?.length > 0 ||
      newMessage?.attachments?.length > 0
    ) {
      dispatch(
        createCommentSAV(
          newMessage,
          ticketSAV?.id,
          isAdmin,
          user?.id,
          () => {
            setNewMessage({ text: "", attachments: [] });
            callback();
          },
          t
        )
      );
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages?.reduce((acc, message) => {
      const createdAt = message?.attributes?.createdAt;
      const formattedDate = createdAt ? moment(createdAt).format("LL") : "-"; // Example: January 31, 2025

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(message);

      return acc;
    }, {});
  };

  const groupedMessages = useMemo(
    () => groupMessagesByDate(comments.list),
    [comments.list]
  );

  useEffect(() => {
    if (ticketSAV?.id) {
      dispatch(getComments(ticketSAV?.id, isAdmin));
    }
  }, [dispatch, ticketSAV?.id, isAdmin]);

  return (
    <LoaderWrapper
      loading={currentCommentsLoading || currentAdminCommentsLoading}
    >
      <div className="newSavComment d-flex flex-column justify-content-end position-relative overflow-hidden max-w-900px mx-auto">
        <div className="d-flex px-4 flex-column overflow-auto flex-column-reverse flex-grow-1 pt-3 pb-5">
          {Object.entries(groupedMessages).map(([date, messages]) => (
            <Fragment key={date}>
              {messages.map((comment, index) => (
                <MessageItem
                  key={comment?.id}
                  index={index}
                  message={comment}
                  isOwner={+user?.id === +comment?.attributes?.user?.data?.id}
                  view={isAdmin ? "SAV_ADMIN" : "SAV"}
                />
              ))}
              <div className="text-center text-muted my-2 px-4 py-2 f11 bg-dimGrey br5 sBold d-flex max-fit-content mx-auto text-dark">
                {date}
              </div>
            </Fragment>
          ))}
        </div>
        <div className={"px-4"}>
          <MessageInput
            loading={createCommentLoading}
            handleChange={handleChange}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default SavChatMessages;
