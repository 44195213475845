import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../Common/FormElements/Select/Select";
import { MultiSelect } from "primereact/multiselect";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { accompanimentForm } from "./data";
import API from "../../../Api/Api";
import { API_END_POINT } from "../../../Api/EndPoints";
import TextArea from "../../Common/FormElements/TextArea/TextArea";

const Accompaniment = ({ initialValues, handleFormChange }) => {
  const { t, i18n } = useTranslation("common");
  const [selectOptions, setSelectOptions] = useState({});
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getSelectValue = (value, options) => {
    const currentOptions = (options ?? []).map((option) => ({
      name: t(`${option.name}`),
      code: option.code,
    }));
    if (["number", "string", "boolean"].includes(typeof value)) {
      return currentOptions.find((option) => option.code === value);
    }
    return value;
  };

  useEffect(() => {
    handleFormChange("accompaniment", formData);
  }, [formData]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await API.get(`${API_END_POINT}api/furnitures`, {
          params: {
            fields: ["id", "name"],
          },
        });

        setSelectOptions({
          furnitures: data?.data.map((furniture) => ({
            value: furniture.id,
            label: i18n.language?.includes("en")
              ? furniture?.attributes?.name_en
              : furniture?.attributes?.name,
          })),
        });
      } catch {}
    })();
  }, [i18n.language]);

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="modalContent px-5 pt-4 pb-5">
        <div className="row">
          {accompanimentForm.map((el) => (
            <>
              <div
                className={`col-${el.type === "textarea" ? 12 : 6} mt-4`}
                key={el?.name}
              >
                <p className="f16 text-ultraBlack mb-2">{t(`${el?.label}`)}</p>
                {el.type === "select" ? (
                  <Select
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={getSelectValue(formData[el?.name], el.options ?? [])}
                    options={
                      (el.options ?? []).map((option) => ({
                        name: t(`${option.name}`),
                        code: option.code,
                      })) ?? []
                    }
                    callback={handleChange}
                    appendToSelf
                  />
                ) : el.type === "multiSelect" ? (
                  <MultiSelect
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    options={
                      selectOptions[el.name] ??
                      (el.options ?? []).map((option) => ({
                        name: t(`${option.name}`),
                        code: option.code,
                      })) ??
                      []
                    }
                    onChange={handleChange}
                    appendToSelf
                    className="h-40px w-100"
                  />
                ) : el.type === "textarea" ? (
                  <TextArea
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    callback={handleChange}
                  />
                ) : (
                  <TextInput
                    {...el}
                    label={el.label ? t(`${el.label}`) : ""}
                    placeholder={el.placeholder ? t(`${el.placeholder}`) : ""}
                    value={formData[el?.name]}
                    callback={handleChange}
                  />
                )}
              </div>
              {el.offset > 0 && (
                <div className={`col-${el.offset} mt-4`} key={el?.name}></div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accompaniment;
