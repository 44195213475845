import React, { useEffect, useState } from "react";
import "./CalendarEvent.css";
import { getCalendarEventStyle } from "../../../../Utils";

const CalendarEvent = ({
  id,
  type,
  title,
  resellerName,
  startTime,
  endTime,
  width = "",
  hasFullHeight,
  isEndDate,
  isStartDate,
  multipleDays,
  isBetween,
  callback,
  slug,
}) => {
  const calendarEventContainer = document.getElementById(id);
  const [dialogTop, setDialogTop] = useState(0);
  const [dialogLeft, setDialogLeft] = useState(0);
  const [eventStyle, setEventStyle] = useState();
  const [paddingLeft, setpaddingLeft] = useState(false);
  const [paddingRight, setpaddingRight] = useState(false);
  const [paddingX, setpaddingX] = useState(false);

  const heightClassname = hasFullHeight ? "h100" : "";

  useEffect(() => {
    setEventStyle(getCalendarEventStyle(type));
    setDialogTop(calendarEventContainer?.getBoundingClientRect().top);
    setDialogLeft(calendarEventContainer?.getBoundingClientRect().left);
    const handleScroll = () => {
      setDialogTop(calendarEventContainer?.getBoundingClientRect().top);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [type, calendarEventContainer]);

  useEffect(() => {
    if (multipleDays && isStartDate) {
      setpaddingLeft(true);
    }
    if (isBetween) setpaddingX(true);
    if (isEndDate && !multipleDays) setpaddingRight(true);
  }, [isBetween, isEndDate, isStartDate, multipleDays]);

  return (
    <div
      className={`${paddingLeft && "ps-2"}  ${paddingRight && "pe-2"}  ${
        isBetween && "px-0"
      } ${
        type !== "Salon" && type !== "Événement" && "px-2"
      } calendarEventContainer mb-2 ${isBetween ? "between" : ""} ${
        isEndDate ? "end-date" : ""
      }`}
      id={id}
    >
      <div
        className={`calendar-event position-relative ${!isBetween && "br10"} ${
          paddingLeft && "br10Left"
        } ${paddingRight && "br10Right"} ${
          paddingRight && paddingLeft && "br10"
        } pointer p-3 pe-4 d-flex ${width} ${
          eventStyle?.eventBgStyle
        } ${heightClassname}`}
        role="button"
        onClick={callback}
      >
        {(isStartDate || !isStartDate || !isBetween || !isEndDate) && (
          <div
            className={`vertical-bar br10 me-3 bg-${eventStyle?.textBarStyle}`}
          ></div>
        )}
        <div className="colAlignC  justify-content-start align-items-start ">
          <span className={`f13 semi-bold text-${eventStyle?.textBarStyle}`}>
            {title ?? type}
          </span>
          {/* On Hover event card */}
          {startTime && (
            <span className="text-blueGrey f10">{`${startTime} - ${endTime}`}</span>
          )}
        </div>
        {/* {isEndDate && (
          <div
            className={`vertical-bar br10 ms-auto bg-${eventStyle?.textBarStyle}`}
          ></div>
        )} */}
      </div>
    </div>
  );
};

export default CalendarEvent;
