import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import OutsideClickHandler from "react-outside-click-handler";
// import SwitchButton from "../../Common/FormElements/SwitchButton/SwitchButton";
import MenuAvatar from "./MenuAvatar";
import NotifDropdown from "./NotifDropdown";
import RightModal from "../../Modals/RightModal/RightModal";
import { HeaderLogoSvg, NotifSvg } from "../../Svgs";
// import { switchToAdmin, switchToRevendeur } from "../../../REDUX/actions/role";
import { rolesColors } from "../../../Utils/fileUtils";
import "./Header.css";
import { openQuestionsPopup } from "../../../REDUX/actions/questionsPopup";
import Select from "../../Common/FormElements/Select/Select";
import LanguageSelector from "../../Common/LanguageSelector";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";

const Header = () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const { userRole, user } = useSelector((state) => state.userReducer);
  const { fromProfile } = useSelector((state) => state.rightModalReducer);

  const location = useLocation();
  let navigate = useNavigate();

  const handleShowNotifDropdown = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (location?.pathname?.includes("produits") && userRole === "reseller") {
      navigate("/catalogue");
    } else if (
      location?.pathname?.includes("prospects") &&
      userRole === "admin"
    ) {
      navigate("/revendeur");
    }
  }, [location?.pathname, userRole, navigate]);

  return (
    <div
      className={`headerContainer bg-${
        +user?.attributes?.marque?.data?.id === 1 && rolesColors[userRole]
      } d-flex justify-content-between align-items-center px-3 gap-3`}
      style={
        user?.attributes?.marque?.data?.id !== 1 && {
          backgroundColor: `#${user?.attributes?.marque?.data?.attributes?.bgColor}`,
        }
      }
    >
      <Link to={"/"} className="pointer text-white h-100">
        <HeaderLogoSvg
          imageURL={
            user?.attributes?.marque?.data?.attributes?.logo?.data
              ? `${IMAGE_END_POINT}${user?.attributes?.marque?.data?.attributes?.logo?.data?.attributes?.url}`
              : undefined
          }
        />
      </Link>

      <div className="flex-2 justify-content-end alignH gap-4">
        <div className="alignH gap-3">
          <LanguageSelector />
          {/* {userRole === "reseller" && (
            <span
              className="br5 menuIconContainer messagesMenuIcon colAlignC pt-1 pointer"
              role="button"
              onClick={() => dispatch(openQuestionsPopup())}
            >
              <i className="bi bi-chat-square-dots text-white f16"></i>
            </span>
          )} */}
          {/* <span className="notifIcon">
            <OutsideClickHandler
              onOutsideClick={() => {
                isShow && setIsShow(false);
              }}
            >
              <span
                className="br5 menuIconContainer  colAlignC position-relative pointer"
                onClick={handleShowNotifDropdown}
              >
                <NotifSvg width={14} height={17} color={"white"} notified />
                <div className="br50 b-2-white bg-turquoise position-absolute notifiedIcon"></div>
              </span>
              {isShow && (
                <div className="notifDrop">
                  <NotifDropdown />
                </div>
              )}
            </OutsideClickHandler>
          </span> */}
        </div>
        <MenuAvatar />
        {fromProfile ? <RightModal /> : null}
      </div>
    </div>
  );
};

export default Header;
