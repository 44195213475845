import React, { useEffect, useState } from "react";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  generateAvoir,
  generateInvoice,
  updateCredit,
  updateFacture,
} from "../../../REDUX/actions/catalogue";
import { saveAs } from "file-saver";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { useTranslation } from "react-i18next";
const Step4Facture = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { cart, cartLoading } = useSelector((state) => state.catalogueReducer);
  const [factureDataIncomplete, setfactureDataIncomplete] = useState(true);

  const [factureNumber, setfactureNumber] = useState(
    cart.facture?.number || null
  );
  const [avoirNumber, setavoirNumber] = useState(cart.avoir?.number || null);
  const [avoirDate, setavoirDate] = useState(cart.avoir?.date || null);

  useEffect(() => {
    setfactureDataIncomplete(
      cart.particulier
        ? !cart.addresseDeLivraison ||
            !cart.addresseDeFacturation ||
            !cart.deliveryOptionName ||
            !cart.particulierName
        : cart?.majorAccountOrder
        ? !cart.addresseDeLivraison ||
          !cart.addresseDeFacturation ||
          !cart.deliveryOptionName ||
          !cart.majorAccountName
        : !cart.addresseDeLivraison ||
          !cart.addresseDeFacturation ||
          !cart.deliveryOptionName ||
          !cart.reseller
    );
  }, [cart]);
  const [factureDate, setfactureDate] = useState(cart.facture?.date || null);

  useEffect(() => {
    if (cart) {
      setfactureNumber(cart.facture?.number || null);
      setavoirNumber(cart.avoir?.number || null);
      setfactureDate(cart.facture?.date || null);
      setavoirDate(cart.avoir?.date || null);
    }
  }, [cart]);

  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <div className="d-flex gap-5">
        <h4 className="mt-4 text-softBlue bold">4- {t("Order details")} :</h4>
      </div>

      {cart.facture && (
        <>
          <div className="mt-3 alignH gap-2">
            <div>{t("Order number")} : </div>
            <input
              className="refClientItem p-1"
              defaultValue={cart?.credit ? avoirNumber : factureNumber}
              onChange={(e) =>
                cart?.credit
                  ? setavoirNumber(e.target.value)
                  : setfactureNumber(e.target.value)
              }
            />
          </div>
          <div className="mt-3 alignH gap-2">
            <div>{t("Invoice date")} : </div>
            <input
              className="refClientItem p-1"
              type="date"
              defaultValue={cart?.credit ? avoirDate : factureDate}
              onChange={(e) =>
                cart?.credit
                  ? setavoirDate(e.target.value)
                  : setfactureDate(e.target.value)
              }
            />
          </div>

          <div
            className="f12 underline cursor-pointer w100 m-auto"
            onClick={() =>
              saveAs(
                `${IMAGE_END_POINT}${cart.facture.media.url}`,
                `${t(cart?.credit ? "Credit" : "Invoice")}-${
                  cart?.credit ? cart?.avoir?.number : cart.facture?.number
                } ${t("Order")}-${cart.number}`
              )
            }
          >
            {t(cart?.credit ? "Download credit" : "Download invoice")}
          </div>
        </>
      )}
      <div className="w40 m-auto">
        <Button
          loading={cartLoading}
          className="w100 my-3 bold"
          disabled={factureDataIncomplete}
          onClick={() => {
            if (cart?.credit) {
              if (cart?.avoir) {
                dispatch(
                  updateCredit(
                    cart?.avoir?.id,
                    { number: avoirNumber, date: avoirDate },
                    cart?.id,
                    t
                  )
                );
              } else {
                dispatch(generateAvoir(cart?.id, false, t));
              }
            } else {
              if (cart?.facture) {
                dispatch(
                  updateFacture(
                    cart?.facture?.id,
                    { number: factureNumber, date: factureDate },
                    cart?.id,
                    t
                  )
                );
              } else {
                dispatch(generateInvoice(cart?.id, false, t));
              }
            }
          }}
        >
          {cart?.facture
            ? t("Validate the changes")
            : t(cart?.credit ? "Generate the credit" : "Generate the invoice")}
        </Button>
      </div>
    </div>
  );
};

export default Step4Facture;
