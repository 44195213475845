import { useTranslation } from "react-i18next";
import Button from "../Common/FormElements/Button/Button";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import RevendeurAddMedia from "./RevendeurAddMedia";
import MediaPreview from "../Common/MediaPreview";
const RevendeurGallery = ({ gallery, resellerId, addMediaToGallery }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [previewMedia, setPreviewMedia] = useState();
  console.log(previewMedia);

  const divideGalleryIntoChunks = (gallery, chunkSize = 3) => {
    const result = [];
    for (let i = 0; i < gallery.length; i += chunkSize) {
      result.push(gallery?.slice(i, i + chunkSize));
    }
    return result;
  };

  const galleryChunks = gallery?.data && divideGalleryIntoChunks(gallery?.data);

  const handleClick = () => {
    dispatch(
      openCenteredModal(
        <RevendeurAddMedia
          resellerId={resellerId}
          currentIds={gallery?.data?.map((media) => media?.id)}
          addMediaToGallery={addMediaToGallery}
        />
      )
    );
  };

  return (
    <div className="py-4">
      <div className="d-flex alignH justify-content-between mb-3">
        <span className="text-blue bold f22 quickSand">
          {t("Store Content")}
        </span>
        <Button onClick={handleClick}>{t("Add photo/video")}</Button>
      </div>

      <div className="d-flex flex-column gap-4">
        {galleryChunks?.slice(0, page)?.map((chunk) => (
          <div className="gallery-container max-h-900px overflow-hidden position-relative">
            {chunk?.map((media, index) => {
              const src = `${IMAGE_END_POINT}${media?.attributes?.url}`;
              const wideMediaSpan =
                index % 3 === 0 ? "gallery-item-wide" : "side-images-" + index;

              return (
                <div
                  className={`position-relative gallery-item ${wideMediaSpan}`}
                  key={media?.id}
                >
                  {media?.attributes?.mime?.includes("video") ? (
                    <video controls className={`h-100 w-100 ob-cover`}>
                      <source src={src} />
                    </video>
                  ) : media?.attributes?.mime?.includes("image") ? (
                    <img
                      src={src}
                      alt={media?.attributes?.name}
                      className={`w-100 h-100 ob-cover`}
                    />
                  ) : null}
                  <button
                    className="opacity-0 position-absolute start-0 top-0 w-100 h-100 z-1"
                    onClick={() => setPreviewMedia(media?.attributes?.url)}
                  >
                    {t("Preview")}
                  </button>
                  {previewMedia === media?.attributes?.url && (
                    <MediaPreview
                      isUrl
                      mediaType={
                        media?.attributes?.mime?.includes("image")
                          ? "image"
                          : media?.attributes?.mime?.includes("video")
                          ? "video"
                          : null
                      }
                      file={previewMedia}
                      setPreviewMedia={setPreviewMedia}
                    />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      {galleryChunks?.length - 1 >= page && (
        <div className="w-100 text-center mt-4">
          <button
            className="text-brightGrey underline bg-transparent border-0 sBold outline-none"
            onClick={() => setPage((prev) => prev + 1)}
          >
            {t("See more")}
          </button>
        </div>
      )}
    </div>
  );
};

export default RevendeurGallery;
