import React, { useEffect, useState } from "react";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import { useDispatch, useSelector } from "react-redux";
import { openRightModal } from "../../REDUX/actions/rightModal";
import ModifyOrder from "./ModifyOrder/ModifyOrder";
import DeliverySummary from "./ModifyOrder/DeliverySummary";
import OrderProductsList from "../Common/OrderProductsList/OrderProductsList";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import DeleteCommand from "./DeleteCommand";
import DeliveryDialog from "./DeliveryDialog/DeliveryDialog";
import ModifyStatusDialog from "./ModifyOrder/ModifyStatusDialog";
// import CommandeStatusTag from "./CommandeStatusTag";
import ValidatePayment from "./ValidatePayment";
import { saveAs } from "file-saver";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import { formatDate, getFileName } from "../../Utils/fileUtils";
import { getOrderStatus } from "./ModifyOrder/orderUtils";
import {
  handleCloseOrderCard,
  handleShowOrderCard,
} from "../../REDUX/actions/orders";
import adjustPrice from "../../Utils/AdjustPrice";
import CustomTooltip from "../Common/CustomTooltip/CustomTooltip";
import { isDefined } from "../../Utils";
import moment from "moment";
import { createInvoice } from "../../REDUX/actions/invoices";
import {
  generateAvoir,
  generateBonDeCommande,
  generateInvoice,
  updateCart,
} from "../../REDUX/actions/catalogue";
import { useNavigate } from "react-router-dom";
import { updateCommande } from "../../REDUX/actions/commandes";
import { useTranslation } from "react-i18next";

const OrderCardAdmin = ({ commande, paymentStatus, orderData }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { showOrder } = useSelector((state) => state.ordersReducer);

  const { userRole, user } = useSelector((state) => state.userReducer);
  const { factureLoading, avoirLoading } = useSelector(
    (state) => state.commandeReducer
  );

  const dispatch = useDispatch();

  const toggleOrderDropdown = () => {
    +showOrder === +commande.id
      ? dispatch(handleCloseOrderCard())
      : dispatch(handleShowOrderCard(commande.id));
  };

  useEffect(() => {
    return () => {
      dispatch(handleCloseOrderCard());
    };
  }, [dispatch]);

  const handleOpenDeliverySummary = () => {
    dispatch(openCenteredModal(<DeliverySummary id={commande.id} />));
  };
  const handleOpenModal = () => {
    dispatch(openRightModal(<ModifyOrder id={commande.id} />));
  };
  const handleDelete = () => {
    dispatch(
      openCenteredModal(
        <DeleteCommand
          commandeNumber={commande.number}
          commandeId={commande.id}
        />
      )
    );
  };

  const handlePayment = () => {
    dispatch(
      openCenteredModal(
        <ValidatePayment
          id={orderData.id}
          paymentStatus={paymentStatus}
          slug={orderData?.slug}
        />
      )
    );
  };

  const handleDeliveryDate = (isEstimatedDate) => {
    dispatch(
      openCenteredModal(
        <DeliveryDialog
          oldEstimatedDate={orderData?.estimatedDeliveryDate}
          oldRealDeliveryDate={orderData?.realDeliveryDate}
          orderStatus={orderData?.status}
          orderId={orderData?.id}
          isEstimatedDate={isEstimatedDate}
        />
      )
    );
  };

  const bgClassName = +showOrder === +commande.id ? "bg-blue" : "";
  const textColorClassName =
    +showOrder === +commande.id ? "text-white" : "text-brightGrey";
  const showClassName = +showOrder === +commande.id ? "show" : "";

  return (
    <div>
      <div
        className={`orderCardAdmin ${
          commande?.majorAccountOrder &&
          +showOrder !== +commande.id &&
          "bg-lightYelloww"
        } border-bottom row p-4 d-flex justify-content-between pointer align-items-center w-100 orderCardContainer ${bgClassName} ${showClassName}`}
        onClick={toggleOrderDropdown}
      >
        <CustomTooltip title={t("#New|Old")}>
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
          >
            #{commande.number}
            {commande?.oldId && `|${commande?.oldId}`}
          </div>
        </CustomTooltip>

        <div
          className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
        >
          {commande.date ? formatDate(commande.date, "DD-MM-YYYY") : "-"}
        </div>

        <CustomTooltip title={t("Invoice number / Billing date")}>
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
          >
            <span>
              #
              {commande?.credit
                ? commande?.avoir?.number
                : commande.facture?.number || ""}
            </span>
            <br />
            <span>
              {commande?.credit
                ? commande?.avoir?.date
                : commande.facture?.date || ""}
            </span>
          </div>
        </CustomTooltip>
        <CustomTooltip
          title={commande.reseller?.name || commande.particulierName}
        >
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName} ellipsis-text`}
          >
            {commande.reseller?.name || commande.particulierName || "-"}
          </div>
        </CustomTooltip>
        {!commande?.credit && (
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
          >
            <span>#{commande.bon_de_commande?.number || ""}</span>
            <br />
            <span>{commande.bon_de_commande?.date || ""}</span>
          </div>
        )}

        <CustomTooltip title={t("Preferred delivery / Delivery")}>
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
          >
            {commande.estimatedDeliveryDate
              ? formatDate(commande.estimatedDeliveryDate, "DD-MM-YYYY")
              : "-"}{" "}
            <br />
            {commande.realDeliveryDate
              ? formatDate(commande.realDeliveryDate, "DD-MM-YYYY")
              : "-"}
          </div>
        </CustomTooltip>

        <div
          className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
        >
          {userRole === "BusinessBroker"
            ? commande.comission
              ? `${commande.comission} €`
              : "-"
            : commande.business_affiliate
            ? `${commande.business_affiliate?.user?.firstName}`
            : "-"}
        </div>
        {userRole === "admin" && (
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
          >
            {commande?.txMarge
              ? `${commande?.txMarge?.toFixed(2)?.toString()} %`
              : "-"}
          </div>
        )}

        <div
          className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
        >
          {adjustPrice(commande.totalTTC?.toFixed(2)?.toString())} €
        </div>
        {commande.status === "Brouillon" && userRole === "admin" ? (
          <CustomTooltip
            title={`${t("Being modified by")} ${
              commande.ongoingCreator?.firstName || ""
            } ${commande.ongoingCreator?.lastName || ""}`}
          >
            <div
              className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
            >
              {t(`${commande.status ?? ""}`)}
            </div>
          </CustomTooltip>
        ) : (
          <div
            className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
          >
            {t(`${commande.status ?? ""}`)}
          </div>
        )}
        <div
          className={`f14 text-brightGrey normal col-1 ${textColorClassName}`}
        >
          {t(`${commande.payStatus ?? ""}`)}
        </div>
        <div
          className={`f14 text-brightGrey normal d-flex align-items-center justify-content-around col-1 ${textColorClassName}`}
        >
          {commande.creator?.firstName || "-"}
          <div
            className={`f14 normal  col-1 ${textColorClassName}alignH justify-content-between`}
          >
            <VerticalPoints
              showLeft
              options={[
                {
                  text: t("View the order"),
                  callback: () =>
                    dispatch(
                      openRightModal(
                        <ModifyOrder
                          id={commande?.id}
                          initialTab={"General information"}
                        />
                      )
                    ),
                },
                {
                  text: t("Modify the order"),
                  hidden: userRole !== "admin",

                  callback: () => {
                    localStorage.setItem("selectedCart", commande.id);
                    dispatch(
                      updateCommande(commande.id, {
                        status: "Brouillon",
                        ongoingCreator: user.id,
                      })
                    ).then(() => navigate("/catalogue"));
                  },
                },
                {
                  text: t("Download invoice"),
                  hidden: !commande?.facture?.media?.url || commande?.credit,
                  callback: () =>
                    saveAs(
                      IMAGE_END_POINT + commande?.facture?.media?.url,
                      `${t("Invoice")}-${commande.facture?.number} ${t(
                        "Order"
                      )}-${commande.number}`
                    ),
                },
                {
                  text: t("Generate the invoice"),
                  hidden:
                    userRole !== "admin" ||
                    commande.status === "Brouillon" ||
                    commande?.credit,
                  loading: factureLoading === commande.id,

                  callback: () =>
                    dispatch(generateInvoice(commande.id, true, t)),
                },

                {
                  text: t("Generate the credit"),
                  hidden:
                    userRole !== "admin" ||
                    commande.status === "Brouillon" ||
                    !commande?.credit,
                  loading: avoirLoading === commande.id,

                  callback: () => dispatch(generateAvoir(commande.id, true, t)),
                },

                {
                  text: t("Download credit"),
                  hidden: !commande?.avoir?.media?.url || !commande?.credit,
                  callback: () =>
                    saveAs(
                      IMAGE_END_POINT + commande?.avoir?.media?.url,
                      `${t("Credit")}-${commande.avoir?.number} ${t("Order")}-${
                        commande.number
                      }`
                    ),
                },

                {
                  text: t("Download the purchase order"),
                  hidden:
                    !commande?.bon_de_commande?.media?.url || commande?.credit,
                  callback: () =>
                    saveAs(
                      IMAGE_END_POINT + commande?.bon_de_commande?.media?.url,
                      `Bon_de_commande-${commande.bon_de_commande?.number} commande-${commande.number}`
                    ),
                },
                {
                  text: t("Generate the purchase order"),
                  hidden: userRole !== "admin" || commande?.credit,
                  loading: factureLoading === commande.id,

                  callback: () =>
                    dispatch(generateBonDeCommande(commande.id, true, t)),
                },
                // {
                //   text: "Générer la facture",
                //   // hidden: !!!orderData?.attachment?.file?.url,
                //   callback: () =>
                //     dispatch(
                //       generateInvoice(commande.id)
                //     ),
                //   loading: invoicesLoading,
                // },
                // {
                //   text: "Préparation de livraison",
                //   callback: handleOpenDeliverySummary,
                // },
                {
                  text: t("Set a preferred delivery date"),
                  callback: () => handleDeliveryDate({ isEstimatedDate: true }),
                },
                {
                  text: t("Set a delivery date"),
                  callback: () => handleDeliveryDate(),
                },
                {
                  text: t("Comment"),
                  callback: () =>
                    dispatch(
                      openRightModal(
                        <ModifyOrder
                          id={commande?.id}
                          initialTab={t("Comments")}
                        />
                      )
                    ),
                },
                {
                  text: t("Deposits and payments"),
                  callback: () =>
                    dispatch(
                      openRightModal(
                        <ModifyOrder
                          id={commande?.id}
                          initialTab={t("Payments")}
                        />
                      )
                    ),
                },

                {
                  text: t("Set status"),
                  callback: () =>
                    dispatch(
                      openCenteredModal(
                        <ModifyStatusDialog
                          id={orderData.id}
                          status={orderData.status}
                          number={commande?.number}
                        />
                      )
                    ),
                },

                {
                  text: t("Set payment status"),
                  callback: () =>
                    dispatch(
                      openCenteredModal(
                        <ModifyStatusDialog
                          id={orderData.id}
                          status={orderData.payStatus}
                          number={commande?.number}
                          isPayment
                        />
                      )
                    ),
                },
                // {
                //   text: !paymentStatus
                //     ? "Valider le paiement"
                //     : "Annuler le paiement",
                //   callback: handlePayment,
                // },
                {
                  text: t("Delete the order"),
                  hidden:
                    userRole !== "admin" ||
                    commande.status === "Livrée" ||
                    commande.payStatus === "Payée" ||
                    commande.payStatus === "Acompte payé",
                  callback: handleDelete,
                  red: true,
                },
              ]}
              color={+showOrder === +commande.id ? "white" : ""}
              className="ms-auto"
            />
          </div>
        </div>
      </div>

      {+showOrder === +commande.id && showOrder !== null && (
        <OrderProductsList orderData={orderData} />
      )}
    </div>
  );
};

export default OrderCardAdmin;
