const initialState = {
  packings: [],
  loading: undefined,
  updateLoading: false,
  error: null,
  lazyLoading: false,
  nextOffset: 0,
};

const advancedStockReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_STOCK_LOADING":
      return { ...state, loading: true, nextOffset: 0 };

    case "FETCH_STOCK_LAZY_LOADING":
      return { ...state, lazyLoading: true, error: null };

    case "FETCH_STOCK_SUCCESS":
      return { ...state, loading: false, error: null, packings: payload };

    case "FETCH_STOCK_LAZY_SUCCESS":
      return {
        ...state,
        loading: false,
        lazyLoading: false,
        error: null,
        packings: {
          ...payload,
          data: [...state.packings?.data, ...payload?.data],
        },
      };

    case "FETCH_STOCK_ERROR":
      return { ...state, loading: false, error: payload };

    case "UPDATE_STOCK_LOADING": {
      return { ...state, updateLoading: true };
    }

    case "UPDATE_STOCK_SUCCESS": {
      const data = state.packings?.data;

      const index = data?.findIndex((packing) =>
        packing?.ids?.includes(payload.id)
      );
      data[index] = {
        ...data[index],
        status: payload?.status,
        reservation: payload?.reservation,
      };
      if (payload.filter?.status && payload.filter.status !== payload?.status) {
        data.splice(index, 1);
      }

      return {
        ...state,
        updateLoading: false,
        error: null,
        packings: { ...state.packings, data },
      };
    }
    case "CANCEL_RESERVATION_STOCK_SUCCESS": {
      if (!payload?.status) return state;

      const data = state.packings?.data?.map((packing) => {
        if (+packing?.ids[0] === payload?.id) {
          delete packing?.reservation;
          return {
            ...packing,
            status: payload?.status,
          };
        }
        return packing;
      });
      return { ...state, packings: { ...state.packings, data } };
    }

    case "UPDATE_STOCK_ERROR": {
      return { ...state, updateLoading: false, error: payload };
    }

    case "CLEAR_STOCK":
      return {
        ...state,
        loading: undefined,
        lazyLoading: false,
        error: null,
        packings: {},
      };

    default:
      return state;
  }
};

export default advancedStockReducer;
