import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../Common/FormElements/Select/Select";
import { getOrganizations } from "../../../REDUX/actions/organziation";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import TextArea from "../../Common/FormElements/TextArea/TextArea";

const ReserveStock = ({ action }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { organizations } = useSelector((state) => state.organizationReducer);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  return (
    <div className="d-flex flex-column">
      <p className="f16 bold">{t("Reserve the product to")} : </p>
      <Select
        name={"reseller"}
        placeholder={t("Store")}
        value={formData?.reseller}
        options={[
          {
            name: "Poseidon Spa",
            code: null,
          },
          ...organizations,
        ]}
        label={t("Store")}
        callback={handleChange}
        className="mb-3"
        filter={true}
        appendToSelf
      />

      <div className="colAlignC h100 align-items-start gap-2">
        <span className="text-softBlack f16 normal">Note :</span>
        <TextArea
          name="note"
          className={"w-100 b-2-softGrey h100"}
          rows={4}
          value={formData.note || ""}
          callback={handleChange}
        />
      </div>
      <div className="alignC mt-3">
        <Button
          onClick={() => {
            action({ ...formData, reseller: formData?.reseller?.code });
            dispatch(closeCenteredModal());
          }}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default ReserveStock;
