import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeleteBtn from "../FormElements/DeleteBtn";
import Button from "../FormElements/Button/Button";
import { useTranslation } from "react-i18next";
const ConfirmModal = ({
  label,
  id,
  text = "Are you sure you want to delete",
  confirmText,
  onConfirm,
}) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      setLoading(true);
      await onConfirm();
      dispatch(closeCenteredModal());
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {
    dispatch(closeCenteredModal());
  };
  return (
    <div className="d-flex flex-column align-items-center gap-3">
      <p>
        {t(`${text}`)} {label ?? ""} #{id} ?{" "}
      </p>
      <div className="d-flex gap-3">
        <DeleteBtn
          text={confirmText}
          loading={loading}
          onClick={handleDelete}
        />
        <Button onClick={closeModal}>{t("Cancel")}</Button>
      </div>
    </div>
  );
};

export default ConfirmModal;
