/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import moment from "moment";
import React, { useEffect, useState } from "react";
// local UI component :
import Button from "../../../Common/FormElements/Button/Button";
import CalendarInput from "../../../Common/FormElements/CalendarInput/CalendarInput";
import TextArea from "../../../Common/FormElements/TextArea/TextArea";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import UploadButton from "../../../Common/FormElements/UploadButton/UploadButton";
// utils
import { formatTimeStringToDate } from "../../../../Utils/fileUtils";
// redux
import { useDispatch, useSelector } from "react-redux";
import { updateEvent } from "../../../../REDUX/actions/events";
import { closeRightModal } from "../../../../REDUX/actions/rightModal";
import { getOrganizations } from "../../../../REDUX/actions/organziation";
import CustomMultiSelect from "../../../Common/FormElements/MultiSelect";
import { useTranslation } from "react-i18next";
import Select from "../../../Common/FormElements/Select/Select";
import SwitchButton from "../../../Common/FormElements/SwitchButton/SwitchButton";
/* -------------------------------------------------------------------------- */
/*                             Update-FC-Component                            */
/* -------------------------------------------------------------------------- */
const UpdateEvent = ({ event, eventId }) => {
  const { t } = useTranslation("common");

  const selectOptions = [
    { name: t("Expo and Fair"), code: "expo_fair" },
    { name: t("Open House"), code: "open_house" },
    { name: t("Google Ads"), code: "google_ads" },
    { name: t("Meta Ads"), code: "meta_ads" },
    { name: t("Flyer Campaign"), code: "flyer_campaign" },
    { name: t("Radio Campaign"), code: "radio_campaign" },
    { name: t("City Poster"), code: "city_poster" },
  ];

  const { user } = useSelector((state) => state.userReducer);
  const { organizations } = useSelector((state) => state.organizationReducer);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const { eventLoading } = useSelector((state) => state.eventsReducer);
  const eventData = event?.data?.attributes;
  const [eventFormData, setEventFormData] = useState({
    ...eventData,
    eventType: selectOptions?.find(
      (option) => option?.code === event?.data?.attributes?.eventType
    ),
    endTime: formatTimeStringToDate(eventData?.endTime),
    startTime: formatTimeStringToDate(eventData?.startTime),
  });
  const dispatch = useDispatch();

  const userRole = user?.attributes?.userRole;

  const handleChange = (field, value) => {
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  // handle change => calendar / time :
  const handleCalendarInput = (e, name) => {
    const { value } = e;
    let formattedValue = value;
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setEventFormData((prevFormData) => ({
        ...prevFormData,
        newPhoto: imageFile,
      }));
    }
  };

  const handleDocumentChange = (e) => {
    const documentFile = e.target.files[0];
    if (documentFile) {
      setEventFormData((prevFormData) => ({
        ...prevFormData,
        newDocument: documentFile,
      }));
    }
  };
  // data to update
  const updatedEvent = {
    ...eventFormData,
    endTime: moment(eventFormData.endTime).format("HH:mm:ss.SSS"),
    startTime: moment(eventFormData.startTime).format("HH:mm:ss.SSS"),
    startDate: moment(eventFormData.startDate)?.utc(true).toDate(),
    endDate: moment(eventFormData.endDate)?.utc(true).toDate(),
  };

  const handleSubmit = () => {
    dispatch(updateEvent({ updatedEvent, eventId }, t));
  };

  useEffect(() => {
    let extraFilters = {};
    if (user?.attributes?.userRole === 2) {
      extraFilters = {
        business_affiliate: {
          id: { $eq: user?.attributes?.business_affiliate?.data?.id },
        },
      };
    }
    dispatch(getOrganizations(extraFilters));
  }, [
    dispatch,
    user?.attributes?.userRole,
    user?.attributes?.business_affiliate?.data?.id,
  ]);

  const formDataIncomplete =
    !eventFormData.title ||
    !eventFormData.startDate ||
    !eventFormData.endDate ||
    !eventFormData.startTime ||
    !eventFormData.endTime ||
    !eventFormData.eventType ||
    ([1, 2].includes(userRole) &&
      !eventFormData.isGlobal &&
      !eventFormData.resellers?.length > 0);

  useEffect(() => {
    setOrganizationOptions(
      organizations?.map((item) => ({ label: item?.name, value: item?.code }))
    );
  }, [organizations]);

  useEffect(() => {
    setEventFormData((state) => ({
      ...state,
      resellers: event?.data?.attributes?.resellers?.data?.map(
        (item) => item?.id
      ),
    }));
  }, [event]);
  /* ******************************** rendering ******************************* */
  return (
    <div className="evenementModal px-5 d-flex flex-column justify-content-between h100 ">
      <div>
        <div className="row g-4">
          {userRole === 1 && (
            <div className="d-flex col-12 gap-2">
              <span className="text-softBlack normal f14">{t("Reseller")}</span>
              <SwitchButton
                small
                isActive={eventFormData.isGlobal}
                onClick={() => {
                  if (!!!eventFormData.isGlobal) {
                    handleChange("resellers", []);
                  }
                  handleChange("isGlobal", !!!eventFormData.isGlobal);
                }}
              />
              <span className="text-softBlack normal f14">{t("Poseidon")}</span>
            </div>
          )}
          {userRole !== 0 && !eventFormData.isGlobal && (
            <div className="d-flex flex-column gap-2 col-12">
              <span className="text-softBlack normal f16">
                {t("Reseller")}
                <span className="ms-1 text-whyBotherGrey f12">
                  ({t("Required")})
                </span>
              </span>
              <CustomMultiSelect
                filter
                name="resellers"
                placeholder=""
                value={
                  Array.isArray(eventFormData?.resellers)
                    ? eventFormData.resellers
                    : []
                }
                options={organizationOptions}
                callback={(e) => handleChange("resellers", e.target.value)}
              />
            </div>
          )}
          <div className="d-flex flex-column gap-2 col-12">
            <span className="text-softBlack normal f16">
              {t("Title")}
              <span className="ms-1 text-whyBotherGrey f12">
                ({t("Required")})
              </span>
            </span>
            <TextInput
              className="w100"
              value={eventFormData?.title || ""}
              callback={(e) => handleChange("title", e.target.value)}
            />
          </div>
          <div className="col-6 d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">
              {t("Event start date")}
              <span className="ms-1 text-whyBotherGrey f12">
                ({t("Required")})
              </span>
            </span>
            <CalendarInput
              className="w100"
              value={
                moment(eventFormData?.startDate).utc(true).toDate() || null
              }
              callback={(event) => handleCalendarInput(event, "startDate")}
            />
          </div>
          <div className="col-6 d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">
              {t("Event end date")}
              <span className="ms-1 text-whyBotherGrey f12">
                ({t("Required")})
              </span>
            </span>
            <CalendarInput
              className="w100"
              value={moment(eventFormData?.endDate).utc(true).toDate() || null}
              callback={(event) => handleCalendarInput(event, "endDate")}
            />
          </div>

          <div className="col-6 d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">
              {t("Start time")}

              <span className="ms-1 text-whyBotherGrey f12">
                ({t("Required")})
              </span>
            </span>
            <CalendarInput
              className="w100"
              callback={(event) => handleCalendarInput(event, "startTime")}
              value={eventFormData?.startTime}
              timeOnly
            />
          </div>
          <div className="col-6 d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">
              {t("End time")}
              <span className="ms-1 text-whyBotherGrey f12">
                ({t("Required")})
              </span>
            </span>
            <CalendarInput
              className="w100"
              callback={(event) => handleCalendarInput(event, "endTime")}
              timeOnly
              value={eventFormData?.endTime}
            />
          </div>

          <div className="col-6 d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">
              {t("Event type")}

              <span className="ms-1 text-whyBotherGrey f12">
                ({t("Required")})
              </span>
            </span>
            <div className="alignH">
              <Select
                options={selectOptions}
                value={eventFormData?.eventType}
                callback={(e) => handleChange("eventType", e.target.value)}
              />
            </div>
          </div>

          {!eventFormData.isGlobal && (
            <>
              <div className="d-flex flex-column gap-2 col-6">
                <span className="text-softBlack normal f16">{t("Budget")}</span>
                <TextInput
                  type="number"
                  className="w100"
                  value={eventFormData?.budget || ""}
                  euro
                  callback={(e) => handleChange("budget", e.target.value)}
                />
              </div>

              <div className="d-flex flex-column gap-2 col-6">
                <span className="text-softBlack normal f16">
                  {t("Number of spas sold")}
                </span>
                <TextInput
                  type="number"
                  className="w100"
                  value={eventFormData?.spa_sold}
                  callback={(e) => handleChange("spa_sold", e.target.value)}
                />
              </div>
              <div className="d-flex flex-column gap-2 col-6">
                <span className="text-softBlack normal f16">
                  {t("Number of swim spas sold")}
                </span>
                <TextInput
                  type="number"
                  className="w100"
                  value={eventFormData?.swim_spa_sold}
                  callback={(e) =>
                    handleChange("swim_spa_sold", e.target.value)
                  }
                />
              </div>

              <div className="d-flex flex-column gap-2 col-6">
                <span className="text-softBlack normal f16">
                  {t("Number of quotes")}
                </span>
                <TextInput
                  type="number"
                  className="w100"
                  value={eventFormData?.quotes_count}
                  callback={(e) => handleChange("quotes_count", e.target.value)}
                />
              </div>
            </>
          )}

          <div className="col-6 d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">
              {t("Link to view")}
            </span>
            <TextInput
              className="w100"
              value={eventFormData?.visitLink || ""}
              callback={(e) => handleChange("visitLink", e.target.value)}
            />
          </div>

          <div className="col-6">
            <span className="text-softBlack normal f16">
              {t("Cover image")}
            </span>
            <UploadButton
              id="cover"
              type={"file"}
              text={`${
                eventFormData?.newPhoto?.name ||
                eventFormData?.Image?.data?.attributes?.name
              }`}
              callback={handleImageChange}
              className="coverImg mt-2 flex-grow-1"
            />
          </div>

          <div className="col-6">
            <span className="text-softBlack normal f16 ">
              {t("Description")}
            </span>
            <TextArea
              className="w100 mt-2"
              value={eventFormData?.description || ""}
              callback={(e) => handleChange("description", e.target.value)}
            />
          </div>

          {eventFormData.isGlobal && (
            <div className="col-6 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                {t("PDF Document")}
              </span>
              <UploadButton
                id="document"
                type={"file"}
                text={`${
                  eventFormData?.newDocument?.name ||
                  eventFormData?.document?.data?.attributes?.name ||
                  t("Add a document (max. 15 MB)")
                }`}
                callback={handleDocumentChange}
                className="coverImg flex-grow-1"
              />
            </div>
          )}
        </div>
      </div>
      {/* Buttons */}
      <div className="align-self-center alignH gap-3 mt-5">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
          loading={eventLoading}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="px-5"
          onClick={handleSubmit}
          formDataIncomplete={formDataIncomplete}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default UpdateEvent;
