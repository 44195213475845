import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import Button from "../Common/FormElements/Button/Button";
import { openRightModal } from "../../REDUX/actions/rightModal";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import NewRevendeur from "./NewRevendeur/NewRevendeur";
import "./Revendeur.css";
import { getResellers } from "../../REDUX/actions/reseller";
import SwitchButtonDark from "../Common/FormElements/SwitchButton/SwitchButtonDark";
import { useTranslation } from "react-i18next";

const RevendeurListHeader = ({
  isArchived,
  search,
  setSearch,
  setIsArchived,
  filters,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(openRightModal(<NewRevendeur />));
  };

  const [isSearching, setIsSearching] = useState(false);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
    if (isSearching) {
      dispatch(getResellers("", true, isArchived, filters));
    }
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(getResellers(search, true, isArchived, filters));
    setIsSearching(search?.length > 0);
  };

  const toggleArchived = () => {
    setIsArchived((state) => {
      dispatch(getResellers(search, true, !state, filters));
      return !state;
    });
  };

  return (
    <div className="alignH justify-content-between gap-3">
      <h2 className="text-blue bold f30">{t("Resellers")}</h2>

      <>
        <form onSubmit={handleSearch} className="w40">
          <TextInput
            withSearchBtn
            searchButtonType="submit"
            placeholder={t("Search")}
            width="100%"
            value={search}
            callback={handleChange}
            onSearchBtnClick={handleSearch}
            clearSearch={handleClearSearch}
          />
        </form>

        <div className="alignH gap-5">
          <label className="d-flex align-items-center cursor-pointer">
            <p className="f14 text-brightGrey sBold mb-0 me-2">
              {t("Archived")}
            </p>
            <SwitchButtonDark isActive={isArchived} onClick={toggleArchived} />
          </label>
          <Button
            Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
            onClick={handleOpenModal}
          >
            {t("New reseller")}
          </Button>
        </div>
      </>
    </div>
  );
};

export default RevendeurListHeader;
