import React, { Fragment, useEffect, useMemo, useState } from "react";

import MessageInput from "../../Sav/NewTicketSav/ChatContainer/MessageInput";
import { useDispatch, useSelector } from "react-redux";
import MessageItem from "../../Sav/NewTicketSav/ChatContainer/MessageItem";
import {
  createInvoiceComment,
  getInvoicesComments,
} from "../../../REDUX/actions/invoices";
import { useTranslation } from "react-i18next";
import moment from "moment";

const InvoiceTabComments = ({ id }) => {
  const { t } = useTranslation("common");
  const { user } = useSelector((state) => state.userReducer);
  const { currentComments, currentCommentsLoading: loading } = useSelector(
    (state) => state.invoicesReducer
  );

  const [newMessage, setNewMessage] = useState({ text: "", attachments: [] });

  const dispatch = useDispatch();

  const groupMessagesByDate = (messages) => {
    return messages?.reduce((acc, message) => {
      const createdAt = message?.attributes?.createdAt;
      const formattedDate = createdAt ? moment(createdAt).format("LL") : "-"; // Example: January 31, 2025

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(message);

      return acc;
    }, {});
  };

  const groupedMessages = useMemo(
    () => groupMessagesByDate(currentComments),
    [currentComments]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attachments") {
      setNewMessage({
        ...newMessage,
        [name]:
          newMessage?.attachments?.length > 0
            ? [...newMessage?.attachments, ...value]
            : value,
      });
    } else {
      setNewMessage({ ...newMessage, [name]: value });
    }
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    if (
      newMessage?.text?.trim()?.length > 0 ||
      newMessage?.attachments?.length > 0
    ) {
      dispatch(
        createInvoiceComment(
          newMessage,
          id,
          user?.id,
          () => {
            setNewMessage({ text: "", attachments: [] });
            callback();
          },
          t
        )
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getInvoicesComments(id));
    }
  }, [dispatch, id]);

  return (
    <div className="ps-5 pe-3 pt-4 newSavComment d-flex flex-column justify-content-end max-w-900px mx-auto">
      <div className="d-flex pe-4 flex-column overflow-auto flex-column-reverse flex-1 pb-5">
        {Object.entries(groupedMessages).map(([date, messages]) => (
          <Fragment key={date}>
            {messages.map((comment, index) => (
              <MessageItem
                key={comment?.id}
                index={index}
                message={comment}
                isOwner={+user?.id === +comment?.attributes?.user?.data?.id}
                view="INVOICE"
              />
            ))}
            <div className="text-center text-muted my-2 px-4 py-2 f11 bg-dimGrey br5 sBold d-flex max-fit-content mx-auto text-dark">
              {date}
            </div>
          </Fragment>
        ))}
      </div>
      <div className={"px-4"}>
        <MessageInput
          loading={loading}
          handleChange={handleChange}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default InvoiceTabComments;
