export const invoicesExtractor = (invoicesData) => {
  return invoicesData.map((invoice) => {
    return {
      id: invoice?.id,
      oldId: invoice?.attributes?.oldId,
      createdAt: invoice?.attributes?.createdAt,
      // refClient: invoice?.attributes?.order?.data?.attributes?.refClient,
      // realRefClient:
      //   invoice?.attributes?.order?.data?.attributes?.realRefClient,
      status: invoice?.attributes?.commande?.data?.attributes?.status,
      totalTTC: invoice?.attributes?.commande?.data?.attributes?.totalTTC,
      orderID: invoice?.attributes?.commande?.data?.id,
      oldIdOrder: invoice?.attributes?.commande?.data?.attributes?.oldId,
      reseller:
        invoice?.attributes?.commande?.data?.attributes?.reseller?.data
          ?.attributes.name,
      fileUrl: invoice?.attributes?.media?.data?.attributes?.url,
      slug: invoice?.attributes?.number,
      slugDate: invoice?.attributes?.date,
      orderSlug: invoice?.attributes?.commande?.data?.attributes?.number,
    };
  });
};
