import qs from "qs";
import moment from "moment";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { savProductsExtractor } from "../../Utils/DataExtractors/savProductsExtractor";
import {
  savProductsQuery,
  ticketSavQuery,
  ticketSavsQuery,
} from "../apiQueries/ticketSAV";
import { uploadAttachmentToStrapi } from "../../Utils/uploadAttachmentToStrapi";
import { closeRightModal } from "./rightModal";
import { toast } from "react-toastify";
import {
  oneTicketSAVExtractor,
  ticketSavsExtractor,
} from "../../Utils/DataExtractors/ticketSavsExtractor";
import { ticketSAVurlFilters } from "../../Utils/urlFilters/ticketSAVurlFilters";
import store from "../store";
import { uploadFile } from "../../Utils";
import {
  extractResponsableOfSav,
  extractRevendeurOfSav,
} from "../../Utils/savUtils";
import { removeDuplicates } from "../../Utils/removeDuplicates";
import {
  savBusinessResellersExtractor,
  savResellersExtractor,
} from "../../Utils/DataExtractors/savExtractor";
import NEW_API from "../../Api/NewApi";
import { switchCarts, updateCart } from "./catalogue";

// GET ALL TICKETS
export const getTicketSAVs =
  ({ searchInput = "", filtersInput = "" }) =>
  async (dispatch) => {
    dispatch({ type: "TICKETS_SAV_LOADING" });
    try {
      const { user } = store.getState().userReducer;

      const getResellerFilters = () => {
        if (user?.attributes?.profil?.data?.attributes?.reseller?.data?.id) {
          return `&filters[reseller][id][$eq]=${user?.attributes?.profil?.data?.attributes?.reseller?.data?.id}`;
        }
      };
      let userFilters = "";
      if (user?.attributes?.userRole === 0) {
        userFilters = getResellerFilters();
      } else if (user?.attributes?.userRole === 2) {
        userFilters = `&filters[reseller][business_affiliate][id][$eq]=${user?.attributes?.business_affiliate.data?.id}`;
      }

      let marqueFilters = `&filters[marque][id][$eq]=${user?.attributes?.marque?.data?.id}`;

      const filters = ticketSAVurlFilters(searchInput, filtersInput);
      const fetchUrl = `${API_END_POINT}api/ticket-savs?${filters}${ticketSavsQuery()}${userFilters}${marqueFilters}&sort[0]=createdAt:desc`;
      const { data: allTicketsSav } = await API.get(fetchUrl);
      const ticketsSAV = ticketSavsExtractor(allTicketsSav.data);
      dispatch({
        type: "TICKETS_SAV_FETCHED",
        payload:
          (user?.attributes?.profil?.data?.attributes?.reseller?.data?.id &&
            user?.attributes?.userRole === 0) ||
          user?.attributes?.userRole === 1 ||
          user?.attributes?.userRole === 2
            ? ticketsSAV
            : [],
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "TICKETS_SAV_ERROR", payload: error });
    }
  };

// GET ONE TICKET
export const getOneTicketSAV = (payload) => async (dispatch) => {
  dispatch({ type: "TICKET_SAV_LOADING" });
  try {
    const fetchUrl = `${API_END_POINT}api/ticket-savs/${
      payload.id
    }?${ticketSavQuery()}`;
    const { data: OneTicketSav } = await API.get(fetchUrl);

    const ticketSAV = oneTicketSAVExtractor(OneTicketSav.data);
    dispatch({ type: "TICKET_SAV_FETCHED", payload: ticketSAV });
  } catch (error) {
    console.log(error);
    dispatch({ type: "TICKET_SAV_ERROR", payload: error });
  }
};

// CREATE NEW TICKET
export const createTicketSAV = (payload, t) => async (dispatch) => {
  dispatch({ type: "TICKETS_SAV_LOADING" });
  try {
    const user = store.getState()?.userReducer?.user;
    let parts = payload?.warrantyEndDate?.split("/");

    const userMarque = user?.attributes?.marque?.data?.id;

    let formattedDate = payload?.warrantyEndDate
      ? new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)?.toISOString()
      : null;
    const newTicketSAV = {
      product: payload?.product?.id,
      deliveryDate: payload?.deliveryDate
        ? moment(payload?.deliveryDate).utc(true).toDate()
        : null,
      serialNumber: payload?.serialNumber,
      refClient: payload?.refClient,
      problem: payload?.problem?.code,
      warrantyEndDate: formattedDate,
      status: 1,
      explanation: payload?.explanation,
      reseller: payload?.reseller?.id,
      order: payload?.order?.id,
      marque: userMarque,
      commande: payload?.commande?.id,
      users_permissions_users:
        user?.attributes?.userRole === 0 ? user?.id : undefined,
    };
    const { data: createdTicket } = await API.post("api/ticket-savs", {
      data: newTicketSAV,
    });

    if (payload.attachments.length) {
      payload.attachments.forEach(async (attachment) => {
        if (attachment.file) {
          const { data: createdAttachment } = await API.post(
            "api/attachments",
            {
              data: {
                title: attachment.title,
                ticket_sav: createdTicket.data.id,
              },
            }
          );
          uploadAttachmentToStrapi(
            attachment.file,
            "ticket sav fichier",
            createdAttachment.data.id,
            "api::attachment.attachment",
            "file"
          );
        }
      });
    }
    dispatch(getTicketSAVs({}));
    dispatch(closeRightModal());
    toast.success(t("Ticket created successfully"));
  } catch (error) {
    console.log(error);
    dispatch({ type: "TICKETS_SAV_ERROR", payload: error });
  }
};

// UPDATE TICKET ATTACHEMENTS
export const updateTicketAttachments = (payload) => async (dispatch) => {
  dispatch({ type: "TICKET_SAV_LOADING" });
  try {
    const oldAttachments =
      store.getState().ticketSAVReducer.ticketSAV.attachments;
    for (const attachement of payload.attachments) {
      // add or update attachment
      const existantFile = oldAttachments.find(
        (oldAttach) => oldAttach.id === attachement.id
      );

      if (!existantFile && attachement.file) {
        const fileId = await uploadFile(attachement?.file);
        await API.post(`${API_END_POINT}api/attachments?populate=*`, {
          data: {
            title: attachement?.title,
            date: new Date(attachement?.date),
            file: fileId,
            ticket_sav: payload.id,
          },
        });
      } else if (existantFile && attachement.title !== existantFile?.title) {
        await API.put(
          `${API_END_POINT}api/attachments/${attachement?.attachmentId}?populate=*`,
          {
            data: {
              title: attachement?.title,
            },
          }
        );
      }
    }
    dispatch({ type: "TICKET_SAV_UPDATED" });
    dispatch(ticketSAVReload());
  } catch (error) {
    console.log(error);
    dispatch({ type: "TICKET_SAV_ERROR", payload: error });
  }
};

export const updateTicketSAVStatus = (payload, t) => async (dispatch) => {
  dispatch({ type: "TICKET_SAV_LOADING" });
  try {
    await API.put(`api/ticket-savs/${payload.id}`, {
      data: {
        ...payload.data,
      },
    });
    // Fetch and update ticket SAVs after updating
    toast.success(t("Support ticket updated successfully!"));
    dispatch(ticketSAVsReload());
    dispatch({ type: "TICKETS_SAV_UPDATED" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "TICKET_SAV_ERROR", payload: error });
  }
};
export const updateTicketSAV = (payload, t) => async (dispatch) => {
  dispatch({ type: "TICKET_SAV_LOADING" });

  try {
    const revendeur = payload.users.find((user) => user.userRole === 0);
    const responsable = payload?.responsable?.code;
    const usersGroup = [revendeur?.id, responsable].filter(Boolean);
    await API.put(`api/ticket-savs/${payload.id}`, {
      data: {
        users_permissions_users: usersGroup,
        relaunch: payload.relaunch
          ? moment(payload.relaunch).format("YYYY-MM-DD")
          : null,
      },
    });
    if (payload.intervention) {
      if (payload.intervention.id) {
        await API.put(`api/interventions/${payload.intervention.id}`, {
          data: {
            type: payload.intervention.type.code,
            date: payload.intervention.date
              ? moment(payload.intervention.date).format("YYYY-MM-DD")
              : null,
          },
        });
      } else {
        await API.post(`api/interventions`, {
          data: {
            type: payload.intervention.type.code,
            date: payload.intervention.date
              ? moment(payload.intervention.date).format("YYYY-MM-DD")
              : null,
            relaunch: payload.relaunch
              ? moment(payload.relaunch).format("YYYY-MM-DD")
              : null,
            ticket_sav: payload.id,
          },
        });
      }
    }
    // Fetch and update ticket SAVs after updating
    toast.success(t("Support ticket updated successfully!"));
    dispatch(ticketSAVReload());
    dispatch(ticketSAVsReload());
    dispatch(closeRightModal());
    dispatch({ type: "TICKET_SAV_UPDATED" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "TICKET_SAV_ERROR", payload: error });
  }
};

export const getSAVProducts = () => async (dispatch) => {
  dispatch({ type: "SAV_PRODUCTS_LOADING" });
  try {
    const { data: allSAVProducts } = await API.get(
      `${API_END_POINT}api/products?filters[productType][$eq]=1&${savProductsQuery()}`
    );
    const products = savProductsExtractor(allSAVProducts.data);

    dispatch({
      type: "SAV_PRODUCTS_FETCHED",
      payload: products,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "SAV_PRODUCTS_ERROR", payload: error });
  }
};

// SAV revendeurs
export const getSAVRevendeurs = () => async (dispatch, getState) => {
  dispatch({ type: "SAV_REVENDEURS_LOADING" });
  try {
    const { user } = getState().userReducer;

    const filter = `&filters[business_affiliate][id][$eq]=${user?.attributes?.business_affiliate?.data?.id}`;

    const archivedFilters = qs.stringify({
      $or: [
        {
          archived: { $eq: false },
        },
        {
          archived: { $null: true },
        },
      ],
    });

    const { data: allSAVRevendeurs } = await API.get(
      `${API_END_POINT}api/organizations?${archivedFilters}${
        user?.attributes?.userRole === 2 ? filter : ""
      }`
    );
    const revendeurs = savResellersExtractor(allSAVRevendeurs);

    dispatch({
      type: "SAV_REVENDEURS_FETCHED",
      payload: revendeurs,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "SAV_REVENDEURS_ERROR", payload: error });
  }
};

export const getTicketSAVFiltersData = () => async (dispatch) => {
  dispatch({ type: "FILTERS_LOADING" });
  try {
    const fetchUrl = `api/ticket-savs?${ticketSavsQuery()}`;
    const { data: allTickets } = await API.get(fetchUrl);

    const createdAtList = allTickets.data.map(
      (ticket) => ticket.attributes.createdAt
    );
    const relaunchList = allTickets.data
      .map((ticket) => ticket.attributes?.relaunch)
      .filter(Boolean);
    var resellersList = allTickets.data
      .map((ticket) => {
        const revendeur = extractRevendeurOfSav(
          ticket?.attributes?.users_permissions_users
        );
        if (revendeur) {
          return {
            id: revendeur.id,
            firstName: revendeur.attributes.firstName,
            lastName: revendeur.attributes.lastName,
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    var responsablesList = allTickets.data
      .map((ticket) => {
        const responsable = extractResponsableOfSav(
          ticket?.attributes?.users_permissions_users
        );
        if (responsable) {
          return {
            id: responsable.id,
            firstName: responsable.attributes.firstName,
            lastName: responsable.attributes.lastName,
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);

    resellersList = removeDuplicates(resellersList);
    responsablesList = removeDuplicates(responsablesList);
    const filtersList = {
      createdAtList,
      relaunchList,
      resellersList,
      responsablesList,
    };
    dispatch({ type: "FILTERS_FETCHED", payload: filtersList });
  } catch (error) {
    console.log(error);
  }
};
export const ticketSAVsReload = () => async (dispatch) => {
  dispatch({ type: "TICKETS_SAV_RELOAD" });
};
export const ticketSAVReload = () => async (dispatch) => {
  dispatch({ type: "TICKET_SAV_RELOAD" });
};

export const createCommentSAV =
  (comment, ticket, isAdmin, user, successCallback, t) => async (dispatch) => {
    if (isAdmin) {
      dispatch({ type: "CREATE_ADMIN_COMMENTS_SAV_LOADING" });
    } else {
      dispatch({ type: "CREATE_COMMENTS_SAV_LOADING" });
    }

    try {
      const {
        data: { data: commentData },
      } = await API.post(`${API_END_POINT}api/comments?populate=*`, {
        data: {
          text: comment?.text,
          ticket_sav: ticket,
          user,
          onlyAdmin: !!isAdmin,
        },
      });

      if (comment?.attachments?.length > 0) {
        for (const file of comment?.attachments) {
          const fileId = await uploadFile(file);
          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                date: new Date(),
                file: fileId,
                comment: commentData?.id,
              },
            }
          );
          commentData.attributes.attachments.data?.push(data?.data);
        }
      }

      if (isAdmin) {
        dispatch({
          type: "CREATE_ADMIN_COMMENTS_SAV_SUCCESS",
          payload: {
            ...commentData,
            attributes: { ...commentData?.attributes },
          },
        });
      } else {
        dispatch({
          type: "CREATE_COMMENTS_SAV_SUCCESS",
          payload: {
            ...commentData,
            attributes: { ...commentData?.attributes },
          },
        });
      }
      successCallback();
      toast.success(t("A comment has been added"));
    } catch (error) {
      console.log(error);
    }
  };

export const getComments = (ticket, isAdmin) => async (dispatch) => {
  try {
    if (isAdmin) {
      dispatch({ type: "FETCH_ADMIN_COMMENTS_SAV_LOADING" });
    } else {
      dispatch({ type: "FETCH_COMMENTS_SAV_LOADING" });
    }
    const query = qs.stringify({
      populate: [
        "attachments.file",
        "user.photo",
        "ticket_sav",
        "user.profil.reseller",
        "user.business_affiliate",
        "user.marque",
      ],
      sort: ["createdAt:desc"],
      filters: {
        ticket_sav: { id: { $eq: ticket } },
        onlyAdmin: isAdmin ? { $eq: true } : { $ne: true },
      },
    });
    const {
      data: { data: commentData },
    } = await API.get(`${API_END_POINT}api/comments?${query}`);

    if (!!isAdmin) {
      dispatch({
        type: "FETCH_ADMIN_COMMENTS_SAV_SUCCESS",
        payload: commentData,
      });
    } else {
      dispatch({ type: "FETCH_COMMENTS_SAV_SUCCESS", payload: commentData });
    }
  } catch (error) {
    dispatch({ type: "FETCH_COMMENTS_SAV_ERROR", payload: error });
  }
};

export const createCartSAV = (navigate, data) => async (dispatch) => {
  try {
    const {
      data: {
        data: { id },
      },
    } = await NEW_API.post(`/api/createCart`);
    dispatch(updateCart(id, { free: true, ...data }));
    navigate("/catalogue");
    localStorage.setItem("selectedCart", id);
    dispatch(switchCarts(id));
  } catch (error) {
    console.log(error);
  }
};

export const deleteTicketSAV = (id) => async (dispatch) => {
  dispatch({ type: "TICKET_SAV_LOADING" });
  try {
    await API.delete(`api/ticket-savs/${id}`);

    toast.success("Ticket SAV supprimé avec succès!");
    dispatch(ticketSAVReload());
    dispatch(ticketSAVsReload());
    dispatch({ type: "TICKET_SAV_DELETED" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "TICKET_SAV_ERROR", payload: error });
  }
};
