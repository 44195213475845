import React, { useEffect } from "react";
import Select from "../Common/FormElements/Select/Select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizations } from "../../REDUX/actions/organziation";
import { getAffiliates } from "../../REDUX/actions/affiliate";
import { getCountries } from "../../REDUX/actions/country";
import { isDefined } from "../../Utils";

const RevendeurFilters = ({ filters, setFilters }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const { organizations } = useSelector((state) => state.organizationReducer);
  const { affiliates } = useSelector((state) => state.affiliateReducer);
  const { countries } = useSelector((state) => state.countryReducer);
  const { user } = useSelector((state) => state.userReducer);

  const userRole = user?.attributes?.userRole;
  const isManager = userRole === 2;
  const isAdmin = userRole === 1;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "business_affiliate") {
      setFilters((prev) => ({
        ...prev,
        reseller: null,
        [name]: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const getAffiliatesOptions = () =>
    affiliates.map((affiliate) => {
      return {
        name:
          affiliate?.attributes?.user?.data?.attributes?.firstName +
          " " +
          affiliate?.attributes?.user?.data?.attributes?.lastName,
        code: affiliate?.id,
      };
    });

  useEffect(() => {
    if (userRole === 2) {
      dispatch(
        getOrganizations({
          business_affiliate: { user: { id: { $eq: user?.id } } },
        })
      );
    } else if (userRole === 1) {
      const extraFilters = isDefined(filters?.business_affiliate?.code)
        ? {
            business_affiliate: {
              id: { $eq: filters.business_affiliate.code },
            },
          }
        : {};
      dispatch(getOrganizations(extraFilters));
    }
  }, [user?.id, userRole, dispatch, filters.business_affiliate]);

  useEffect(() => {
    dispatch(getAffiliates());
  }, [userRole, dispatch]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <>
      {/* <div>
        <h2 className="text-blue bold f30 mb-3">
          {isAdmin || isManager ? t("Stores") : t("My Store")}
          {filters.reseller && ` - ${filters?.reseller?.name}`}
        </h2>
      </div> */}
      {(isManager || isAdmin) && (
        <div className="py-2 d-flex gap-3 w-100">
          <div className="max-w-300px w-100">
            <Select
              name="country"
              value={filters.country}
              label={t("Country")}
              placeholder={t("Country")}
              options={countries}
              filter
              resetable
              showClear
              appendToSelf
              callback={handleFilterChange}
            />
          </div>
          {isAdmin && (
            <div className="max-w-300px w-100">
              <Select
                name="business_affiliate"
                value={filters.business_affiliate}
                label={t("Manager")}
                placeholder={t("Manager")}
                filter
                appendToSelf
                showClear
                resetable
                options={getAffiliatesOptions()}
                callback={handleFilterChange}
              />
            </div>
          )}
          <div className="max-w-300px w-100">
            <Select
              name="reseller"
              value={filters.reseller}
              label={t("Store")}
              placeholder={t("Store")}
              filter
              appendToSelf
              showClear
              resetable
              options={organizations}
              callback={handleFilterChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RevendeurFilters;
