import { useTranslation } from "react-i18next";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { useEffect, useState } from "react";
import Button from "../../Common/FormElements/Button/Button";
import FiltersContainer from "../../Common/Calendar/FiltersContainer/FiltersContainer";
import Select from "../../Common/FormElements/Select/Select";
import { API_END_POINT, IMAGE_END_POINT } from "../../../Api/EndPoints";
import API from "../../../Api/Api";

const AdvancedStockHeader = ({
  filters,
  type,
  setType,
  setFilters,
  clearStock,
}) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState("");
  const [currentFilters, setCurrentFilters] = useState(filters);

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    setFilters((prev) => ({ ...prev, search }));
    clearStock();
  };

  const handleClearSearch = () => {
    setSearch("");
    setFilters((prev) => ({ ...prev, search: "" }));
    clearStock();
  };

  // const handleSelectType = (e) => {
  //   const { value } = e.target;
  //   setType(value);
  //   clearStock();
  //   setFilters((prev) => {
  //     const { gamme, ...rest } = prev;
  //     return rest;
  //   });
  //   setCurrentFilters((prev) => {
  //     const { gamme, ...rest } = prev;
  //     return rest;
  //   });
  // };

  const handleSelectChange = (e) => {
    const { value, name } = e.target;
    setCurrentFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitFilter = () => {
    clearStock();
    setFilters((prev) => ({ ...prev, ...currentFilters }));
  };

  const downloadPDF = async () => {
    setLoading(true);
    try {
      const { data } = await API.post(`${API_END_POINT}api/stock-pdf`, {
        data: {
          ...filters,
          type: type.code,
        },
      });

      window.open(`${IMAGE_END_POINT}${data.url}`);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getFilters() {
      try {
        const response = await API.get(`${API_END_POINT}api/stock-filters`);
        setData(() => {
          const { approvisionnements, products, gammes, entrepots } =
            response?.data;

          return {
            status: [
              { name: t("Stock"), code: "in-stock" },
              { name: t("Delivered"), code: "delivered" },
              { name: t("Order"), code: "ordered" },
              { name: t("Preorder"), code: "pre-ordered" },
              { name: t("Reserved"), code: "reserved" },
              {
                name: t("In supply"),
                code: "in-supply",
              },
            ],
            approvisionnement: approvisionnements.map((approv) => ({
              code: approv.id,
              name: approv.name,
            })),
            model: products.map((product) => ({
              code: product.id,
              name: i18n.language?.includes("en")
                ? product.name_en
                : product.name,
            })),
            gamme: gammes.map((gamme) => ({
              code: gamme.id,
              name: gamme.name,
            })),
            entrepot: entrepots.map((entrepot) => ({
              code: entrepot.id,
              name: entrepot.name,
            })),
          };
        });
      } catch {}
    }
    getFilters();
  }, [i18n.language, t]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-3 mt-5 mb-3">
        <div className="alignH gap-4 flex-grow-1 justify-content-between">
          <p className="text-blue bold f30 mb-0">
            {t("Stock")} {t(`${type.name}`)}
          </p>
          {/* 
            <Select
            value={type}
            options={[
              {
                name: t("Product"),
                code: 1,
              },
              {
                name: t("Access./Part/PLV"),
                code: 2,
              },
            ]}
            name="type"
            callback={handleSelectType}
            resetable={false}
            showClear={false}
            className="w-50 max-w-300px"
          /> */}
        </div>
        <div className="d-flex align-items-center justify-centent-between gap-3">
          <form onSubmit={handleSearch}>
            <TextInput
              withSearchBtn
              placeholder={t("Search")}
              width={500}
              value={search}
              callback={(e) => setSearch(e.target.value)}
              onSearchBtnClick={handleSearch}
              searchButtonType="submit"
              clearSearch={handleClearSearch}
            />
          </form>
          <Button
            Icon={() => <i className="bi bi-funnel fa-lg " />}
            variant={showFilters && "outline"}
            onClick={() => setShowFilters((prev) => !prev)}
          >
            {t("Filters")}
          </Button>
          <Button loading={loading} onClick={downloadPDF}>
            {t("Download Stock Value")}
          </Button>
        </div>
      </div>
      {showFilters ? (
        <FiltersContainer className="my-4">
          <div className="row g-3 flex-wrap">
            <div className="col-3">
              <label className="f16 text-softBlack mb-2 sBold">{t("PO")}</label>
              <Select
                value={currentFilters.approvisionnement}
                filter
                options={data?.approvisionnement || []}
                placeholder={t("PO")}
                name="approvisionnement"
                callback={handleSelectChange}
                resetable
                showClear
              />
            </div>
            <div className="col-3">
              <label className="f16 text-softBlack mb-2 sBold">
                {t("Model")}
              </label>
              <Select
                value={currentFilters.model}
                filter
                options={data?.model || []}
                placeholder={t("Model")}
                name="model"
                callback={handleSelectChange}
                resetable
                showClear
              />
            </div>
            {type.code === 1 && (
              <div className="col-3">
                <label className="f16 text-softBlack mb-2 sBold">
                  {t("Range")}
                </label>
                <Select
                  value={currentFilters.gamme}
                  filter
                  options={data?.gamme || []}
                  placeholder={t("Range")}
                  name="gamme"
                  callback={handleSelectChange}
                  resetable
                  showClear
                />
              </div>
            )}
            <div className="col-3">
              <label className="f16 text-softBlack mb-2 sBold">
                {t("Depot")}
              </label>
              <Select
                value={currentFilters.entrepot}
                filter
                options={data?.entrepot || []}
                placeholder={t("Depot")}
                name="entrepot"
                callback={handleSelectChange}
                resetable
                showClear
              />
            </div>
            <div className="col-3">
              <label className="f16 text-softBlack mb-2 sBold">
                {t("Status")}
              </label>
              <Select
                value={currentFilters.status}
                options={data?.status}
                placeholder={t("Status")}
                name="status"
                callback={handleSelectChange}
                resetable
                showClear
              />
            </div>
          </div>
          <Button
            className="filterSubmitBtn mt-4 ms-auto"
            onClick={handleSubmitFilter}
          >
            {t("Filter")}
          </Button>
        </FiltersContainer>
      ) : null}
    </>
  );
};

export default AdvancedStockHeader;
